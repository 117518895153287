import React from 'react';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import PopoverList from './PopoverList';
import Tooltip from 'control/Tooltip';
import msgImg from 'public/img/msg.png';
import messageStore from 'component/message/MessageStore';
import './style.scss';
import withLocale from 'decorator/withLocale';

const MessageBadge = observer(({ count, locale }) => {
  let countstr = count;
  if (count > 99) {
    countstr = '99+';
  }
  return (
    <React.Fragment>
      <div 
        style={{
          marginRight: 5,
        }}
        className="font-s-14"
      >
        {locale.notify}
      </div>
      <div className="message-badge">
        {count > 0 ? <i>{countstr}</i> : null}
      </div>
    </React.Fragment>
  );
});
MessageBadge.defaultProps = {
  count: 0,
};


@withRouter
@withLocale
@observer
class MessagePopover extends React.Component {
  state = { open: false }

  handleFocus = () => {
    this.setState({
      open: !this.state.open
    });
  }

  showMore = () => {
    const data = messageStore.messages.getData();
    if(data.length != 0) {
      this.setState({
        open: !this.state.open
      });
      // console.log(this.props, this.context)
      this.props.history.push('/app/message');
    }
  }

  readAll = () => {
    messageStore.readAll();
  }

  render() {
    const { locale } = this.props;
    const { open } = this.state;
    return (
      <Tooltip 
        clickToOpen 
        triggerText={<MessageBadge count={messageStore.noReadMsgSize} locale={locale}/>} 
        open={open}
        className="message-popover"
        showIcon={false}
      >
        <PopoverList data={messageStore.messages.getData()} style={{ width: 336 }} onClick={this.showMore} locale = {locale}/>
        <div className="d-flex align-items-center justify-content-center message-popover__footer">
          <span className="show-more" onClick={this.showMore}>{locale.message.readAll}</span>
        </div>
      </Tooltip>
    )
  }
}
export default MessagePopover;
