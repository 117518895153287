import { Model } from 'qn/data';

@Model({
  modelName: 'Campaign',
  fields: {
    campaignKey: 'string',
    enterpriseKey: 'string',
    type: 'string',
    name: 'string',
    startDate: 'date',
    expirationDate: 'date',
    description: 'string',
    status: 'string',
    typeSpecificFieldValues: 'any',
    storeName: 'string',
    storeKey: 'string',
    comment: 'string',
    usedTimess: 'string',
  },
  idProperty: 'campaignKey',
})
class Campaign {

}

export default Campaign;
