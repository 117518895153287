import React from 'react';
import cx from 'classnames';
import './style.scss';
// import expandImg from 'public/img/expand.png';
// import recoverImg from 'public/img/recover.png';

const Card = ({ children, className, ...rest }) => {
  const cardClassName = cx(className, 'qf-card');
  return (
    <div className={cardClassName} {...rest}>
      {children}
    </div>
  );
};
// const imgStyle = {
//   width: 16,
//   height: 16,
// };
// class Card extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       height: props.height || 'auto',
//       expanded: false,
//     };
//   }

//   renderExpand = () => {
//     const { enableExpand } = this.props;
//     if (!enableExpand) {
//       return null;
//     }
//     return (
//       <i onClick={this.expand} className="qf-card__expand-icon">
//         {this.state.expanded ? <img src={recoverImg} alt="" style={imgStyle} /> : <img src={expandImg} alt="" style={imgStyle} />}
//       </i>
//     );
//   }

//   expand = () => {
//     this.setState(prevState => ({
//       expanded: !prevState.expanded,
//       height: prevState.expanded ? this.props.height : 'auto',
//     }));
//   }

//   render() {
//     const { style, className, children } = this.props;
//     const cardClassName = cx('qf-card', className);  
//     const cardStyle = {
//       ...style,
//       height: this.state.height,
//     };
//     return (
//       <div className={cardClassName} style={cardStyle}>
//         {children}
//         {this.renderExpand()}
//       </div>
//     );
//   }
// }

export default Card;
