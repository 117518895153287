import { observable, computed } from 'mobx';
import { StoreManager, Store } from 'qn/data';
import api from 'remote/api';


class MessageStore {
  @observable messages = Store.create({
    model: 'Message',
  })

  @computed get noReadMsgSize() {
    const noReads = this.messages.getData().filter(item => item.hasBeenRead === false);
    return noReads.length;
  }

  async readAll() {
    await api.post('/readmsg');
    this.messages.getData().forEach((item) => {
      item.set('hasBeenRead', true);
    });
  }

  dispose() {
    this.messages.dispose();
  }

}
const messageStore = new MessageStore();
StoreManager.register(messageStore);
export default messageStore;