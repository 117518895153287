import React from 'react';
import { Tabs, Tab } from 'carbon-components-react';
import { ReceivablesRoute } from './receivables';
import api from 'remote/api';
import withLocale from 'decorator/withLocale';

@withLocale
class Transaction extends React.Component {

  render() {
    const lang = this.props.locale.transaction;
    return (
      <ReceivablesRoute />
    )
  }
}
export default Transaction;
