import invariant from 'invariant';

class ModelManager {
  models = {}
  setModel(model) {
    let name = model.modelName;
    invariant(!Reflect.has(this.models, name), 'The Model already exists');
    this.models[name] = model;
  }

  getModel(name) {
    invariant(Reflect.has(this.models, name), `not found ${name} in [${Object.keys(this.models).join(', ')}]`);
    return this.models[name];
  }
}
export default new ModelManager();
