import React from 'react';
import sessionStore from 'sessionStore';
import { observer } from 'mobx-react';

const Can = observer(({ doSome, notDoSome, children }) => {
  const userAccess = sessionStore.getUser().accesses;
  if (doSome && !doSome.some(o => userAccess.includes(o))) {
    return null;
  }
  if (notDoSome && notDoSome.some(o => userAccess.includes(o))) {
    return null;
  }
  return children;
});


const canDoSome = (access) => {
  const userAccess = sessionStore.getUser().accesses;
  if (!access || access.length === 0) {
    return true;
  }
  return access.some(o => userAccess.includes(o));
};
const canDoEvery = (access) => {
  const userAccess = sessionStore.getUser().accesses;
  if (!access || access.length === 0) {
    return true;
  }
  return access.every(o => userAccess.includes(o));
};
const cannotDoSome = (access) => {
  const userAccess = sessionStore.getUser().accesses;
  if (!access || access.length === 0) {
    return true;
  }
  return access.some(o => !userAccess.includes(o));
};
const cannotDoEvery = (access) => {
  const userAccess = sessionStore.getUser().accesses;
  if (!access || access.length === 0) {
    return true;
  }
  return access.every(o => !userAccess.includes(o));
};
export { Can, canDoSome, canDoEvery, cannotDoSome, cannotDoEvery };
