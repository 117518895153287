import React from 'react';
import moment from 'moment';

// 时间格式化 format->日期格式
export const DateFormat = ({ value, format, ...props }) => {
  return (
    <span {...props}>
      {(!_.isNil(value) && moment(value).isValid()) ? moment(value).format(format || 'YYYY-MM-DD') : '--'}
    </span>
  );
};
