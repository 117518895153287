import { Model } from 'qn/data';

@Model({
  modelName: 'Receivable',
  fields: {
    key: 'string',
    cashierId: 'string',
    channel: 'string',
    enterpriseId: 'number',
    errorMsg: 'string',
    exchangeAmount: 'string',
    exchangeCurrency: 'string',
    goodsName: 'string',
    storeDeviceId: 'string',
    channelTransactionId: 'string', // 渠道交易流水号
    storeKey: 'string',
    tradeNo: 'string',
    originTradeNo: 'string',
    tradeTime: 'date',
    type: 'string',
    currency: 'string', // 币种
    amount: 'number', // 交易金额
    state: 'string', // 订单状态
    originAmount: 'number',
    payType: 'string',
    refundType: 'string',
    storeName: 'string',
    storeAddress: 'string',
    cashierName: 'string',
    campaignName: 'string',
    campaignType: 'string',
    serviceCashing: 'number',
    comment: 'string',
    poundage: 'string',
    settlementDate: 'string',
    type: 'string',
    payImg: 'string',
    scanType: 'string',
    storeId: 'number',
    supportPartialRefund: 'boolean',
    storeLoginId: 'string',
    enterpriseLoginId:'string',
    supportRefund: 'boolean',
    payChineseName: 'string',
    payEnglishName: 'string',
    payJanpaneseName: 'string',
    pointAmount: 'number',
    deduction: 'number',
    originalAmount: 'number'
  },
  // idProperty: 'uuid',
  idgen: 'uuid',
})
class Receivable {
  static states = {
    PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    REFUND_COMPLETED: 'REFUND_COMPLETED',
    REFUND_FAILED: 'REFUND_FAILED',
    ORDER_CLOSE: 'ORDER_CLOSE',
  }
}
export default Receivable;
