import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

export const PendingReview = Loadable({
  loader: () => import('./PendingReviewTableContainer'),
  loading: LCLoading,
});
export const ReviewDetail = Loadable({
  loader: () => import('./ReviewDetail'),
  loading: LCLoading,
});
export const ApprovedReviewDetail = Loadable({
  loader: () => import('./ApprovedReviewDetail'),
  loading: LCLoading,
});
export const PendingReviewRoute = ({ match }) => {
  return (
    <Switch>
      {/* <Route exact path={`${match.path}/:id`} component={ReviewDetail} /> */}
      <Route exact path={`${match.path}`} component={PendingReview} />
      <Route exact path={`${match.path}/approved/:id`} component={ApprovedReviewDetail} />
    </Switch>
  );
};
