import React from 'react';
import logo from 'public/img/logo.png';
import './style.scss';
import MessagePopover from 'component/message/MessagePopover';
import sessionStore from 'sessionStore';
import TextButton from 'control/textButton';
import { withRouter } from 'react-router-dom';
import withLocale from 'decorator/withLocale';
import { observer } from 'mobx-react';
import { runInAction, observable, action } from 'mobx';
import Modal from 'control/modal';
import { StoreManager } from 'qn/data';
import ClickListener from 'carbon-components-react/es/internal/ClickListener';
import { DEFAULT_LOCALE } from 'config';
import { LocaleContext, locales } from 'component/LocaleContext';

@withLocale
@observer
class LanguageHeader extends React.Component {
  constructor() {
    super();
    const langInStorage = sessionStore.getLocale();
    let lang = DEFAULT_LOCALE;
    if (langInStorage) {
      lang = langInStorage;
    }
    this.state = {
      locale: locales[lang],
      setLocale: this.setLocale,
    };
    window.qf_locale = locales[lang];

    //document.getElementById("app").style.fontFamily="Meiryo, \"ヒラギノ角ゴ Pro W3\", \"Hiragino Kaku Gothic Pro\", \"ＭＳ Ｐゴシック\", Arial, Sans-serif";
    if(lang === 'jp'){
      document.getElementById("app").style.fontFamily="\"Open Sans\",\"Meiryo\", \"ヒラギノ角ゴ Pro W3\", \"Hiragino Kaku Gothic Pro\", \"ＭＳ Ｐゴシック\", Arial, Sans-serif";
    } else if(lang === 'zh-CN'){
      document.getElementById("app").style.fontFamily="\"PingFang SC\",Arial,simsun,sans-serif";
    } else {
      document.getElementById("app").style.fontFamily="\"Helvetica Neue\", Helvetica, Arial, sans-serif";
    }
  }

  setLocale = (value) => {
    if (value !== this.state.locale.l) {
      this.setState({
        locale: locales[value],
      });
      window.qf_locale = locales[value];
      sessionStore.saveLocale(value);
      window.location.reload();
    }
  }
  @observable langListVisible = false

  @action
  changeLangListVisible = () => {
    this.langListVisible = !this.langListVisible;
  }

  @action
  handleClickOutside = () => {
    this.langListVisible = false;
  }

  @action
  handleLangClick = (lang, e) => {
    e.stopPropagation();
    this.langListVisible = false;
    this.props.setLocale(lang);
  }

  
  render() {
  const { match, locale } = this.props;
  const style = {
    display: this.langListVisible ? 'block' : 'none',
  };
    return (
      <div onClick={this.changeLangListVisible}  style={{ marginRight: 10 }}>
        <ClickListener onClickOutside={this.handleClickOutside}>
          <div >
            <span  style={{cursor:'pointer' }}> {locale.langSelection}</span>
            <div id='language'  className="account" style={style}>
              <div className="updatepasswords" onClick={this.handleLangClick.bind(this, 'zh-CN')}>简体中文</div>
              <div className="updatepasswords" onClick={this.handleLangClick.bind(this, 'jp' )}>日本語</div>
              <div className="loginout" onClick={this.handleLangClick.bind(this, 'en')}>English</div>
            </div>
          </div>
        </ClickListener>
      </div>
      
    )
  }
}

const Header = withLocale(withRouter(({ history, locale }) => {
  function logout() {
    Modal.confirm({
      title: locale.modal.title,
      content: locale.logoutConfirm,
      onRequestSubmit() {
        history.replace('/auth/login');
        sessionStore.removeToken();    
        StoreManager.dispose();
      },
    });
  }
  function changeManageListVisible() {
   document.getElementById('wordpass').style.display="block";
  }
  function handleClickOutside() {
    document.getElementById('wordpass').style.display="none";
  }
  function updatePassWord (){
    history.push('/app/updatePassword');
   
  }
  function updateSettings (){
    history.push('/app/updateSettings');
   
  }
  

  const jsonObj = JSON.parse(sessionStore.getUser().token);
  const aloginId = jsonObj['loginId'];
  const user = sessionStore.getUser();
  return (
    <header className="d-flex header justify-content-between">
      <img src={logo} alt="" style={{ width: 126, height: 20 }} />
      <div className="d-flex align-items-center" style={{ marginRight: 34 }}>
        {/* <div>{appStore.viewport.height}</div>*/}
        {/* {user.isStoreOwner ? user.storeName : user.enterpriseName } */}
        <div style={{ marginRight: 10,color:`#468CFB`}}>
          {user.isEnterpriseOwner || user.accesses.findIndex(o => o === 'ENTERPRISE_USER') != -1 ? user.enterpriseName : user.storeName }
        </div>
        <div style={{ marginRight: 10,color:`#468CFB` }}>{sessionStore.getUser().name || aloginId }</div>
        
          <LanguageHeader/>
        <div className="" onClick={changeManageListVisible}>
          <ClickListener onClickOutside={handleClickOutside}>
            <div className="">
              <span  style={{cursor:'pointer' }}> {locale.accountManage}</span>
              <div id='wordpass'  className="account" >
                <div className="updatepasswords" onClick={updatePassWord}>{locale.updatePassword}</div>
                {user.isEnterpriseOwner || user.isStoreOwner ? <div className="updatepasswords" onClick={updateSettings}>{locale.settings}</div> : null}
                <div className="loginout" onClick={logout}>{locale.logout}</div>
              </div>
            </div>
          </ClickListener>
        </div>
        {/* <TextButton onClick={logout} style={{ marginRight: 45 }}>{locale.logout}</TextButton> */}
        {/* <div>{locale.notify}</div> */}
        <div><MessagePopover /></div>
      </div>
    </header>
  );
}));

export default Header;
