import React from 'react';
import ReactDOM from 'react-dom';
import Modal from './Modal';

const ConfirmModal = ({ 
  title, 
  content, 
  visible, 
  danger, 
  close, 
  isConfirm,
  onRequestSubmit,
  ...rest
}) => {
  function onSubmit() {
    onRequestSubmit();
    close();
  }
  return (
    <Modal
      modalHeading={title}
      open={visible}
      danger={danger}
      passiveModal={!isConfirm}
      onRequestClose={close.bind(this, { triggerCancel: true })}
      onRequestSubmit={onSubmit}
      {...rest}
    >
      {content}
    </Modal>
  );
};

export default function confirm(config) {
  const div = document.createElement('div');
  document.body.appendChild(div);

  function destroy(...args) {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
    const triggerCancel = args && args.length 
      && args.some(param => param && param.triggerCancel);
    if (config.onCancel && triggerCancel) {
      config.onCancel(...args);
    }
  }

  function render(props) {
    ReactDOM.render(<ConfirmModal {...props} />, div);
  }

  function close(...args) {
    render({ ...config, close, visible: false, afterClose: destroy.bind(this, ...args) });
  }

  render({ ...config, visible: true, close });

  return {
    destroy: close,
  };
}
