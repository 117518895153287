import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

const newChild = (children, tabIndex) => {
  const child = React.Children.only(children);
  return React.cloneElement(React.Children.only(child), {
    tabIndex,
    className: 'left-menu-list__item-link',
  });
};

const NavItem = ({ className, tabIndex, children, onClick, activeHref, ...rest }) => {
  const childHref = children.props.href === undefined ? children.props.to : children.props.href;
  const activePath = window.location && window.location.hash ? window.location.hash : activeHref;
  const classNames = classnames('left-menu-list__item', className, {
    'left-menu-list__item--active': activePath === childHref,
  });

  return (
    <li
      tabIndex={children ? -1 : tabIndex}
      role="menuitem"
      className={classNames}
      onClick={evt => onClick(evt, childHref)}
      onKeyPress={evt => onClick(evt, childHref)}
      {...rest}
    >
      {newChild(children, tabIndex)}
    </li>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  children: PropTypes.node,
};

NavItem.defaultProps = {
  tabIndex: 0,
  label: 'NavItem Label',
  onClick: /* istanbul ignore next */ () => {},
};

export default NavItem;
