import { Model } from 'qn/data';
import { observable } from 'mobx';

@Model({
  modelName: 'WithdrawalDateRecord',
  fields: {
    key: 'string',
    withdrawalNum: 'string',
    createdDate: 'date',
    enterpriseId: 'number',
    storeId: 'number',
    enterpriseName: 'string',
    storeName: 'string',
    status: 'number',
    amount: 'number',
    balance: 'number',
    // creatorUserId: 'number',
    // paymentType: 'string',
  },
  idProperty: 'key',
})
class WithdrawalDateRecord {
}

export default WithdrawalDateRecord;
