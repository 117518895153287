import React from 'react';
import { Tooltip } from 'carbon-components-react';
import PropTypes from 'prop-types';

// 截取字符串
function cutStr(value, length) {
  if (value.length <= length) {
    return value;
  }
  return value.substring(0, length).concat('...');
}

// 字符串格式化 words->显示字数
export const StringFormat = ({ value, words, nilRender = '--', ...props }) => {
  // 传入的value不为字符串
  let finalValue = value;
  let title = null;
  let style = words ? {
    ...props.style,
    display: 'block',
    width: `${words + 2}em`,
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  } : props.style;
  // 传入的value为字符串
  if (_.isString(value) && words) {
    finalValue = cutStr(value, words);
    style = props.style;
    title = value;
  }
  if (_.isNil(value) || value.trim() === '') {
    finalValue = nilRender;
  }
  if (title) {
    return (
      <Tooltip triggerText={title} showIcon={false}>
        <span {...props} style={style}>
          {finalValue}
        </span>
      </Tooltip>
    );
  }
  return (
    <span {...props} style={style}>
      {finalValue}
    </span>
  );
};
StringFormat.propTypes = {
  words: PropTypes.number,
};
