import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const Branch = Loadable({
  loader: () => import('./Branch'),
  loading: LCLoading,
});
const BranchDetail = Loadable({
  loader: () => import('./BranchDetail'),
  loading: LCLoading,
});
const BranchCreate = Loadable({
  loader: () => import('./BranchCreate'),
  loading: LCLoading,
});
// const BranchCreateSuccess = Loadable({
//   loader: () => import('./BranchCreateSuccess'),
//   loading: LCLoading,
// });

const BranchRoute = ({ match }) => {
  return (
    <Switch>
      {/* <Route exact path={`${match.path}/new/success`} component={BranchCreateSuccess} /> */}
      {/* <Route exact path={`${match.path}/new`} component={BranchCreate} /> */}
      <Route exact path={`${match.path}/:id/edit`} component={BranchCreate} />
      <Route exact path={`${match.path}/:id`} component={BranchDetail} />
      <Route exact path={`${match.path}`} component={Branch} />
    </Switch>
  );
}
export { BranchRoute, Branch, BranchDetail, BranchCreate };
