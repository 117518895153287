import React from 'react';
import './style/footer.scss';
import { observable, computed, runInAction, action } from 'mobx';
import withLocale from 'decorator/withLocale';
import { observer } from 'mobx-react';
import qrcodeImg from 'public/img/qrcode.png';
import NaviImg from 'public/img/navi.png';
import yayaImg from 'public/img/yaya.png';
import api from 'remote/api';


@withLocale
@observer
class Footer extends React.Component {
  @observable qrcodeImgOpen = false;
  @observable naviImgOpen = false;
  @observable yayaImgOpen = false;
  @observable allPayType = [];


  async componentDidMount() {
    const res = await api.get('/banking/allPayType');
    this.allPayType = res.data;
  }
  @action
  handleClickOutside = (bankingOrg) => {
    // if (bankingOrg == "DOCOMO") {
    //   window.open("https://www.ntt-west.co.jp/smb/assets/img/plan/smartpay/pdf/d.pdf")
    // } else 
    if (bankingOrg == "WECHAT") {
      window.open("https://pay.weixin.qq.com//wechatpay_guide/proper_rule.shtml")
    } else if (bankingOrg == "RAKUTENPAY") {
      window.open("https://pay.rakuten.co.jp/agreement/")
    } else if (bankingOrg == "GMO") {
      window.open("https://mp-faq.gmo-pg.com/s/")
    } else if (bankingOrg == "VIA") {
      window.open("https://ridewithvia.com/terms-of-use/")
    } else if (bankingOrg == "PAYPAY") {
      window.open("https://paypay.ne.jp/rd/cs-ppfb/help/mail-onboarding-new7/")
    } else if (bankingOrg == "ELEAU") {
      window.open("https://form.aupay.wallet.auone.jp/agreement/store/")
    } else if (bankingOrg == "NETSPAY") {
      window.open("https://www.nets.com.sg/terms-and-conditions/")
    } else if (bankingOrg == "ELEUNI") {
      window.open("http://www.unionpayintl.com/en/serviceCenter/questionAnswer/")
    } else if (bankingOrg == "ACD") {
      window.open("http://www.unionpayintl.com/en/serviceCenter/questionAnswer/")
    } else if (bankingOrg == "BTC") {
      window.open("https://bitcoin.org/zh_CN/faq")
    } else if (bankingOrg == "SOLPAY") {
      window.open("https://www.solpay.com/en/common/merchantfaq/")
    } else if (bankingOrg == "PAYPAYOL") {
      window.open("https://about.paypay.ne.jp/terms/merchant-online/rule/online/")
    }

  }
  payType = (allPayType) => {
    return (
      allPayType.map((item, i) => {
        if ((i + 1) % 7 != 0) {
          return (
            <img onClick={this.handleClickOutside.bind(this, item.bankingOrg)} src={item.iconNormalManagementUrl} style={{ cursor: 'hand' }} class="footer-img " />
          )
          // if (item.bankingOrg == "RAKUTENPAY") {
          //   return(
          //     <img onClick={this.handleClickOutside} src={item.iconNormalManagementUrl} class="footer-img "/>
          //   )
          // }else{
          //   return(
          //     <img src={item.iconNormalManagementUrl} class="footer-img "/>
          //   )
          // }
        } else {
          return (
            <span><img onClick={this.handleClickOutside.bind(this, item.bankingOrg)} src={item.iconNormalManagementUrl} style={{ cursor: 'hand' }} class="footer-img " /><br /></span>
          )
          // if (item.bankingOrg == "RAKUTENPAY") {
          //   console.info(item.bankingOrg)
          //   return(
          //     <span><img onClick={this.handleClickOutside} src={item.iconNormalManagementUrl} class="footer-img "/><br/></span>
          //   )
          // }else{
          //   return(
          //     <span><img src={item.iconNormalManagementUrl} class="footer-img "/><br/></span>
          //   )
          // }
        }
      })
    )
  }

  enlargeImages = (name) => {
    switch (name) {
      case 'qrcodeImg':
        this.qrcodeImgOpen = true;
        break;
      case 'naviImg':
        this.naviImgOpen = true;
        break;
      case 'yayaImg':
        this.yayaImgOpen = true;
        break;
    }
  }

  restoreImage = (name) => {
    switch (name) {
      case 'qrcodeImg':
        this.qrcodeImgOpen = false;
        break;
      case 'naviImg':
        this.naviImgOpen = false;
        break;
      case 'yayaImg':
        this.yayaImgOpen = false;
        break;
    }
  }

  render() {
    const { locale } = this.props;
    const lang = locale.footer;
    return (
      <div className="footer">
        <div className="footer-content align-items-center justify-content-center">
          <div className="d-flex align-items-start flex-1 ">
            <div className="footer-content__container">
              <div>
                <div className="footer-text__title font-s-18 font-w-semibold">{lang.concatInfo}</div>
                <div className="footer-border"></div>
                <div className="footer-content__text__left">
                  <div className="footer-text__normal font-s-16" style={{ marginBottom: 7, fontSize: 14, minHeight: 18 }}>{lang.concatMail}：info@qfpay.info</div>
                  <div className="footer-text__normal font-s-16" style={{ marginBottom: 7, fontSize: 14, minHeight: 18 }}>{lang.businessHours}：10:00 〜 17:00（{lang.except}）</div>
                  <div className="footer-text__normal font-s-16" style={{ marginBottom: 7, fontSize: 14, minHeight: 18 }}>{lang.address}</div>
                  <div className="footer-text__normal font-s-16" style={{ marginBottom: 7, fontSize: 14, minHeight: 18 }}>TEL：0120-501-080</div>
                  {/* <div className="footer-text__normal font-s-16" style={{ fontSize: 10,minHeight: 18 }}>FAX：03-5405-7997</div> */}
                </div>
              </div>
            </div>
            <div className="footer-content__container_left">
              <div className="footer-text__title font-s-18 font-w-semibold">{lang.aboutUs}</div>
              <div className="footer-border"></div>
              <div className="footer-margin_right">
                <div className="footer-content__container_top">
                  <div className=" my-img-big">
                    {
                      this.qrcodeImgOpen ?
                        <div className=" my-big-img"><span></span><img src={qrcodeImg} alt="" style={{ width: 140, height: 140 }} /></div>
                        : null
                    }
                  </div>
                  <div onMouseOver={() => this.enlargeImages('qrcodeImg')} onMouseOut={() => this.restoreImage('qrcodeImg')}><a href="javascript:void(0);">{lang.qfpay}</a></div>
                </div>
                {/* <div className="footer-content__container_top">
                <div className=" my-img-big">
                  {
                    this.naviImgOpen ?
                    <div className=" my-big-img"><span></span><img src={NaviImg} alt="" style={{ width: 140, height: 140 }} /></div>
                    : null
                  }
                </div>
                <div onMouseOver={() => this.enlargeImages('naviImg')} onMouseOut={() => this.restoreImage('naviImg')}><a href="javascript:void(0);">{lang.naviJapan}</a></div>
              </div> */}
                {/* <div className="footer-content__container_top">
                <div className=" my-img-big">
                  {
                    this.yayaImgOpen ?
                    <div className=" my-big-img"><span></span><img src={yayaImg} alt="" style={{ width: 140, height: 140 }} /></div>
                    : null
                  }
                </div>
                <div onMouseOver={() => this.enlargeImages('yayaImg')} onMouseOut={() => this.restoreImage('yayaImg')}><a href="http://yaya-translate.com/" target="view_window">{lang.yayaTraslation}</a></div>
              </div> */}
              </div>
            </div>
            <div className="footer-content__container_left">
              <div className="footer-text__title font-s-18 font-w-semibold">{lang.pay}</div>
              <div className="footer-border"></div>
              <div style={{ fontSize: 14 }}>{lang.supportPay}</div><br />
              <div>
                {this.payType(this.allPayType)}
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copy-right">Copyright © 2020 QFPay Japan Inc.All rights reserved.</div>
      </div>
    );
  };
};

export default Footer;
