import React from 'react';
// 手机号码格式化 手机号码带杠分割显示
export const PhoneFormat = ({ value, ...props }) => {
  const data = `${String(value).substr(0, 3)}-${String(value).substr(3, 4)}-${String(value).substr(7, 4)}`;
  return (
    <span {...props}>
      {value ? data : '--'}
    </span>
  );
};
