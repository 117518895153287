import React from 'react';
import logo from 'public/img/logo.png';
import './style.scss';
import cx from 'classnames';
import withLocale from 'decorator/withLocale';

@withLocale
class LoginHeader extends React.Component {

  changeLang = (lang) => {
    this.props.setLocale(lang);
  }

  render() {
    const { locale } = this.props;
    if(locale.l === 'jp'){
      document.getElementById("app").style.fontFamily="\"Open Sans\",\"Meiryo\", \"ヒラギノ角ゴ Pro W3\", \"Hiragino Kaku Gothic Pro\", \"ＭＳ Ｐゴシック\", Arial, Sans-serif";
    } else if(locale.l === 'zh-CN'){
      document.getElementById("app").style.fontFamily="\"PingFang SC\",Arial,simsun,sans-serif";
    } else {
      document.getElementById("app").style.fontFamily="Helvetica Neue, Helvetica, Arial, sans-serif";
    }
    //document.getElementById("app").style.fontFamily="Meiryo, \"ヒラギノ角ゴ Pro W3\", \"Hiragino Kaku Gothic Pro\", \"ＭＳ Ｐゴシック\", Arial, Sans-serif";
    const langZhCNClassName = cx('login-header__right__lang', {
      'login-header__right__lang--active': locale.l === 'zh-CN',
    });
    const langENClassName = cx('login-header__right__lang', {
      'login-header__right__lang--active': locale.l === 'en',
    });
    const langJPClassName = cx('login-header__right__lang', {
      'login-header__right__lang--active': locale.l === 'jp',
    });
    const brand = this.props.locale.brand;
    return (
      <header className="d-flex justify-content-between login-header my_header">
        <div style={{ marginLeft: 55, height: 28 }} className="d-flex align-items-center login-header__left my_header_left">
          <img src={logo} alt="" style={{ width: 163, height: 28 }} />
          <span className="login-header__left__divide divide-v" />
          <strong className="login-header__left__brand">{brand}</strong>
        </div>
        <div style={{ marginRight: 26, height: 28 }} className="d-flex align-items-center login-header__right my_header_right">
          <span onClick={() => this.changeLang('jp')} className={langJPClassName}>日本語</span>
          <span className="login-header__right__divide divide-v" />
          <span onClick={() => this.changeLang('en')} className={langENClassName}>English</span>
          <span className="login-header__right__divide divide-v" />
          <span onClick={() => this.changeLang('zh-CN')} className={langZhCNClassName}>简体中文</span>
        </div>
      </header>
    );
  }
}
export default LoginHeader;
