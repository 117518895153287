export default {
  l: 'zh-CN',
  param: 'zh_CN',
  lang: '简体中文',
  langSelection: '语言选择',
  brand: '商户管理系统',
  searchMore: '更多搜索',
  search: '搜索',
  modify: '修改',
  delete: '删除',
  notify: '通知',
  export: '导出',
  exportCSV: '导出CSV',
  exportExcel: '导出Excel',
  allexport: '全部导出',
  exportOriginal: '导出原始数据',
  withdrawalDetail: '提现详情',
  all: '全部',
  rests: '其他',
  restsChannel: '其他支付渠道',
  complete: '完成',
  cancel: '取消',
  add: '添加',
  back: '返回',
  logout: '退出',
  logoutConfirm: '确认退出系统吗？',
  submit: '提交',
  yes: '是',
  no: '否',
  moreSearchCriteria: '更多搜索条件',
  expand: '展开',
  takeUp: '收起',
  storeExpand: '详细店铺展开',
  storeTakeUp: '详细店铺收起',
  psdError: '您输入的密码不正确',
  noWebLoginAccess: '抱歉，您没有登录权限',
  operator: '操作',
  detail: '详情',
  updatePassword: '设置密码',
  accountManage: '账号管理',
  tradeFrom: '交易流水表',
  store: '店铺',
  storeUser: '店员',
  select: '搜索条件',
  noAuthority: '您没有权限',
  certificateOfIncorporation: '营业执照',
  settings: '基本设置',
  tip: '提示',
  login: {
    id: '登录ID',
    idPlaceholder: '请输入邮箱地址',
    storeownerEmail: '店主邮箱',
    psd: '密码',
    psdPlaceholder: '请输入密码',
    workNumber: '工号',
    workNumberPlaceholder: '请输入工号',
    autoLogin: '自动登录',
    shopkeeperLogin: '管理者登录',
    employeeLogin: '员工登录',
    forgetPsd: '忘记密码',
    forgetPsdTitle: '忘记密码',
    submit: '登录',
    noLoginIdTip: '还没有账号？',
    registerNow: '立即注册',
    kickOut: '由于刚才其他用户使用同一账号登录了系统，您已经被强制退出了登录！请联系贵公司其他用户确定是否在使用相同的账户。',
    rejectInfo: '您的账户申请已被拒绝，请联系相关营业代理人员修改您提交的信息！',
    pleaseEnterLoginID: '登录ID',
    rescindInfo: '您所在的商户已经解约，不可进行交易。'
  },
  register: {
    mail: '邮箱',
    mailPlaceholder: '请输入邮箱地址',
    mailPlaceholderStore: '店铺用户登录ID',
    mailPlaceholderEnterprise: '企业用户登录ID',
    defaultPassword: '初始密码为123456',
    theDefaultPassword: '默认密码',
    psd: '密码',
    psdPlaceholder: '请输入密码',
    psdConfirm: '确认密码',
    psdConfirmPlaceholder: '请再次输入密码',
    captcha: '验证码',
    captchaPlaceholder: '请输入验证码',
    termsOfService: '服务条款',
    privacyRelatedPolicies: '隐私相关政策',
    agree: '同意',
    agreeAndSendMail: '同意服务条款，并发送至邮箱',
    and: '和',
    submit: '注册',
    hasLoginId: '已有账号',
    toLogin: '去登录',
    oldPsd: '原密码',
    newPsd: '新密码',
    psdOldPlaceholder: '请输入原密码',
    prompt: '点击下一页翻页,阅读完毕后才可以点击同意',
    titlePrompt: '若使用QFPay为您服务请先同意QFPay服务条款。请您先确认以下的内容后，点击您同意使用的“同意服务条款，并发送至邮箱”按键。'
  },
  registerMore: {
    belongToEInfo: '所属公司信息',
    belongToEName: '所属企业名称',
    hasEnterprise: '所属企业是否已注册',
    has: '有',
    not: '无',
    bankInfoRequired: '独立结算需要输入银行信息',
  },
  registerType: {
    title: '请选择账户类型',
    single: '单店',
    chain: '连锁店',
    personal: '个人',
    enterprise: '企业',
  },
  registerStep: {
    register: '注册账号',
    moreInfo: '完善信息',
    waitingReview: '等待审核',
  },
  footer: {
    concatInfo: '联系方式',
    concatMail: '客服邮箱',
    concatTel: '客服电话',
    businessHours: '营业时间',
    address: '住所：〒105-0001 東京都港区虎ノ門 1-10-5 KDX Toranomon 1 Chome 11F 株式会社QFPay Japan',
    except: '休息日、节假日除外',
    useRole: '利用规约',
    privacy: '隐私协议',
    companyInfo: '公司信息',
    aboutUs: '关于我们',
    qfpay: 'QFPay Japan',
    about: '关于',
    naviJapan: 'Navi Japan',
    yayaTraslation: 'Yaya Translation',
    pay: '支付',
    supportPay: 'QFPay支持的支付方式',
  },
  nav: {
    homePage: '首页',
    tranManagemant: '交易管理',
    propManagement: '宣传推广',
    promActivities: '优惠活动',
    mercManagement: '商户管理',
    employeeManagement: '员工管理',
    toReview: '待审核',
    branchStoreManagement: '分店管理',
    subscription: '签约信息',
    approvedReview: '渠道审核',
    approvedReviews: '汇率设定',
    withdrawalManagement: '提现管理',
    settlementManagement: '清算管理',
    deviceManagement: '设备管理',
    barcodeImage: '收款二维码',
    settlement: '清算管理',
    tranQuery: '交易查询',
    updatePassword: '设置密码',
    advertiseManage: '广告管理',
    userManagement: '用户管理',
    exceptionOrder: '异常订单',
    settingsManage: '邮件管理',
    disposeBarCode: '可配置二维码',
    acdExchangeRate: 'ACD汇率',
    internationalExchangeRate: '国际汇率',
    savvyPoint: '积分',
    pointBillManagement: '积分账单管理',
    exchangeRateManagement: '汇率管理',
  },
  permissions: {
    loginAccess: '登录权限',
    tranManagemant: '交易管理',
    propManagement: '宣传推广',
    mercManagement: '商户管理',
    deviceManagement: '设备管理',
    QFPayPlus: 'QFPay+'
  },
  dashboard: {
    scanIncome: '今日扫码收入',
    incomeNumber: '收入笔数',
    notice: '平台通知',
    noticeCount: '条新消息',
    incomeOfMonth: '近30天收入金额',
    currentPromot: '当前店内优惠活动',
    notAvailable: '暂无',
    incomeStat: '收入统计',
    storeIncomeStat: '分店收入统计',
    lastMonth: '最近30天',
    lastWeek: '最近一周',
    totalIncome: '总收入',
    refundsAmount: '退款笔数',
    paymentChannel: '支付渠道',
    count: '笔',
    amount: '交易金额(円)'
  },
  user: {
    code: '员工工号',
    employeeCode: '员工工号',
    code_p: '请输入员工工号',
    name: '员工姓名',
    name_p: '请输入员工姓名',
    permission: '权限',
    permissionFilters: '权限',
    concatInfo: '联系方式',
    concatInfo_p: '请输入员工联系方式',
    operate: '操作',
    password: '登录密码',
    password_p: '请输入登录密码',
    deleteInfo: '您确定要删除员工(工号{code})？',
    addEmployee: '添加员工',
    editEmployee: '修改员工',
    tip: '提示',
    printerId: '打印设备号',
    payDeviceId: '付款设备号',
    bindDevice: '绑定设备',
    deviceIdDuplicate: '该员工已成功解绑设备。',
    unbind: '解绑',
    export: '导出',
    userInfo: '员工信息表',
    downloadQRCode: '下载二维码',
    storeBrCode: '下载店铺二维码',
    addBrCode: '新增收款码',
    clickToDownloadQRCode: '点击下载静态支付二维码',
    formerDeleteUser: '请将设备解绑',
    branchStoreName: '分店名称',
    userName: '用户姓名',
    addUser: '添加用户',
    editUser: '修改用户',
    id: '登录ID',
    id_p: '请输入登录账号',
    deleteInfo1: '您确定要删除用户({code})？',
  },
  registerAftermail: {
    agentManagement: '代理商管理',
    homePagenew: '代理商主页',
    create: '创建代理',
    mailtitle: '邮箱验证',
    agentInformation: '代理商信息',
    success: '完成',
    mailRegistration: '邮箱注册',
    captcha: '获取验证码',
    newcaptcha: '验证码:',
    bankInfo: '银行信息',
    bankName: '银行名称',
    bankCodePlaceholder: '银行编号',
    branchBankNamePlaceholder: '银行分行名称',
    branchBankCodePlaceholder: '银行分行编号',
    branchTypePlaceholder: '例：支店',
    accountTypePlaceholder: '例：普通預金',
    bankCode: '银行编号',
    bankType: '银行种类',
    branchName: '支行名称',
    branchnumber: '支行编号',
    Subbranchtype: '支行类型',
    Accounttype: '账户类型',
    Bankaccountname: '银行账户名称(半角片假名)',
    Bankaccountname2: '银行账户名称',
    Bankaccount: '银行账号',
    psd: '输入密码',
    psdConfirm: '确认密码',
    bankInfo: '银行信息',
    ChannelAddition: '渠道添加',
    submit: '提交',
    man: '男',
    woman: '女',
    mailtitle: '邮箱验证',
    agentInformation: '代理商信息',
    corporateInformation: '法人信息',
    Contacts: '联系人信息',
    undertaker: '担当者情报',
    success: '完成',
    Preservation: '保存已填写内容',
    trueEmail: '请输入正确的邮箱!',
    filEmail: '请填写邮箱',
    psd: '密码',
    popcontent: '填写过程退出程序记得保存，再次登录可继续填写',
    fillcode: '请填写验证码',
    psdPlaceholder: '请输入密码!',
    psdConfirmPlaceholder: '请再次输入密码!',
    fillInformation: '请填写信息',
    fillNumber: '请填写号码',
    filEmail: '请填写邮箱',
    rate: '请填写费率',
    addTips: '（提示：默认开通所有渠道）',
    toLogin1: '已有帐号，',
    toLogin2: '去登陆',
    picDetail: '请上传清晰、实拍的图片，请不要在图片上添加文字、数字、网址等内容，请勿上传名片、二维码、自拍照、风景照等无关图片，支持JPG、PNG格式，最多上传9张，每张最大2M',
    WeChatExplain: '是由中国最为通用的一款聊天软件“微信”来为您提供支付服务---“WeChat Pay”。',
    AlipayExplain: '“支付宝”以淘宝网络购物为开端，受到全球10亿余（※统计至2019年1月）用户的青睐，是堪称世界第三的支付服务平台。',
    OrigamiExplain: 'Origami以“创造金钱、支付、和商业的未来”为时代理念，提供无现金支付平台。',
    PaypayExplain: '是由软银和雅虎日本携手打造的一款手机支付软件---PayPay。',
    NetspayExplain: '新加坡三大银行皆可进行二维码支付的一款便捷服务。',
    RakutenpayExplain: '乐天支付，即便您不在乐天，只要手持乐天ID就可随时随地进行便捷支付。',
    UmspayExplain: '银联（UnionPay），确立了继国际5大经济品牌（VISA、MASTER、JCB、AMERICAN EXPRESS、DINERS CLUB）的第六国际品牌地位。',
    VIAExplain: '可使用新加坡的Dash、泰国的Global Pay的一款支付服务。',
    DocomoExplain: 'docomo支付，是一款可以将大街小巷网络支付与手机充值合并结算、简单而便捷、实惠的手机支付服务。',
    PringExplain: 'pring，是一款将金钱交易以如同亲朋好友间消息发送的往来方式进行支付的手机软件。',
    SolPayExpain: '韩国国内排行第二位的新韩银行所提供的QR服务',
    GMOExpalin: '专精线上交易的gateway系统',
    DiXingExpalin: '提供高效的跨境支付与银行会员服务',
    tBTCExplain: '这是一种ERC20代币,得到可靠的支持并可兑换成比特币。',
    BTCExplain: '比特币是一个共识网络，促成了一个全新的支付系统和一种完全数字化的货币。它是第一个去中心化的对等支付网络，由其用户自己掌控而无须中央管理机构或中间人。从用户的角度来看，比特币很像互联网的现金。比特币也可以看作是目前最杰出的三式簿记系统。',
    ACDExplain: 'ACD COIN同航空公司、EC平台进行合作，应用于全球线上、线下商铺。',
    ContentPic1: '個人事業主の場合は、必ず営業許可証が必要になります。 (写真/スキャンデーター)',
    ContentPic2: '振込口座が確認できるページの写真/スキャンデーター',

  },
  guide: {
    title: '温馨提示',
    hidden: '下次不再提示',
    know: '知道了',
    BrCode1: '收款二维码功能重新设计了',
    BrCode2: '您可以查询和下载一位员工对应的收款二维码了。点击每位店员列表后的二维码图标可以打开收款二维码下载页面下载对应店员的二维码(点击店长对应的图标可以下载店长的二维码)',
    BrCode3: '点击"新增收款码"按钮，可以创建一位新员工，由于员工和二维码是一一对应的，同时也创建了该员工对应的收款二维码。',
    User1: '员工管理页面做了用户体验优化',
    User2: '权限筛选条件进行分组排版, 选择时可以一目了然, 添加和修改员工界面的权限选择也做了相同的改动。',
    User3: '员工权限如果很多一行显示不下, 可以鼠标悬停三个点的位置查看具体权限列表。',
    device1: '设备管理页面做了用户体验优化',
    device2: '此界面已升级，可以用于管理通过QFPay购买的QM800来绑定收银员，也可以用于创建和管理商米等安卓系统设备。',
    device3: '',
    device4: '',
    device5: '',
    device6: ''
  },
  device: {
    deviceId: '设备号',
    storeName: '店铺名',
    importDeviceId: '请输入设备号',
    importStoreName: '请输入店铺名称',
    bindingState: '设备是否已绑定',
    all: '全部',
    yes: '是',
    no: '否',
    add: '添加',
    softwareVersion: '软件版本号',
    bindingStore: '绑定店铺',
    operate: '操作',
    binding: '绑定',
    unbind: '解绑',
    delete: '删除',
    tip: '提示',
    deleteInfo: '您确定要删除设备(设备号{code})？',
    unbindInfo: '您确定要解绑此设备吗？',
    edit: '编辑',
    remark: '备注',
    addError: '操作失败,此设备号已存在!',
    staff: '店员名称',
    bindingStaff: '绑定店员',
    comment: '※在备注里记录设备的详细信息，标记设备容易区分。',
    deviceType: '设备类型',
    hardware: '硬件设备',
    android: '安卓设备',
    formerDeleteUser: '请将设备解绑',
    AndroidDevicesBinding: '安卓设备必须绑定店铺',
  },
  access: {
    APP_LOGIN: '登录app',
    WEB_LOGIN: '登录管理系统',
    SUPER_ADMIN: 'SUPER_ADMIN',
    ENTERPRISE_OWNER: 'ENTERPRISE_OWNER',
    STORE_OWNER: 'STORE_OWNER',
    MANAGE_EMPLOYEE: '员工管理',
    MANAGE_PROMOTION_CAMPAIGN: '管理优惠活动',
    TRADE_QUERY: '交易查询',
    TRADE_STORE_QUERY: '本店交易查询',
    SETTLEMENT_MANAGEMENT: '清算管理',
    WITHDRAWAL_MANAGEMENT: '提现管理',
    PAY_BARCODE: '静态付款码',
    CONTRACT_INFO: '签约信息',
    ENTERPRISE_USER: '用户管理',
    STORE_DEVICE_MANAGEMENT: '设备管理',
    ENTERPRISE_DEVICE_MANAGEMENT: '设备管理',
    MANAGE_BRANCH: '分店管理',
    ORDER_REFUND: '交易退款',
    FIXED_AMOUNT_QR_CODE: '可配置二维码'
  },
  receivables: {
    code: '单号',
    originTradeNo: '原始单号',
    storeId: '店铺ID',
    storeName: '店铺名',
    storeAddress: '店铺地址',
    promotionName: '活动名称',
    originAmount: '活动前价格',
    serviceCashing: '交易手续费',
    netAmount: '交易净额',
    cashier: '收银员编号',
    tragePeriod: '交易时间',
    trageTime: '交易时间',
    businessWaterNumberTitle: '商户订单号(QFPay发行)',
    null: '无',
    TradingTime: '首次交易时间',
    initTradingTime: '首次交易时间',
    currency: '币种',
    finalTotalAmount: '订单金额',
    payMethod: '具体支付方式',
    payMethodExplain: '<p style="text-align:left;font-size:14px;">具体支付方式说明:</p><p style="text-align:left;font-size:14px;">1.动态客户扫商户: 商户生成付款二维码，顾客用相应的钱包付款</p><p style="text-align:left;font-size:14px;">2.动态商户扫顾客: 顾客出示付款二维码，商户扫码收款</p><p style="text-align:left;font-size:14px;">3.静态客户输入金额: 顾客扫商户的静态二维码，输入付款金额，进行付款</p><p style="text-align:left;font-size:14px;">4.静态商户输入金额: 商户输入金额，顾客扫商户的静态二维码进行付款</p><p style="text-align:left;font-size:14px;">5.小程序支付: 在微信小程序内发起的支付</p>',
    amount: '交易金额',
    amountDetails: '交易金额',
    operateAccount: '操作账号',
    storeDeviceId: '设备号',
    trageChannel: '交易渠道',
    promotionType: '活动类型',
    orderStatus: '订单状态',
    operate: '操作',
    refund: '退款',
    branchStoreName: '分店名称',
    refundChannel: '退款渠道',
    canRefundMoney: '可退款金额',
    refundMoney: '退款金额',
    paySuccess: '支付成功',
    payFailure: '支付失败',
    refunded: '已退款',
    refundFailure: '退款失败',
    refundFailureState: '退款失败,请联系客服,电话：0120-501-080',
    refundSuccess: '退款申请已经成功，用户会收到${wallet}发送的退款通知',
    orderClose: '订单关闭',
    tradeAmount: '交易金额',
    offerType: '活动类型',
    noCampaign: '没有活动',
    businessWaterNumber: '商户订单号',
    businessWaterNumberExplain1: '<p style="text-align:left;font-size:14px;">商户订单号:</p><p style="text-align:left;font-size:14px;">${channel}生成订单号</p>',
    businessWaterNumberExplain2: '<p style="text-align:left;font-size:14px;">商户订单号(QFPay发行):</p><p style="text-align:left;font-size:14px;">QFPay生成订单号</p>',
    issued: '(QFPay发行)',
    enterpriseLoginId: '企业Id',
    storeLoginId: '店铺Id',
    businessWaterNumberBranchStoreNameAdmin: '请输入商户订单号(QFPay发行)/操作账号/设备号/登入Id',
    businessWaterNumberBranchStoreName: '请输入商户订单号(QFPay发行)/分店名称',
    businessWaterNumberOperateAccount: '请输入商户订单号(QFPay发行)/操作账号/设备号',
    transNumber: '交易笔数',
    transMoney: '交易总金额',
    comment: '备注',
    discounts: '优惠券',
    rate: '费率',
    rateUnit: '(以百分之一为单位)',
    settlementTime: '清算时间',
    activityName: '活动名称',
    choose: '选择活动',
    chooseStores: '选择分店',
    chooseHint: '请选择活动添加至搜索条件',
    scanType: {
      QR: '动态客户扫商户',
      Scan: '动态商户扫顾客',
      StaticQR: '静态客户输入金额',
      Applet: '小程序支付',
    },
    clickToAddOrRemove: '点击分店名称即可添加或删除',
    selectedStore: '已选择的分店',
    chooseDevice: '选择设备',
    chooseDeviceHint: '请选择设备号添加至搜索条件',
    chooseColumn: '选择导出内容',
    columns: {
      TradeNumber: "商户订单号(QFPay发行)",
      OriginalTradeNumber: "原始单号",
      ChannelTransactionId: "渠道订单号",
      TradeTime: "交易时间",
      Currency: "币种",
      Amount: "交易金额",
      TransactionFee: "交易手续费",
      Netamount: "交易净额",
      BankingOrg: "交易渠道",
      Status: "交易状态",
      StoreId: "分店ID",
      StoreName: "分店名",
      Operator: "操作员",
      CashierNumber: "收银员编号",
      StoreDeviceId: "设备号",
      payMethod: "具体支付方式",
      CampaignType: "活动类型",
      CampaignName: "活动名称",
      AmountbeforeCampaign: "优惠前金额",
      Remark: "备注",
      Poundage: "费率",
      SettlementDate: "清算时间",
      PointAmount: '消耗积分',
      Deduction: '积分抵扣金额',
      OriginalAmount: '抵扣前金额'
    },
    savvyPoint: '积分',
    pointAmount: '消耗积分',
    deduction: '积分抵扣金额',
    originalAmount: '抵扣前金额',
    pointRefundSuccess: '退款申请已经成功',
    exchangeCurrency: '实际支付币种',
    exchangeAmount: '实际支付金额'
  },
  exceptionOrder: {
    exceptionStatus: '异常状态',
    newCreate: '新建',
    solveSuccess: '解决成功',
    solveFailure: '解决失败',
    manualSolution: '等待人工解决',
    retryTimes: '重试次数',
    MarkAsSolve: '标记为已解决',
    askToSolve: '确定要将该异常订单标记为已解决吗?',
  },
  transaction: {
    tabName: '收款明细',
  },
  campaign: {
    activityTime: '活动时间',
    activityType: '活动类型',
    activityStatus: '活动状态',
    activityName: '活动名称',
    inProcess: '正在进行',
    terminated: '已经终止',
    unStart: '尚未开始',
    activityRange: '活动范围',
    operate: '操作',
    inputName: '请输入活动名称',
    startAt: '开始时间',
    endAt: '结束时间',
    downloadQRCode: '下载二维码',
    QRCodeCount: '二维码数量',
    QRCodeSize: '二维码大小',
    codeCanUseTime: '优惠码可使用次数',
    codeCanUseTimePlaceholder: '请填写优惠码可使用次数',
    unlimited: '不限',
    customQuantity: '自定义数量',
    nextStep: '下一步',
    lastStep: '上一步',
    overlayUse: '是否叠加使用',
    modifyActivityInfo: '修改活动信息',
    activityValidity: '活动有效期',
    activityAmount: '活动金额',
    remark: '备注',
    pleaseChooseType: '请选择您要创建的优惠活动类型',
    pleaseInputActivityContent: '请输入优惠活动内容',
    release: '发布',
    selectType: '选择类型',
    inputContent: '输入内容',
    activityRelease: '发布活动',
    giftName: '礼品名称',
    giftPhoto: '礼品图片',
    off: '减免金额',
    new: '新建活动',
    allBranch: '全部分店',
    usedTimess: '使用次数',
  },
  barcode: {
    comment: '收款二维码为静态二维码，可以下载打印后置于贵店中。顾客自己主动扫码进行付款时使用。',
  },
  bankInfo: {
    title: '银行信息',
    bankCode: '银行编号',
    bankName: '银行名称',
    settlementCurrency: '结算货币',
    accountType: '账户类型',
    accountName: '账户持有人姓名',
    accountCode: '账户编号',
    branchName: '银行分行名称',
    branchCode: '银行分行编号',
    noData: '您还没有创建银行信息',
    accountTypeEnum: {
      SAVINGS: '普通',
      CHECKING: '当座',
    },
    bankTypeEnum: {
      BANK: '银行',
      FINANCIAL_COOPERATIVE: '金融合作社',
      CREDIT_COOPERATIVE: '信用合作社',
      AGRICULTURAL_COOPERATIVE: '农业合作社',
    },
    branchTypeEnum: {
      BRANCH_STORE: '分店',
      HEAD_OFFICE: '总店',
      FIELD_OFFICE: '外地办事处',
      BRANCH_OFFICE: '分所',
    },
    bankNamePlaceholder: '例：三菱銀行',
    bankCodePlaceholder: '例：0005',
    bankTypePlaceholder: '例：銀行',
    branchBankNamePlaceholder: '例：新瑞橋支店',
    branchBankCodePlaceholder: '例：251',
    branchTypePlaceholder: '例：支店',
    accountTypePlaceholder: '例：普通預金',
    accountNameHalfKataPlaceholder: '例：カブシキガイシャ〇〇〇〇',
    accountNamePlaceholder: '例：株式会社〇〇〇〇',
    accountCodePlaceholder: '例：12414141',
  },
  branch: {
    SettlementExplain1: '<p style="text-align:left;font-size:14px;">独立结算店铺：</p><p style="text-align:left;font-size:14px;">交易的余额不计入企余额中，</p><p style="text-align:left;font-size:14px;">由店铺直接与QFPay做清算、提现和打款的店铺类型，</p><p style="text-align:left;font-size:14px;">QFPay会按照该类型店铺的提现申请，</p><p style="text-align:left;font-size:14px;">单独向该店铺系统提供的银行地址进行汇款；</p>',
    SettlementExplain2: '<p style="text-align:left;font-size:14px;">非独立结算店铺：</p><p style="text-align:left;font-size:14px;">QFPay系统中的通常店铺类型，</p><p style="text-align:left;font-size:14px;">企业下的各非独立清算店铺的统一展现在企业ID的余额中，</p><p style="text-align:left;font-size:14px;">清算、提现操作由企业ID统一提交，</p><p style="text-align:left;font-size:14px;">QFPay直接将体现金额汇款至QFPay系统提供的企业的银行账户下；</p>',
    SettlementType1: '独立结算',
    SettlementType2: '非独立结算',
    uid: '店铺ID',
    createAt: '创建时间',
    status: '状态',
    auditSuccess: '审核通过',
    auditing: '审核中',
    auditFailure: '审核失败',
    rescind: '已解约',
    name: '分店名称',
    code: '分店编号',
    address: '分店地址',
    storeStatus: '分店状态',
    mail: '邮箱',
    tel: '联系电话',
    operate: '操作',
    view: '查看',
    addStore: '添加分店',
    resubmit: '再次提交',
    storeInfo: '分店信息',
    accountInfo: '账号信息',
    type: '结算类别',
    bankInfo: '银行信息',
    didShowBankInfo: '是否显示本银行信息',
    didFillingBankInfo: '是否填写本银行信息',
    fillExplain: '是否要为该分店填写银行信息。',
    showExplain: '是否在分店用户登录时在签约信息里显示该分店的银行信息，如果选择不显示则显示企业的银行信息。',
    bankCode: '银行编号',
    remark: '分店备注',
    waitingTip: '您提交的申请正在审核中，我们将在1-2个工作日内处理完毕，请耐心等待。',
    failureTip: '很抱歉，您提交的商户信息有部分信息未审核通过，请您编辑后重新提交。',
    loginEmail: '此邮箱即为当前分店的登录ID',
    attachedStore: '附属店铺',
  },
  branchAuditStatus: {
    NORMAL: '正常',
    TO_REVISE_MATERIAL: '审核失败',
    AWAITING_REVIEW: '待审核',
    RESCIND: "已解约"
  },
  pendingReviewList: {
    registerTime: '注册时间',
    userID: '登录ID',
    userType: '用户类型',
    registerType: '注册类型',
    lastUpdateTime: '最后更新时间',
    operate: '操作',
    audit: '审核',
    not: '无',
    reviewDate: '上次审核时间',
    searchOther: '名称',
    enterpriseName: '所属企业名',
    storeName: '分店名',
    auditStatus: '信息审核状态',
    auditing: '信息待审核',
    status: "状态"
  },
  registerTypeConst: {
    SELF_REGISTERED: '自行注册',
    ENTERPRISE_REGISTERED: '企业注册',
  },
  agentCreate: {
    agentManagement: '代理商管理',
    homePagenew: '代理商主页',
    create: '创建代理',
    mailRegistration: '邮箱注册',
    mailConfirm: '确认邮箱',
    mailConfirmPlaceholder: '请再次输入邮箱',
    captcha: '获取验证码',
    newcaptcha: '验证码:',
    bankInfo: '银行信息',
    bankName: '银行名称',
    bankCode: '银行编号',
    bankType: '银行种类',
    branchName: '支行名称',
    branchnumber: '支行编号',
    Subbranchtype: '支行类型',
    Accounttype: '账户类型',
    Bankaccountname: '银行账户名称(半角片假名)',
    Bankaccountname2: '银行账户名称',
    Bankaccount: '银行账号',
    captchaPlaceholder: '请输入邮箱中的验证码，如：k2j4',
    mailPlaceholder: '例：qfpay@qfpay.com',
    psd: '输入密码',
    psd2: "******",
    psdConfirm: '确认密码',
    psdConfirmPlaceholder: '请再次输入密码',
    psdConfirmNewPlaceholder: '请再次输入新密码',
    bankInfo: '银行信息',
    ChannelAddition: '渠道添加',
    submit: '提交',
    man: '男',
    woman: '女',
    mailtitle: '邮箱验证',
    agentInformation: '代理商信息',
    corporateInformation: '法人信息',
    Contacts: '联系人信息',
    undertaker: '担当者情报',
    success: '完成',
    Preservation: '保存已填写内容',
    trueEmail: '请输入正确的邮箱!',
    filEmail: '请填写邮箱',
    psd: '密码',
    popcontent: '填写过程退出程序记得保存，再次登录可继续填写',
    fillcode: '请填写验证码',
    psdPlaceholder: '请输入密码!',
    psdConfirmPlaceholder: '请再次输入密码!',
    fillInformation: '请填写信息',
    fillNumber: '请填写号码',
    filEmail: '请填写邮箱',
    rate: '请填写费率',
    rateTip1: '费率的范围为: 最小值为:',
    rateTip2: '最大值为:',
    rateTip3: '',
    picDetail: '请上传清晰、实拍的图片，请不要在图片上添加文字、数字、网址等内容，请勿上传名片、二维码、自拍照、风景照等无关图片，支持JPG、PNG格式，最多上传9张，每张最大2M',
    WeChatExplain: '是由中国最为通用的一款聊天软件“微信”来为您提供支付服务---“WeChat Pay”。',
    AlipayExplain: '“支付宝”以淘宝网络购物为开端，受到全球10亿余（※统计至2019年1月）用户的青睐，是堪称世界第三的支付服务平台。',
    OrigamiExplain: 'Origami以“创造金钱、支付、和商业的未来”为时代理念，提供无现金支付平台。',
    PaypayExplain: '是由软银和雅虎日本携手打造的一款手机支付软件---PayPay。',
    NetspayExplain: '新加坡三大银行皆可进行二维码支付的一款便捷服务。',
    RakutenpayExplain: '乐天支付，即便您不在乐天，只要手持乐天ID就可随时随地进行便捷支付。',
    UmspayExplain: '银联（UnionPay），确立了继国际5大经济品牌（VISA、MASTER、JCB、AMERICAN EXPRESS、DINERS CLUB）的第六国际品牌地位。',
    VIAExplain: '可使用新加坡的Dash、泰国的Global Pay的一款支付服务。',
    DocomoExplain: 'docomo支付，是一款可以将大街小巷网络支付与手机充值合并结算、简单而便捷、实惠的手机支付服务。',
    PringExplain: 'pring，是一款将金钱交易以如同亲朋好友间消息发送的往来方式进行支付的手机软件。',
    SolPayExpain: '韩国国内排行第二位的新韩银行所提供的QR服务',
    GMOExpalin: '专精线上交易的gateway系统',
    DiXingExpalin: '提供高效的跨境支付与银行会员服务',
    tBTCExplain: '这是一种ERC20代币,得到可靠的支持并可兑换成比特币。',
    BTCExplain: '比特币是一个共识网络，促成了一个全新的支付系统和一种完全数字化的货币。它是第一个去中心化的对等支付网络，由其用户自己掌控而无须中央管理机构或中间人。从用户的角度来看，比特币很像互联网的现金。比特币也可以看作是目前最杰出的三式簿记系统。',
    ACDExplain: 'ACD COIN同航空公司、EC平台进行合作，应用于全球线上、线下商铺。',
  },
  userTypeConst: {
    SINGLE: '单店',
    STORE_USER: '分店',
    ENTERPRISE_USER: '企业',
    INCOMPLETE_REGISTRATION: '未填写信息',
  },
  AttachedUserTypeConst: {
    SINGLE: '单店',
    STORE_USER: '企业附属分店',
    ENTERPRISE_USER: '企业',
    INCOMPLETE_REGISTRATION: '未填写信息',
  },
  reviewDetail: {
    revoke: '撤销',
    reject: '拒绝',
    storeInfo: '店铺信息',
    storeName: '店铺名称',
    storeAddress: '店铺地址',
    storeEmail: '店铺邮箱',
    isDevicePurchase: '是否需要购买设备',
    storeTel: '店铺联系电话',
    bankInfo: '银行信息',
    bankInfoOld: '原银行信息',
    didFillingBankInfo: '是否填写本银行信息',
    fillExplain: '是否要为该分店填写银行信息。',
    showExplain: '是否在分店用户登录时在签约信息里显示该分店的银行信息，如果选择不显示则显示企业的银行信息。',
    bankCode: '银行编号',
    bankName: '银行名称',
    branchBankCode: '银行支行编号',
    branchBankName: '银行支行名称',
    accountType: '账户类型',
    accountName: '银行账户名称',
    accountCode: '银行账号',
    settlementCurrency: '清算货币',
    didIndependentSettlement: '是否独立清算',
    ok: '通过',
    refuse: '拒绝',
    audit: '待审核',
    auditBankInfo: '待审核银行信息'
  },
  withdrawalManagement: {
    withdrawalTime: '提现申请时间',
    searchOther: '分店名',
    status: '状态',
    toPay: '待付款',
    ToBeProcessed: '待处理',
    paidAlready: '已付款',
    withdrawalOrderNum: '提现单号',
    enterpriseName: '所属企业名',
    storeName: '分店名',
    paymentChannel: '支付渠道',
    withdrawalAmount: '提现金额',
    pay: '付款',
    notSelectRecord: '请选择要导出的记录！',
    notSelectPayRecord: '请选择要付款的记录！',
    confirmToPay: '确定要付款吗？',
    startTodealwith: '开始处理',
    intheprocessing: '处理中',
    notSelectTodealwithRecord: '请选择要处理的记录！',
    confirmTodealwith: '确定要处理吗？',
    withdrawalNum: '提现笔数',
    confirmIsPay: '确认已付款',
    withdrawalApply: '提现申请',
    withdrawalData: '提现原始数据',
    todayTip: '现在导出的数据截止到当前时间,是否继续？',
    todayTip2: '※商户可能在这之后还会进行提现申请,请注意',
    warningTitle: '<h4>提现金额预警提醒</h4><table><tr><td>企业名</td><td>店铺名</td><td>提现申请时间</td><td>提现金额</td><td>上次提现金额</td></tr>',
    logErrorTitle: '<h4>余额流水异常信息</h4><table><tr><td>企业名</td><td>店铺名</td><td>现余额</td><td>原余额</td><td>发现时间</td></tr>',
    logErrorTip: '余额流水异常信息',
    logErrorText: '※有未处理的流水异常信息, 请联系技术团队解决',
    tableHeader: {
      enterpriseName: '企业名',
      storeName: '店铺名',
      currentCashing: '现余额',
      originalCashing: '原余额',
      createDate: '发现时间'
    },
    withdrawalTotal: '提现总额',
    withdrawalBalance: '提现后剩余金额'
  },
  withdrawal: {
    withdrawalTime: '引き出し申請時間',
    withdrawalAmount: '可提现金额',
    submitWithdrawal: '发起提现',
    setAutomaticWithdrawTime: '设定自动提现时间',
    withdrawalChannel: '提现方式',
    balance: '余额',
    balanceAfterWidthdrawal: '提现后余额',
    withdrawalTitle: '输入要提现的金额',
    withdrawalAutoTitle: '设置自动提现周期',
    inputWithdrawalAmount: '提现金额',
    autoWithdrawalDays: '提现周期',
    autoWithdrawalDaysExceedMaxDays: '自动提现周期应该小于1000天！',
    balanceNotEnough: '余额不足！',
    logError: '余额流水异常, 请联系客服',
    minBalanceEnough: '请将提现金额设定在1000日元以上。',
    comment: '余额为您可以现在申请提取的金额，通过点击“发起提现”按钮,输入要提现的金额来完成。如果想定期自动发起提取所有当前余额，可以通过点击“设置自动提现周期”，输入自动提取的天数完成。',
    warn: '金额是1000日元以下的情况下，提现申请不予受理，请谅解。如果您设置了自动提现，余额小于1000日元也不会生成自动提现申请。',
    warn1: '500万日元以上的提现免跨行汇款手续费。',
    tip: '提示',
    remark: '提取余额和自动提现功能还未正式开始使用，敬请期待！',
    mincashCycle: '自动提现周期不能少于七天!',
    week: '一星期',
    twoWeek: '两星期',
    month: '一个月',
    close: '关闭自动提现',
    explain: '说明',
    weekExplain: '每周一对上一个星期的余额发起提现申请',
    twoWeekExplain: '每隔一周的周一对上两个星期的余额发起提现申请',
    monthExplain: '每月一日对上个月的余额发起提现申请',
    closeExplain: '关闭当前发起自动提现的申请',
    remind: '500万日元以上的提现免跨行汇款手续费。转账金额在500万日元以下的情况下，将会承担汇款手续费，请注意。',
    noPermissions: '您没有提现权限，请登录企业账号操作提现。'
  },
  advertising: {
    pleaseEnter: '广告名称',
    name: '广告名称',
    nameAdd: '广告名称',
    nameOf: '广告名称:',
    imageUrl: '广告图片',
    printTimes: '已打印次数',
    operate: '操作',
    distribution: '分配',
    addAdvertise: '添加广告',
    editAdvertise: '修改广告',
    startDate: '开始时间',
    expirationDate: '结束时间',
    maxPrintTimes: '最多打印次数',
    cannotDelete: '该广告已经被分配给店铺使用,请解除分配后再删除!',
    confirmToDelete: '您确认删除此条广告吗?',
    uploadPicture: '请上传广告图片!',
    status: '状态',
    normal: '正常',
    stop: '已停用',
    enterpriseOrStoreName: '企业名或店铺名',
    enterpriseOrStoreNameEnter: '',
    storeName: '分店名',
    haveNum: '已有广告数量',
    printNum: '本广告打印次数',
    addAdvertiseToStore: '添加到分店',
    AdvertiseDisabled: '此广告已失效',
    threeError: '该分店已有3个使用中的广告,操作失败',
    details: '查看',
    enable: '启用',
    disable: '停用',
    advertiseManage: '广告管理',
    advertiseAllotDetails: '广告分配详情',
    storeAdvertiseManage: '分店广告管理',
    printTimeError: '总打印次数已达到最多打印次数,请修改广告信息后再试',
    pastDueError: '此广告已过期,请修改广告信息后再试',
    allPrintTimes: '总打印次数',
    storePrintTimes: '本店打印次数',
    return: '返回上一页',
  },
  internationalExchangeRate: {
    meiyuan: '美元',
    riyuan: '日元',
    taizhu: '泰铢',
    paymentCurrency: '支付币种',
    converter: '货币兑换',
    nationalFlag: '国旗',
    abbreviation: '英文缩写',
    currency: '清算币种',
    qfpayExchangeRate: '汇率',
    createTime: '创建时间',
    operation: '操作',
    chooseAll: '全选',
    update: '修改',
    history: '历史记录',
    search: '搜索',
    name_p: '请输入',
    editAdvertise: '编辑',
    addAdvertise: '添加',
    goBack: '返回',
    updateBy: '修改人',
    qfpayRateCheckText: '小数点后最多4位，向上取整',
    qfpayRateCheckTextLength: '最多8位数字',
    qfpayRateExist: '汇率不能为空且大于0',
  },
  modal: {
    title: '提示',
    primaryButtonText: '完成',
    secondaryButtonText: '取消',
    errorTitle: '出错啦',
    confirmPrimaryButtonText: '确认',
    confirmSecondaryButtonText: '取消',
    systemErrorMessage: '系统出错啦！',
    systemErrorDashboard: '首页统计数据有异常，稍后会修复，谢谢！',
    downPage: '下一页'
  },
  validator: {
    required: '必填',
    number: '请输入正确的密码',
    percentageNumber: '请输入正确的百分数(最多两位小数)',
    email: '请输入正确的邮箱',
    len: '你输入的字符太长了',
    len1: '密码必须包含字母和数字,字符长度为6-8位',
    integer: '请输入整数',
    samePwd: '请确保两次密码一致',
    existEmail: '邮箱已被注册了，请换一个',
    unExistUser: '用户不存在或密码错误',
    existWorkNumber: '该员工号已经被使用，请重新输入!',
    halfChar: '请输入半角假名、半角空格、半角括号。',
    receivable: {
      refoundAmountTooHight: '退款金额不能高于可退款金额',
      refoundAmountGtZero: '退款金额必须大于0',
      refoundAmountInvalidDecimal: '退款金额保留两位小数',
      max_l_8: '最多不超过8位',
      max_l_100: '最多不超过100位',
    },
    positiveNumber: '请输入正数',
    noBindingChannels: '没有绑定渠道',
    existImageName: '广告名称已经被使用过了,请重新输入',
    maxLength: '最多可输入十位数字。',
    existLoginId: '该登录ID已经被使用，请重新输入!',
    maxUploadSize: '每张图片大小不能超过1M',
    invalidUploadExtensions: '上传文件格式应为合法的图片格式',
    invalidUploadExtensionsAndPdf: '上传文件格式应为合法的图片格式或者pdf格式',
    exchangeRate: '请输入正确的汇率, 最多8位小数'
  },
  enterprise: {
    null: '无',
    tip: '如需修改公司信息，请联系客服。客服邮箱：info@qfpay.info',
    applyForChannel: '渠道申请',
    registeredChannelInfo: '已审核渠道',
    applyChannelInfo: '待审核渠道',
    toApplyNewChannel: '申请新渠道',
    checking: '当座',
    savinngs: '普通',
    waitingReview: '等待审核...',
    parentheses: ')',
    refused: '已拒绝',
    editTo: '(修改为：',
    newUpload: '最新上传',
    not: '暂无',
    lease: '租借',
    buy: '购买',
    notBuy: '不购买',
    applyInAttachedStore: '提示: 请在附属店铺内申请新渠道',
    bankTypeEnum: {
      BANK: '银行',
      FINANCIAL_COOPERATIVE: '金融合作社',
      CREDIT_COOPERATIVE: '信用合作社',
      AGRICULTURAL_COOPERATIVE: '农业合作社'
    },
    branchTypeEnum: {
      BRANCH_STORE: '分店',
      HEAD_OFFICE: '总店',
      FIELD_OFFICE: '外地办事处',
      BRANCH_OFFICE: '分所'
    },
  },
  channel: {
    storeId: '分店ID',
    id: '渠道支付ID',
    qf: '钱方',
    channel: '渠道',
    type: '支付渠道',
    currency: '币种',
    rate: '费率',
    rateUnit: '以百分之一为单位',
    withdrawCycle: '提现周期',
    withdrawCycleUnit: '以天为单位',
    withdrawAmount: '单次提现额度',
    withdrawAmountUnit: '以分为单位',
    applySuccess: '您的申请已提交，请等待审核',
    comment: '备注',
    applicationDate: '申请时间',
    rejectReason: '拒绝原因',
    typeEnum: {
      ALIPAY: '支付宝',
      WECHAT: '微信',
    },
    poundage: '费率',
    language: 'zh-CN',
    merchcomment: '钱方渠道支持自动开户，其他渠道需填写支付渠道ID',
    whetherToUse: '是否禁用',
    hasBeendisabled: '已禁用',
    whetherToPass: '是否通过',
    ok: '通过',
    refuse: '拒绝',
    applyFailure: '申请失败，请稍后重试',
    whetherSettlement: '是否结算',
    settlement: '是',
    NoSettlement: '否',
    deviceNum: '凭据码',
    iSORate: '一级代理费率',
    iSVOneRate: '二级代理费率',
    iSVTwoRate: '三级代理费率',
    agentRate: '营业员费率',
    channelRate: '渠道方费率',
    qfpayRate: 'QFPay所得费率'
  },
  approvedReview: {
    pendingReviewChannel: '待审核渠道',
    registeredChannel: '已有渠道',
    reviewDate: '审核时间',
    searchOther: '名称',
    feeRate: '费率',
  },
  tips: {
    confirmToDelete: '确定要删除吗',
    needToReWrite: '已拒绝，请联系该用户重新填写信息',
    noPremission: '该账号没有权限',
    reviewInEmail: '请登录邮箱，确认邮件后修改密码',
    modifySuccess: '密码修改成功',
    channelExist: '该用户已有该渠道，不能重复添加',
    confirmPassed: '确定审核通过吗？',
    rejectStore: '拒绝商户申请',
    actionSuccess: '操作成功',
    storeAccount: '请使用店主账号登录操作',
    cannotDelete: '该设备已与用户绑定,请解绑后删除',
    actionFail: '操作失败',
    ErrTime: '结束时间不能早于开始时间！',
    selectChannel: '请选择交易渠道',
    maxUpload: '最多能添加九张图片!',
    maxRate: '折扣率不能超过100%!',
    selectDevice: '请选择付款设备号!',
    selectCampaigns: '请选择活动',
    lackJurisdiction: '没有使用提现功能的权限。',
    noMchId: '该商户没有渠道支付Id,请填写后重新申请',
    openAccountFail: '数据保存成功，调用钱方接口失败，错误代码和信息为：${errmsg}',
    noEnterMchid: '数据保存成功，调用钱方接口失败',
    selectOrderState: '请选择订单状态',
    maxAmount: '最多可添加四个抄送邮箱!',
    auditFail: '店铺审核失败，请稍后重试！',
    appliFeerateRule: '费率需满足如下条件 :店铺费率>=直属代理费率,下级费率>=上级费率,渠道费率小于代理费率。',
    curFeerate: '当前费率如下：店铺费率：${feeRate},渠道方费率：${channelRate}',
    iSORateTip: ',一级代理费率：${iSORate}',
    iSVOneRateTip: ',二级代理费率：${iSVOneRate}',
    iSVTwoRateTip: ',三级代理费率：${iSVTwoRate}',
    agentRateTip: ',营业员费率：${agentRate}'
  },
  message: {
    readed: '已读',
    unread: '未读',
    readAll: '查看全部',
    noMessage: '暂无通知',
    noNewMessage: '暂时没有消息'
  },
  pagination: {
    prev: '前一页',
    next: '后一页',
    each: '每页个数',
    total: '总页数',
  },
  currency: {
    c: '人民币',
    e: '美元',
    j: '日本円',
  },
  bank: {
    ALIPAY: '支付宝',
    WECHAT: '微信',
    UNKNOWN: '未知',
    ZERO: '零',
  },
  settlement: {
    amountInThisMonth: '本月该清算金额',
    pay: '支付',
    status: '清算状态',
    status_unsettlement: '未清算',
    status_settled: '已清算',
    settlementTime: '清算时间',
    payeeName: '收款人账户名称',
    payeeAccount: '收款人账户',
    payeeBankName: '收款人银行名称',
    bankBranch: '银行支行',
    channel: '渠道',
    tradeAmount: '交易金额',
    feeRate: '费率',
    channelFee: '渠道费用',
    systemFee: '系统使用费',
    serviceFee: '服务费',
    otherFee: '其他费用',
    finalFee: '实际清算金额',
    storeName: '分店名',
    doSettle: '清算',
    enterpriseName: '所属企业名',
    userType: '用户类型',
    settlementRange: '清算周期',
    settlementFee: '预计清算金额',
    settlementInfo: '清算信息',
    settlementSuccess: '清算成功',
    doSettleTime: '操作时间',
    operator: '操作人',
    exportUnsettlement: '导出自动汇款文件',
    transferDate: '汇款时间',
    settlementchannel: '清算渠道',
    transNumber: '交易笔数',
    amount: '交易金额',
    actualarrivalamount: '实际到账金额',
    export: '详情导出',
    settlementDetail: '清算详细表',
  },
  QFPayMore: {
    develop: 'QFPay+暂未开通，正在努力开发中…',
    wish: '敬请期待',
  },
  updateSettings: {
    setting: '设置发送周期',
    enterpriseName: '企业名称',
    storeName: '店铺名',
    cycle: '发送周期',
    mail: '邮箱',
    copyMail: '抄送邮箱',
    all: '全部',
    time: '每次',
    day: '每天',
    week: '每周',
    month: '每月',
    close: '关闭',
    save: '保存',
    condirm: '确认修改邮件设置?',
    tips1: '选择【每笔】时，每笔交易和退款成功后将会以邮件的形式提醒；',
    tips2: '选择【每天】时，从设定之日起，每天发送前一日的交易流水；',
    tips3: '选择【每周】时，从下周开始，每周一发送上周的交易流水；',
    tips4: '选择【每月】时，下一个月第一天发送上一整月的交易流水；',
    tips5: '选择【关闭】时，从设定之日起，不再不发送交易流水邮件；温馨提示内容',
    tips6: '',
    enterprise: '企业',
    store: '店铺',
    merchantName: '商户名称',
    mercahntMail: '商户邮箱',
    operate: '操作',
    exchangeRate: '汇率搜索',
    keyWord: '检索关键字',
    baseInfo: '店铺基本信息',
    signStatus: '签约状态'
  },
  disposeBarCode: {
    title: '标题',
    createDate: '创建时间',
    dateRange: '创建时间',
    amount: '金额',
    comment: '备注',
    operate: '操作',
    edit: '编辑',
    detail: '详情',
    delete: '删除',
    create: '新增收款码',
    title_P: '请输入标题',
    amount_P: '请输入金额',
    comment_P: '请输入备注',
    barcodeTip: '收款二维码为固定金额静态二维码，可以下载打印后置于贵店中。顾客自己主动扫码进行付款时使用。',
    deleteTip: '您确认删除该二维码吗?'
  },
  acdExchangeRate: {
    updateTime: '修改时间',
    virtualCurrency: '虚拟币种',
    before: '修改前汇率',
    after: '修改后汇率',
    updateBy: '修改者',
    edit: '修改汇率',
    nowRate: 'ACD当前汇率',
    newRate: 'ACD新汇率',
  },
  savvyPoint: {
    enterpriseName: '企业名称',
    storeName: '店铺名称',
    address: '地址',
    phoneNumber: '电话',
    mail: '邮箱',
    openPoint: '开通积分',
    openTip: '正在开通积分服务，请确认此操作',
    intoSavvy: '登录Savvy系统管理积分',
    billMonth: '账单年月',
    billAmount: '账单金额',
    billNum: '订单号',
    paymentDeadline: '最晚付款日',
    statementDate: '出账日期',
    payStatus: '支付状态',
    status: '状态',
    hasOpen: '已开通',
    notPay: '未支付',
    hasPay: '已支付',
    overdue: '已逾期',
    urging: '已催收',
    toOpen: '去开通',
    waitPayBills: 'Savvy账单',
    storeList: '店铺列表',
    payDate: '支付日期',
    markAsPaid: '标记已支付',
    payTip: '确认标记该笔账单已经支付完成吗？',
    inputStoreName: '请输入店铺名称',
    inputEnterName: '请输入企业名称',
    ensureOpen: '确认跳转Savvy系统进行操作？',
    openFailure: '开通失败，请联系客服,电话：0120-501-080',
    reason: '原因：'
  },
  errorCode: {
    186688: '连接redis失败',
    186678: '主键重复',
    186679: '图片验证码存放session失败',
    186680: '创建企业失败',
    186681: '无法连接阿里云',
    186682: '创建商户失败',
    186683: '无法修改用户状态',
    186684: '该用户已存在',
    186685: '无法绑定用户',
    186686: '更新用户失败',
    186687: '更新用户状态失败',
    186689: '删除企业和用户临时绑定关系失败',
    186690: '企业用户关系绑定失败',
    186691: '更新用户权限失败',
    186692: '用户初始消息关联失败',
    186693: '删除商户和用户临时绑定关系失败',
    186694: '商户和用户关系绑定失败',
    186695: '更新渠道信息失败',
    186696: '连接邮件服务器失败',
    186697: '开了小差,请重试',
    186698: '更新企业信息失败',
    186699: '更新优惠活动失败',
    186676: '退款失败',
    186674: '无法连接钱方服务器',
    186675: '订单关闭,退款失败',
    186601: '发生未知错误',
    186602: '数据插入出错',
    186503: '商户未绑定主人',
    186504: '商户主人数量不正确',
    186505: '创建优惠活动失败',
    186506: '优惠码数量太多',
    186507: '用户绑定企业不唯一',
    186508: '创建企业失败',
    186509: '无法向企业插入空值',
    186510: '无法为企业创建商户',
    186511: '无法绑定商户给用户',
    186512: '插入权限失败',
    186513: '数据更新出错',
    186514: '约束有误',
    186515: '结果不匹配',
    186516: '更新失败',
    186599: '查不到相关信息',
    186570: '文件已损坏',
    186571: '验证码无效',
    186572: '不在范围0-20之间',
    186573: '查询不能为空',
    186574: '员工编码最多8位',
    186575: '错误商户信息',
    186576: '用户不存在',
    186577: '待审核用户信息有误',
    186578: '邮箱地址有误',
    186579: 'token失效或不存在',
    186580: '验证码优惠次数必须大于0',
    186581: '验证码尺寸有误',
    186582: '错误用户信息',
    186583: '订单状态不能为空',
    186584: '请求参数有误',
    186470: '优惠活动不存在',
    186471: '渠道不存在',
    186472: '用户不存在',
    186473: '无效密码',
    186474: '你未绑定支付账户',
    186560: '生成交易号失败',
    183675: '当日未清算余额不足,退款失败',
    181674: '商户配置错误, 请联系管理员',
    181121: '清算数据插入失败',
    186000: '上传文件不能超过10MB!',
    187000: '插入提现日期状态表失败',
    187001: '更新提现日期状态表失败',
    187002: '更新提现记录表失败',
    187003: '传入状态码错误',
    187100: '订单日期超过退款期限',
    187101: '操作失败，该笔交易已经退款。'
  }
};
