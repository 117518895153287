import {observable, computed, action, extendObservable, reaction} from 'mobx';
import mixin from './mixin';
import Observable from '../observable';
import Numbers from './Numbers';
import Arrays from './Arrays';

@mixin(Observable)
class KeyedList {
  @observable.shallow items = [];
  @observable map = new Map();
  @observable indexMap = new Map();

  generation = 0;
  indexGeneration = 0;
  source;
  _filters;
  _sorters;
  isCollection = true;
  autoSort = false
  updating = 0;

  constructor(data, options = {}) {
    if(data) {
      this.add(data);
    }
    if(options.source) {
      this.source = options.source;
    }
    this.initOptions = options;
  }

  getKey(item) {
    if (item.isModel) {
      return item.getId();
    }
    return item.id;
  }

  splice(index, toRemove/* number | object[] */, toAdd/* object[] */) {
    let len = this.getCount();
    if(arguments[1] === undefined) {
      toRemove = len;
    }
    if(typeof toRemove !== 'number' && !Array.isArray(toRemove)) {
      toRemove = [toRemove];
    }
    let length = this.getCount();
    let removeItems = (toRemove instanceof Array) ? toRemove : null;
    let isRemoveCount = !removeItems;
    let [begin, end] = Numbers.clipIndices(length, [index, isRemoveCount ? toRemove : 0], Numbers.Clip.COUNT);
    let removeCount = end - begin;
    let removes = removeCount ? [begin] : null;
    let newItemsMap;
    let duplicates = {};
    let newItems;
    if(toAdd && !Array.isArray(toAdd)) {
      newItems = [toAdd];
    }else if(toAdd) {
      newItems = toAdd;
    }

    let newCount = newItems ? newItems.length : 0;
    let newKeys = null;
    let keys;
    let itemIndex;
    let adds;
    let insertAt = begin;
    let key;
    let removeMap;
    let chunk;
    let chunkItems;
    let chunks;
    let addItems;
    let sorters;
    if(newCount) {
      addItems = newItems;
      newItemsMap = {};
      newKeys = [];
      if(this.autoSort) {
        // We'll need the sorters later as well
        sorters = this.getSorters();

        if (newCount > 1) {
          // if(!addItems.$cloned) {
          newItems = addItems = addItems.slice(0);
          // }
          this.sortData(addItems);
        }
      }
      newItems.forEach((item, i) => {
        key = this.getKey(item);
        let k = newItemsMap[key];
        if(k !== undefined) {
          duplicates[k] = 1;
        }else{
          // 如果新增的item在原数据中有重复，而且又不在将要删除的范围内，则需要手动添加进removes数组
          itemIndex = this.indexMap.get(key);
          if(itemIndex < begin || end <= itemIndex) {
            (removes || (removes = [])).push(itemIndex);
          }
        }
        newItemsMap[key] = i;
        newKeys.push(key);
      });
      if(duplicates) {
        addItems = [];
        keys = newKeys;
        newKeys = [];
        for(let i = 0; i < newCount; i++) {
          if(!duplicates[i]) {
            addItems.push(toAdd[i]);
            newKeys.push(keys[i]);
          }
        }
        newCount = addItems.length;
      }

      adds = {
        items: addItems,
        keys: newKeys
      };
    }
    // If we are given a set of items to remove, map them to their indices.
    if(removeItems) {
      for (let i = removeItems.length; i-- > 0;) {
        key = this.getKey(removeItems[i]);
        itemIndex = this.indexMap.get(key);
        if(itemIndex !== undefined) {
          // ignore items we don't have (probably due to filtering)
          (removes || (removes = [])).push(itemIndex);
        }
      }
      // removeItems.forEach((item, i) => {
      //   key = this.getKey(item);
      //   itemIndex = this.indexMap.get(key);
      //   if(itemIndex !== undefined) {
      //     // ignore items we don't have (probably due to filtering)
      //     (removes || (removes = [])).push(itemIndex);
      //   }
      // });
    }
    if(!removes && !newItems) {
      return this;
    }
    this.beginUpdate();
    if(removes) {
      chunk = null;
      chunks = [];
      removeMap = {};
      if(removes.length > 1) {
        removes.sort(function(a, b) {
          return a - b;
        });
      }
      for(let i = 0, n = removes.length; i < n; ++i) {
        itemIndex = removes[i];
        let item = this.items[itemIndex];
        key = this.getKey(item);
        if(!this.map.has(key)) {
          continue;
        }
        this.map.delete(key);
        // Consider chunk = { at: 1, items: [ item1, item2 ] }
        //
        //      +---+---+---+---+---+---+
        //      |   | x | x |   |   |   |
        //      +---+---+---+---+---+---+
        //        0   1   2   3   4   5
        //
        // If we are adding an itemIndex > 3 we need a new chunk.
        //
        if (!chunk || itemIndex > (chunk.at + chunkItems.length)) {
          chunks.push(chunk = {
            at: itemIndex,
            items: (chunkItems = []),
            keys: (keys = []),
            map: removeMap,
            next: chunk,
            replacement: adds
          });

          // Point "replaced" at the last chunk
          if(adds) {
            adds.replaced = chunk;
          }
        }
        removeMap[key] = item;
        chunkItems.push(removeMap[key]);
        keys.push(key);
        if(itemIndex < insertAt - 1) {
          // 如果要插入的位置之前有需要被删掉的数据，则插入索引往前移一位
          --insertAt;
        }
        if (removeCount > 1 && itemIndex === begin) {
          // To account for the given range to remove we started by putting the
          // index of the first such item ("begin") in the array. When we find
          // it in this loop we have to process all of the items and add them
          // to the current chunk. The following trick allows us to repeat the
          // loop for each item in the removeCount.
          //
          --removeCount; // countdown...
          removes[i--] = ++begin; // backup and increment begin
        }
      }
      if(adds) {
        adds.at = insertAt;
      }
      // Loop over the chunks in reverse so as to not invalidate index values on
      // earlier chunks.

      for (let k = chunks.length; k-- > 0;) {
        chunk = chunks[k];
        let i = chunk.at;
        let n = chunk.items.length;

        if (i + n < length) {
          // If we are removing the tail of the collection, we can keep the
          // indices for the rest of the things... otherwise we need to zap it
          // and fix up later.
          this.indexMap.clear();
        }

        length -= n;

        // We can use splice directly. The IE8 bug which Ext.Array works around
        // only affects *insertion*
        // http://social.msdn.microsoft.com/Forums/en-US/iewebdevelopment/thread/6e946d03-e09f-4b22-a4dd-cd5e276bf05a/
        // Ext.Array.erase(items, i, n);
        this.items.splice(i, n);

        if (this.indexMap.size > 0) {
          keys = chunk.keys;
          for (i = 0; i < n; ++i) {
            this.indexMap.delete(keys[i]);
          }
        }

        ++this.generation;
        // me.notify('remove', [ chunk ]);
      }

    }
    if(adds) {
      if(this.autoSort && newCount > 1 && length) {
        this.spliceMerge(addItems, newKeys);
      }else{
        if (this.autoSort) {
          if (newCount > 1) {
              // We have multiple addItems but we are empty, so just add at 0
            insertAt = 0;
            this.indexMap.clear();
          } else {
              // If we are adding one item we can position it properly now and
              // avoid a full sort.
            insertAt = sorters.findInsertionIndex(adds.items[0], this.items, this.getSortFn(), index);
          }
        }
        // if(insertAt === length) {
        //   end = insertAt;
        //   // Inser items backwards. This way, when the first item is pushed the
        //   // array is sized to as large as we're going to need it to be.
        //   for (let i = addItems.length - 1; i >= 0; --i) {
        //     this.items[end + i] = addItems[i];
        //   }
        //   // The indices may have been regenerated, so we need to check if they have been
        //   // and update them
        //   if (this.indexMap.size > 0) {
        //     for (let i = 0; i < newCount; ++i) {
        //       this.indexMap.set(newKeys[i], insertAt + i);
        //     }
        //   }
        // }else{
        //   this.indexMap.clear();
        //   let len = this.items.length;
        //   if(insertAt === 0) {
        //     this.items.unshift(...addItems);
        //   }else if(insertAt < len) {
        //     this.items.splice(insertAt, 0, ...addItems);
        //   }else{
        //     this.items.push(...addItems);
        //     if (this.indexMap.size > 0) {
        //       for (let i = 0; i < newCount; ++i) {
        //         this.indexMap.set(newKeys[i], insertAt + i);
        //       }
        //     }
        //   }
        // }
        this.indexMap.clear();
          let len = this.items.length;
          if(insertAt === 0) {
            this.items.unshift(...addItems);
          }else if(insertAt < len) {
            this.items.splice(insertAt, 0, ...addItems);
          }else{
            this.items.push(...addItems);
            if (this.indexMap.size > 0) {
              for (let i = 0; i < newCount; ++i) {
                this.indexMap.set(newKeys[i], insertAt + i);
              }
            }
          }
        for (let i = 0; i < newCount; ++i) {
          this.map.set(newKeys[i], addItems[i]);
        }
        ++this.generation;
      }
    }
    if(this.indexMap.size === 0) {
      this.rebuildIndexMap();
    }
    this.endUpdate();
  }

  @action
  spliceMerge(newItems, newKeys) {
    var me = this,
      map = me.map,
      newLength = newItems.length,
      oldIndex = 0,
      oldItems = me.items,
      oldLength = oldItems.length,
      adds = [],
      count = 0,
      items = [],
      sortFn = me.getSortFn(), // account for grouper and sorter(s)
      addItems, end, i, newItem, oldItem, newIndex;

      me.items = items;

    //
    //  oldItems
    //      +----+----+----+----+
    //      | 15 | 25 | 35 | 45 |
    //      +----+----+----+----+
    //        0    1    2    3
    //
    //  newItems
    //      +----+----+----+----+----+----+
    //      | 10 | 11 | 20 | 21 | 50 | 51 |
    //      +----+----+----+----+----+----+
    //        0    1    2    3    4    5
    //

    for (newIndex = 0; newIndex < newLength; newIndex = end) {
      newItem = newItems[newIndex];

      // Flush out any oldItems that are <= newItem
      for ( ; oldIndex < oldLength; ++oldIndex) {
        // Consider above arrays...
        //  at newIndex == 0 this loop sets oldItem but breaks immediately
        //  at newIndex == 2 this loop pushes 15 and breaks w/oldIndex=1
        //  at newIndex == 4 this loop pushes 25, 35 and 45 and breaks w/oldIndex=4
        if (sortFn(newItem, oldItem = oldItems[oldIndex]) < 0) {
          break;
        }
        items.push(oldItem);
      }

      if (oldIndex === oldLength) {
        // Consider above arrays...
        //  at newIndex == 0 we won't come in here (oldIndex == 0)
        //  at newIndex == 2 we won't come in here (oldIndex == 1)
        //  at newIndex == 4 we come here (oldIndex == 4), push 50 & 51 and break
        adds[count++] = {
          at: items.length,
          itemAt: items[items.length - 1],
          items: (addItems = [])
        };
        if (count > 1) {
          adds[count - 2].next = adds[count - 1];
        }

        for (; newIndex < newLength; ++newIndex) {
          addItems.push(newItem = newItems[newIndex]);
          items.push(newItem);
        }
        break;
      }

      // else oldItem is the item from oldItems that is > newItem

      // Consider above arrays...
      //  at newIndex == 0 we will push 10
      //  at newIndex == 2 we will push 20
      adds[count++] = {
        at: items.length,
        itemAt: items[items.length - 1],
        items: (addItems = [ newItem ])
      };
      if (count > 1) {
        adds[count - 2].next = adds[count - 1];
      }

      items.push(newItem);

      for (end = newIndex + 1; end < newLength; ++end) {
        // Consider above arrays...
        //  at newIndex == 0 this loop pushes 11 then breaks w/end == 2
        //  at newIndex == 2 this loop pushes 21 the breaks w/end == 4
        if (sortFn(newItem = newItems[end], oldItem) >= 0) {
          break;
        }
        items.push(newItem);
        addItems.push(newItem);
      }

      // if oldItems had 55 as its final element, the above loop would have pushed
      // all of newItems so the outer for loop would then fall out
    }

    for (; oldIndex < oldLength; ++oldIndex) {

        // In the above example, we won't come in here, but if you imagine a 55 in
        // oldItems we would have oldIndex == 4 and oldLength == 5
      items.push(oldItems[oldIndex]);
    }

    for (i = 0; i < newLength; ++i) {
      map.set(newKeys[i], newItems[i]);
    }

    // me.length = items.length;
    ++me.generation;
    this.items.replace(items);
    me.indexMap.clear();
  }

  @action
  insert(index, items) {
    if(!Array.isArray(items)) {
      items = [items];
    }
    let ret = items;
    if (items.length) {
      this.splice(index, 0, items);
      ret = (items.length === 1) ? items[0] : items;
    }

    return ret;
  }

  @action
  add(item) {
    let ret;
    if(!Array.isArray(item)) {
      item = [item];
    }
    if (item.length) {
      this.splice(this.getCount(), 0, item);
      ret = (item.length === 1) ? item[0] : item;
    }
    return ret;
  }

  get(key) {
    return this.map.get(key);
  }

  at(index) {
    return this.items[index];
  }

  getRange(begin, end) {
    if(arguments.length === 0) {
      return this.items;
    }
    let range = Numbers.clipIndices(this.getCount(), [begin, end]);
    return this.items.slice(...range);
  }

  remove(item) {
    this.splice(0, item);
  }

  removeAt(index, count = 1) {
    let length = this.getCount();
    let range = Numbers.clipIndices(length, [index, (count === undefined) ? 1 : count], Numbers.Clip.COUNT);
    let n = range[0];
    let removeCount = range[1] - n;
    let item = (removeCount === 1) && this.at(n);
    let removed;
    this.splice(n, removeCount);

    removed = this.getCount().length - length;

    return (item && removed) ? item : removed;
  }

  removeByKey(key) {
    var item = this.get(key);

    if (!item || !this.remove(item)) {
      return false;
    }

    return item;
  }

  removeAll() {
    let len = this.items.length;
    if(this.generation && len) {
      this.splice(0, len);
    }
    return this;
  }

  @action
  put(key, item) {
    if (!this.map.has(key)) return;
    var index = this.indexOf(item);
    this.items[index] = item;
    this.map.set(key, item);
  }

  isExist(o) {
    let itemKey = this.getKey(o);
    return this.map.has(itemKey) && this.get(itemKey) !== undefined;
  }

  indexOf(item) {
    if (!item) {
      return -1;
    }
    var key = this.getKey(item);
    return this.indexOfKey(key);
  }

  indexOfKey(key) {
    if(!this.indexMap.has(key)) {
      return -1;
    }
    if (this.indexGeneration !== this.generation) {
      this.rebuildIndexMap();
    }
    return this.indexMap.get(key);
  }

  rebuildIndexMap() {
    this.indexMap.clear();
    let len = this.items.length;
    for(let i = 0; i < len; i++) {
      let key = this.getKey(this.items[i]);
      this.indexMap.set(key, i);
    }
    this.indexGeneration = this.generation;
  }

  // 可用于批处理，最终触发事件
  update(fn, scope) {
    this.beginUpdate();
    try {
      return fn.call(scope || this, this);
    }catch (e) {
      console.error(e);
      throw e;
    }finally {
      this.endUpdate();
    }
  }

  beginUpdate() {
    if(!this.updating++) {
      this.fireEvent('beginupdate');
    }
  }

  endUpdate() {
    if (!--this.updating) {
      this.fireEvent('update');
    }
  }

  @action
  replace(items) {
    let ret;
    if(!Array.isArray(items)) {
      items = [items];
    }
    if(items.length) {
      this.splice(0, this.getCount(), items);
      ret = (items.length === 1) ? items[0] : items;
    }else{
      this.removeAll();
    }
    return ret;
  }

  clone() {
    let items = this.items;
    let copy = new this.constructor(items, this.initOptions);
    return copy;
  }

  getCount() {
    return this.items.length;
  }

  sum(field) {
    let len = this.getCount();
    let items = this.items;
    let value = 0;
    for(let i = 0; i < len; i ++) {
      value += items[i][field];
    }
    return value;
  }

  max(field) {
    let value;
    let len = this.getCount();
    let items = this.items;
    if(len > 0) {
      value = items[0][field];
    }
    for(let i = 1; i < len; i ++) {
      if(items[i][field] > value) {
        value = items[i][field];
      }
    }
    return value;
  }

  min(field) {
    let value;
    let len = this.getCount();
    let items = this.items;
    if(len > 0) {
      value = items[0][field];
    }
    for(let i = 1; i < len; i ++) {
      if(items[i][field] < value) {
        value = items[i][field];
      }
    }
    return value;
  }

  avg(field) {
    let sum = this.sum(field);
    let count = this.getCount();
    let val = sum / count;
    if(isNaN(val)) {
      return 0;
    }
    return val;
  }

  // @action
  // filter(fn) {
  //   let items = [];
  //   let map = observable.map();
  //   let indexMap = observable.map();

  //   if(!this.source) {
  //     this.source = new this.constructor(this.items.slice());
  //   }
  //   items = this.source.items.filter(fn);
  //   items.forEach((item, i) => {
  //     let key = this.getKey(item);
  //     map.set(key, item);
  //     indexMap.set(key, i);
  //   });
  //   this.items.replace(items);
  //   this.map.replace(map);
  //   this.indexMap.replace(indexMap);
  //   ++this.generation;
  //   this.fireEvent('update');
  //   this.fireEvent('filter');
  // }

  @action
  sort(field, direction) {
    let items = _.orderBy(this.items, field, direction)
    this.items.replace(items);
    this.rebuildIndexMap();
    this.fireEvent('update');
    this.fireEvent('sort');
  }

  dispose() {
    this.items.clear();
    this.map.clear();
    this.indexMap.clear();
    if(this.source) {
      this.source = null;
    }
  }

  findInsertionIndex(item, items = this.items, comparatorFn = this.getSortFn(), index) {
    var len = items.length,
      beforeCheck, afterCheck;

    comparatorFn = comparatorFn || Arrays.lexicalCompare;

    if (index < len) {
      beforeCheck = index > 0 ? comparatorFn(items[index - 1], item) : 0;
      afterCheck = index < len - 1 ? comparatorFn(item, items[index]) : 0;
      if (beforeCheck < 1 && afterCheck < 1) {
        return index;
      }
    }

    return Arrays.binarySearch(items, item, comparatorFn);
  }
}
export default KeyedList;
