import { observable, action, observe, toJS } from 'mobx';
import { PageStore, defaultPageSize, } from './PageStore';
import KeyedList from '../util/KeyedList';
import ModelManager from './ModelManager';
import invariant from 'invariant';
import StoreManager from './StoreManager';
import Page from '../util/Page';

/**
 * 数据store，可以理解为一个model实例的集合，假如你有个model是Order，那这里面存储了Order集合。
 * 该类所有方法都不会和服务器做任何交互，例如筛选和排序，如果你需要服务器做筛选或排序，那应该自己实现。
 * 
 */
class Store {
  constructor({ data, count, model, storeId }) {
    this.model = ModelManager.getModel(model);
    if (data) {
      this.data.add(data, count);
    }
    if (storeId) {
      this.storeId = storeId;
    }
  }
  @observable data = new KeyedList()
  @observable pageStore = new PageStore()
  @observable removed;
  @observable filtered = false
  @observable sorted = false
  @observable source
  @observable currentSort = {
    col: null,
    direction: null
  }
  maxCid = 1
  disposed = false

  /**
   * 返回集合的长度
   */
  getCount() {
    return this.data.getCount();
  }

  /**
   * 返回集合数据，是一个KeyedList
   */
  getData() {
    return this.data.items;
  }

  /**
   * 返回分页信息
   */
  getPage() {
    return this.pageStore;
  }

  /**
   * store当前是否处于筛选状态
   */
  isFiltered() {
    return this.filtered;
  }

  /**
   * store当前是否属与排序状态
   */
  isSorted() {
    return this.sorted;
  }

  getSource() {
    if(!this.source) {
      this.source = new KeyedList(this.getData().slice());
    }
    return this.source;
  }

  /**
   * 添加数据
   * @param {object | object[]} value 
   * @param {number} remoteTotal 服务器中的数据总数
   */
  @action
  add(value, remoteTotal) {
    let records = this.transformData(value);
    this.data.add(records);
    this.getSource().add(records);
    this.updatePage(remoteTotal);
  }

  /**
   * 插入数据
   * @param {number} index 插入索引
   * @param {object | object[]} value 
   * @param {number} remoteTotal 服务器中的数据总数
   */
  @action
  insert(index, value, remoteTotal) {
    let records = this.transformData(value);
    this.data.insert(index, records);
    this.getSource().insert(index, records);
    this.updatePage(remoteTotal);
  }

  /**
   * 整体替换数据
   * @param {object | object[]} value 
   * @param {number} remoteTotal 服务器中的数据总数
   */
  @action
  replace(value, remoteTotal) {
    let records = this.transformData(value);
    this.data.replace(records);
    this.getSource().replace(records);
    this.updatePage(remoteTotal);
  }

  replaceAt() {}

  /**
   * 根据索引查找
   * @param {number} index 
   */
  at(index) {
    return this.data.at(index);
  }

  /**
   * 根据key查找
   * @param {string} key 默认是id的值
   */
  get(key) {
    return this.getSource().get(key);
  }

  /**
   * 根据key替换对应的对象
   * @param {object} v 
   */
  put(k, v) {
    let [record] = this.transformData(v);
    this.data.put(k, record);
    this.getSource().put(k, record);
  }  

  indexOf(item) {
    return this.data.indexOf(item);
  }

  /**
   * 删除数据
   * @param {object | object[]} records 被删除的对象
   */
  @action
  remove(records, hardDestroy) {
    if(!Array.isArray(records)) {
      records = [records];
    }
    if(!this.removed) {
      this.removed = new KeyedList();
    }
    let removed = this.removed;
    records.forEach(record => {
      this.data.remove(record);
      this.getSource().remove(record);
      if(!record.phantom && hardDestroy === false) {
        removed.add(record);
      }
    });
    this.updatePage();
    return removed;
  }

  /**
   * 根据索引删除
   * @param {number | number[]} indices 
   */
  @action
  removeAt(indices) {
    if(!Array.isArray(indices)) {
      indices = [indices];
    }
    if(!this.removed) {
      this.removed = new KeyedList();
    }
    let removed = this.removed;
    indices.forEach(index => {
      let record = this.at(index);
      if(!record.phantom) {
        removed.add(record);
      }
      this.data.removeAt(index);
      this.getSource().removeAt(index);
    });
    this.removed = removed;
    this.updatePage();
    return indices;
  }

  /**
   * 筛选，该方法不会返回新的store，你可以直接通过getData()方法拿到筛选后的记录
   * @param {(item, index) => boolean} fn 见es6中的filter方法
   * @param pageOption {pageNumber, pageSize}
   */
  @action
  filter(fn, pageOption) {
    let items = [];
    items = this.getSource().items.filter(fn);
    this.getPage().setTotal(items.length);
    if (pageOption) {
      this.getPage().setPageSize(pageOption.pageSize);
      const page = new Page(this.getPage().total, pageOption.pageNumber);
      page.setPageSize(pageOption.pageSize);
      // if (!page.isValid()) {
      //   return;
      // }
      items = items.slice(page.getStartPoz(), page.getEndPoz());
    }
    this.data.replace(items);
    // this.updatePage();
    this.filtered = true;
  }

  /**
   * 排序，具体见lodash.orderBy
   * @param {string} field 列名
   * @param {'asc' | 'desc'} direction 方向
   */
  @action
  sort(field, direction) {
    if (arguments.length === 0) {
      field = this.currentSort.col;
      direction = this.currentSort.direction;
    }
    if (!field || !direction) {
      return;
    }
    this.getSource().sort(field, direction);
    this.toPage(this.getPage().current, this.getPage().pageSize);
    this.currentSort.col = field;
    this.currentSort.direction = direction;
    this.sorted = true;
  }

  /**
   * 重置store，该方法会重置筛选和排序状态
   */
  @action
  reset() {
    if (this.filtered) {
      this.data = this.data.source;
    }
    if (this.sorted) {
      let keyProperty = this.data.at(0).getIdProperty();
      this.data.sort(keyProperty, 'asc');
    }
    this.filtered = false;
    this.sorted = false;
    
    this.updatePage();
    this.getPage().setCurrent(1);
    this.getPage().setPageSize(defaultPageSize);
  }

  /**
   * 获取被删除且未同步的数据集合，必须是服务器已有的数据
   */
  getRemovedRecords() {
    return this.removed.items.filter(o => !o.isSync);
  }

  /**
   * 获取所有新加但未同步的数据
   */
  getNewRecords() {
    return this.data.items.filter(o => o.phantom);
  }

  /**
   * 获取所有修改但未同步的数据
   */
  getModifiedRecords() {
    return this.data.items.filter(o => !o.phantom && o.dirty);
  }

  /**
   * 
   * @param {number} start 起始索引
   * @param {number} end 结束索引（不包含end）
   * 
   */
  getRange(start, end) {
    return this.data.getRange(start, end);
  }

  @action
  toPage(pageNumber, pageSize) {
    this.getPage().setPageSize(pageSize);
    const page = new Page(this.getPage().total, pageNumber);
    page.setPageSize(pageSize);
    if (!page.isValid()) {
      return;
    }
    if(!this.source) {
      this.source = new KeyedList(this.getData().slice());
    }
    const data = this.source.items.slice(page.getStartPoz(), page.getEndPoz());
    this.data.replace(data);
    this.getPage().setCurrent(pageNumber);
    this.filtered = true;
  }

  /**
   * 清除所有数据
   */
  @action
  clear() {
    this.data.removeAll();
    if(this.removed) {
      this.removed.removeAll();
    }
    this.sorted = false;
    this.filtered = false;
    this.getPage().dispose();
    this.maxCid = 1;
  }

  /**
   * 计算集合中的总合
   * @param {string} field 计算的列
   */
  sum(field) {
    return this.data.sum(fieldName);
  }

  /**
   * 计算集合中的最大值
   * @param {string} field 计算的列
   */
  max(field) {
    return this.data.max(fieldName);
  }

  /**
   * 计算集合中的最小值
   * @param {string} field 计算的列
   */
  min(field) {
    return this.data.min(fieldName);
  }

  /**
   * 计算集合中的平均值
   * @param {string} field 计算的列
   */
  avg(field) {
    return this.data.avg(fieldName);
  }

  /**
   * 销毁store
   */
  dispose() {
    this.sorted = false;
    this.filtered = false;
    this.getPage().dispose();
    this.data.dispose();
    if(this.removed) {
      this.removed.dispose();
      // this.removed = null;
    }
    this.maxCid = 1;
    // this.pageStore = null;
    // this.data = null;
    if (this.source) {
      this.source.dispose();
    }
    this.disposed = true;
  }

  /* ===================== private ======================== */
  transformData(data) {
    let records = [];
    if (!Array.isArray(data)) {
      data = [data];
    }
    data.forEach(record => {
      let instance;
      if(record === undefined) {
        return;
      }
      if(record.isModel) {
        instance = record;
      }else{
        instance = new this.model(record);
      }
      let id = instance.getId();
      if(id) {
        instance.cid = this.maxCid;
      }else{
        let cid = this.maxCid;
        instance.cid = cid;
        instance.setId(-cid);
        instance.phantom = true;
        instance.isSync = false;
      }
      this.maxCid++;
      instance.store = this;
      records.push(instance);
    });
    return records;
  }

  @action
  updatePage(remoteTotal) {
    if (remoteTotal !== undefined) {
      this.getPage().remoteTotal = remoteTotal;
    }
    this.getPage().localTotal = this.getSource().getCount();
  }

  /* ===================== private ======================== */

}
/**
 * 创建store。你可以自定义function，只需写在后面即可，所有的function默认被@action包装
 * @param {object} option 
 * @param {object[]} option.data 数据
 * @param {number} option.count 数据总量，你可以传入服务器返回的总数，如果不传，store会以data的长度为准
 * @param {string} option.model model名称
 * @param {string | number} option.storeId 改store的唯一标识
 * @returns store
 */
Store.create = function({ data, count, model, storeId, ...rest }) {
  invariant(model, '必须传入model');
  const store = new Store({ data, count, model, storeId })
  for (let k in rest ) {
    this.prototype[k] = action(rest[k]);
  }
  // StoreManager.register(store);
  return store;
}
export default Store;
