import { Model } from 'qn/data';

@Model({
  modelName: 'InternationalExchangeRate',
  fields: {
    rateId: 'number',
    payId: 'number',
    payAcronyms: 'string',
    paySymbol: 'string',
    exchangeId: 'number',
    exchangeAcronyms: 'string',
    exchangeSymbol: 'string',
    referenceRate: 'number',
    qfpayRate: 'number',
    createTime: 'string',
    createBy: 'string',
    payCode: 'string',
    exchangeCode: 'string',
    updateBy: 'string',
    updateTime: 'string',
    remark: 'string',
    params: 'string',
    note: 'string',
    status: 'number',
    payNationalFlag: 'string',
  },
  idgen: 'uuid',
})
class InternationalExchangeRate {

}

export default InternationalExchangeRate;
