export default function mixin(...objects) {
  return target => {
    objects.forEach(object => {
      let prototype = Object.getOwnPropertyNames(object.prototype);
      prototype.forEach(key => {
        if(typeof target.prototype[key] === 'undefined') {
          Object.defineProperty(target.prototype, key, Object.getOwnPropertyDescriptor(object.prototype, key))
        }
      })
    });
    return target;
  }
}