import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
  modelName: 'Withdrawal',
  fields: {
	withdrawalNum: 'string',
	amount: 'number',
    enterpriseName: 'string',
    storeName: 'string',
    creatorUserId: 'number',
    createdDate: 'date',
    balance: 'number',
    enterpriseId: 'number',
    storeId: 'number',
    paymentType: 'string',
    status: 'number',
    
  },
  idgen: 'uuid',
})
class Withdrawal {

}

export default Withdrawal;
