import axios from 'axios';
import sessionStore from 'sessionStore';
import { DEFAULT_LOCALE } from 'config';
import Modal from 'control/modal';

/* ===============加载mock================= */
// import {
//   mockBranch,
//   mockEnterprise,
//   mockTransaction, mockReturnFund, mockLogin, mockRegister, mockVcode,
// } from 'mock';

// mockBranch();
// mockEnterprise();
// mockBranch();
// mockTransaction();
// mockReturnFund();
// mockLogin();
// mockRegister();
// mockVcode();
/* ===============加载mock================= */

let baseUrl = 'http://localhost:8888';
if (process.env.NODE_ENV === 'production') {
  baseUrl = '/api';
}
const api = axios.create({
  baseURL: baseUrl,
  // timeout: 1000,
  withCredentials: true,
  // transformRequest: [data => JSON.stringify(data.data)],
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
function isLocaleApi(config) {
  if (config.method !== 'get') {
    return false;
  }
  const url = config.url;
  return url.includes('entreginfo')
     || url.includes('campmeta')
     || url.includes('campaigns')
     || url.includes('enterprise')
     || url.includes('campfields');
}
api.interceptors.request.use((config) => {
  // Do something before request is sent
  if (sessionStore.hasToken()) {
    config.headers['qfpay-login-token'] = sessionStore.getToken();
  }
  if (isLocaleApi(config)) {
    const lang = window.qf_locale.param;
    config.params = {
      ...config.params,
      locale: lang || DEFAULT_LOCALE,
    };
  }
  return config;
});
api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (_.get(error, 'response.status') === 401) {   
    
    window.history.replaceState(null, null, '/auth/login?out=1');
    window.location.reload();
    return error;
  }
  return Promise.reject(error);
});

export default api;
