import React from 'react';
import './style/loginFooter.scss';

const LoginFooter = ({ className }) => {
  return (
    <div className={className}>QFPay Japan Inc. All rights reserved.</div>
  );
}
LoginFooter.defaultProps = {
  className: 'login-footer',
}
export default LoginFooter;
