import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { Route, Switch, withRouter } from 'react-router-dom';
import React from 'react';

const Receivables = Loadable({
  loader: () => import('./Receivables'),
  loading: LCLoading,
});
const ReceivablesRoute = withRouter(({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={Receivables} />
    </Switch>
  );
});
export { ReceivablesRoute };
