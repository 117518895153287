import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import MenuList from './MenuList';
import MenuItem from './MenuItem';
import './style.scss';

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.createLinkMap();
    const activeHref = this.props.activeHref || (window.location && window.location.pathname);
    this.state = {
      activeHref,
      activeMenuList: this.linkMap[activeHref] || '',
    };
  }

  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    activeHref: PropTypes.string,
  };

  createLinkMap = () => {
    const { children } = this.props;
    const linkMap = {};
    children.forEach((child) => {
      if (child.type === MenuList) {
        const menuListKey = child.props.title;
        React.Children.toArray(child.props.children).forEach((menuItem) => {
          const key = menuItem.props.children.props.to || menuItem.props.children.props.href;
          linkMap[key] = menuListKey;
        });
      }
    });
    this.linkMap = linkMap;
  }

  componentWillReceiveProps = (nextProps) => {
    const activeHref = nextProps.activeHref;
    if (activeHref) {
      this.setState({ 
        activeHref,
        activeMenuList: this.linkMap[activeHref] || '' 
      });
    }
  };

  handleItemClick = (evt, href, parentMenuTitle) => {
    evt.stopPropagation();

    // 13 = Enter, 32 = Spacebar
    const acceptableEvent = evt.which === 13 || evt.which === 32 || evt.type === 'click';
    const diffHref = href !== this.state.activeHref;
    if (acceptableEvent && diffHref) {
      this.setState({ activeHref: href, activeMenuList: parentMenuTitle });
    }
    if (!parentMenuTitle) {
      this.props.children.forEach((child, index) => {
        if (child.type === MenuList) {
          const childId = `list-${index}`;
          this.refs[childId].close();
        }
      });
    }
  };

  handleListClick = (id) => {
    this.props.children.forEach((child, index) => {
      if (child.type === MenuList) {
        const childId = `list-${index}`;
        if (childId !== id && !child.props.isExpanded) {
          this.refs[childId].close();
        }
      }
    });
  };

  buildNewListChild = (child, index) => {
    let open = child.props.open;

    React.Children.map(child.props.children, c => {
      const { href, to } = c.props.children.props;
      const childHref = href === undefined ? to : href;
      const activePath =
        window.location && window.location.hash ? window.location.hash : this.state.activeHref;
      if (childHref === activePath) open = true;
    });

    const key = `list-${index}`;
    return (
      <MenuList
        {...child.props}
        key={key}
        ref={key}
        id={key}
        onListClick={this.handleListClick}
        onItemClick={this.handleItemClick}
        activeHref={this.state.activeHref}
        activeMenuList={this.state.activeMenuList}
        open={open}
      />
    );
  };

  buildNewItemChild = (child, index) => {
    const key = `item-${index}`;
    return (
      <MenuItem
        {...child.props}
        key={key}
        onClick={this.handleItemClick}
        activeHref={this.state.activeHref}
      />
    );
  };

  render() {
    const {
      className,
      children,
      activeHref, // eslint-disable-line no-unused-vars
      ...rest
    } = this.props;

    const newChildren = React.Children.map(children, (child, index) => {
      if (child.type === MenuList) {
        return this.buildNewListChild(child, index);
      } else if (child.type === MenuItem) {
        return this.buildNewItemChild(child, index);
      }
      return child;
    });

    const classNames = classnames('qf-left-menu-ics', className);

    return (
      <nav
        role="presentation"
        tabIndex={-1}
        aria-label="Interior Left Navigation"
        className={classNames}
        {...rest}
      >
        <ul key="main_list" className="left-menu-list" role="menubar">
          {newChildren}
        </ul>
      </nav>
    );
  }
}
