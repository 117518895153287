import React from 'react';
import { observer } from 'mobx-react';
import msgImg from 'public/img/msg.png';
import { StringFormat } from 'control/format';
import cx from 'classnames';
import moment from 'moment';

const PopoverListItem = observer(({ item, onClick }) => {
  const itemCx = cx('d-flex popover-message-list__item', {
    'popover-message-list__item--readed': item.hasBeenRead,
  });
  return (
    <div className={itemCx} onClick={onClick}>
      <div className="popover-message-list__item__icon"><img src={msgImg} alt="" /></div>
      <div>
        <div className="popover-message-list__item__title">{item.preview}</div>
        <div className="popover-message-list__item__date">
          {moment(item.createdDate).fromNow()}
        </div>
      </div>
    </div>
  );
});

@observer
class PopoverList extends React.Component {
  render() {
    const { data, style, onClick,locale } = this.props;
    if(data.length != 0){
      return (
        <div className="popover-message-list" style={style}>
          {data.map(item => <PopoverListItem item={item} key={item.key} onClick={onClick} />)}
        </div>
      )
    } else {
      return (
        <div className="no-popover-message-list" style={{padding:110}} style={style} >
        {locale.message.noMessage}
        </div>
      )
    }
  }
}

export default PopoverList;