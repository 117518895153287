import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

export const Barcode = Loadable({
  loader: () => import('./Barcode'),
  loading: LCLoading,
});

export const BarCodeRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={Barcode} />
    </Switch>
  );
};
