import { Model } from 'qn/data';

@Model({
  modelName: 'Savvy',
  fields: {
    uid: 'number',
    enterpriseName: 'string',
    enterpriseId: 'number',
    storeName: 'string',
    storeId: 'number',
    address : 'string',
    mail : 'string',
    phoneNumber: 'string',
    hasOpen: 'boolean',
    status: 'number',
    useOwnBankAccount: 'boolean',
    billNum: 'string',
    billAmount: 'number',
    billMonth: 'string',
    statementDate: 'string',
    paymentDeadline: 'string',
    payType: 'number',
    payStatus: 'number',
    createdDate:'date',
    updateDate: 'string',
    payDate: 'string'
  },
  idgen: 'uuid',
})
class Savvy {
}

export default Savvy;
