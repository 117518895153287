import Field from './Field';

class DateField extends Field {
  type = 'date'
  convert(v) {
    if(v === null || v === undefined || v === '') {
      return v;
    }
    if(v instanceof moment) {
      return v;
    }
    if(moment(new Date(v)).isValid()) {
      return moment(v);
    }
    return v;
  }

  isEqual(oldV, newV) {
    if(moment.isMoment(oldV) && moment.isMoment(newV)) {
      return oldV.isSame(newV);
    }
    return false;
  }
}
export default DateField;
