import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

export const BarCodeList = Loadable({
  loader: () => import('./BarCodeList'),
  loading: LCLoading,
});
export const BarCodeListRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={BarCodeList} />
    </Switch>
  );
}