import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const Dashboard = Loadable({
  loader: () => import('./Dashboard'),
  loading: LCLoading,
});
const DashboardRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={Dashboard} />
    </Switch>
  );
}
export { Dashboard, DashboardRoute };
