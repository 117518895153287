import React from 'react';
//import { Loading } from 'carbon-components-react';
import Loadings from 'public/img/three-dots.svg';
import './style.scss';

class LCLoading extends React.Component {
  
  render() {
    return ( 
    <div id="loading">
      <div className="new-loading"></div>
      <div className="loading-new"><img src={Loadings} style={{width:'60%',width:'60%'}}/></div>
    </div>
    )
  }
}

export default LCLoading;
