import React from 'react';
import Header from 'component/header/Header';
import LoginHeader from 'component/header/LoginHeader';
import { LocaleContext, locales } from 'component/LocaleContext';
import { Login } from 'component/login';
import { Forget } from 'component/forgetPwd';
import { Register, /*RegisterMore, */ RegisterSuccess, RegisterType } from 'component/register';
import Transaction from 'component/transaction';
import { Route, Link, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { BranchRoute } from 'component/branch';
import { EnterpriseRoute } from 'component/enterprise';
import { Message } from 'component/message';
import { UserRoute } from 'component/user';
import { DashboardRoute } from 'component/dashboard';
import Nav from 'component/nav';
import LoginFooter from 'component/footer/LoginFooter';
import api from 'remote/api';
import { observer } from 'mobx-react';
import { runInAction, observable, action } from 'mobx';
import messageStore from 'component/message/MessageStore';
import { CampaignRoute } from 'component/campaign';
import Footer from 'component/footer/Footer';
import sessionStore from 'sessionStore';
import appStore from 'appStore';
import { PendingReviewRoute } from 'component/review/pendingreviewlist';
import { AuthorizedRoute, access } from 'acl';
import Card from 'control/card';
import withLocale from 'decorator/withLocale';
import { ApprovedReviewRoute } from 'component/review/approvedReviewList';
import { WithdrawalManagementRoute } from 'component/withdrawalManagement';
import { DeviceManagementRoute } from 'component/deviceManagement';
import { WithdrawalRoute } from 'component/withdrawal';
import { BarCodeRoute } from 'component/barCode';
import { BarCodeListRoute } from 'component/barCodeList';
import { QfpayMore } from 'component/qfpayMore';
import { SettlementAdminRoute } from 'component/settlementAdmin';
import { SettlementRoute } from 'component/settlement';
import { SettlementAlOrWxRoute } from 'component/settlementAlOrWx';
import { ShopownerDeviceManagementRoute } from 'component/shopownerDeviceManagement';
import { EnterpriseDeviceManagementRoute } from 'component/enterpriseDeviceManagement';
import { DEFAULT_LOCALE } from 'config';
import { UpdatePassword } from 'component/updatePassword';
import { AdvertisingRoute } from 'component/advertising';
import { EnterpriseUserRoute } from 'component/EnterpriseUser';
import { ExceptionOrderRoute } from 'component/exceptionOrder';
import { UpdateSettings } from 'component/updateSettings';
import { AdminUpdateSettings } from 'component/adminUpdateSettings';
import { disposeBarCodeRoute } from 'component/disposeBarCode';
import { acdExchangeRate } from 'component/acdExchangeRate';
import { internationalExchangeRate } from 'component/internationalExchangeRate';
import { StoreSavvyRoute } from 'component/storeSavvy';
import { EnterpriseSavvyRoute } from 'component/enterpriseSavvy';
import { AdminSavvyRoute } from 'component/adminSavvy';


@withLocale
@observer
class Home extends React.Component {

  async componentDidMount() {
    if (sessionStore.hasToken()) {
      const res = await api.get('/messages');
      runInAction(() => {
        messageStore.messages.replace(res.data);
      });
    }
  }


  render() {
    const { match, locale } = this.props;
    return (
      <React.Fragment>
        <Header />
        <div className="d-flex" style={{ minHeight: appStore.getMainMinHeight() }}>
          <Card style={{ marginTop: 10, height: 'auto', maxHeight: 920 }}>
            <Nav />
            <div style={{ height: 80 }}></div>
          </Card>
          <div className="main" style={{ width: '100%', minHeight: 790 }}>
            <Switch>
              <AuthorizedRoute path={`${match.path}/branch`} component={BranchRoute} />
              <AuthorizedRoute path={`${match.path}/user`} component={UserRoute} />
              <AuthorizedRoute path={`${match.path}/enterprise`} component={EnterpriseRoute} />
              <Route path={`${match.path}/message`} component={Message} />
              <Route path={`${match.path}/transaction`} component={Transaction} />
              <AuthorizedRoute access={[access.MANAGE_PROMOTION_CAMPAIGN, access.STORE_OWNER, access.ENTERPRISE_OWNER]} path={`${match.path}/campaign`} component={CampaignRoute} />
              <AuthorizedRoute path={`${match.path}/dashboard`} component={DashboardRoute} />
              <AuthorizedRoute path={`${match.path}/pendingReview`} component={PendingReviewRoute} />
              <AuthorizedRoute path={`${match.path}/approvedReview`} component={ApprovedReviewRoute} />
              <AuthorizedRoute path={`${match.path}/withdrawalManagement`} component={WithdrawalManagementRoute} />
              <AuthorizedRoute path={`${match.path}/deviceManagement`} component={DeviceManagementRoute} />
              <AuthorizedRoute path={`${match.path}/withdrawal`} component={WithdrawalRoute} />
              <AuthorizedRoute path={`${match.path}/barcode`} component={BarCodeListRoute} />
              <AuthorizedRoute path={`${match.path}/settlementadmin`} component={SettlementAdminRoute} />
              <AuthorizedRoute path={`${match.path}/settlement`} component={SettlementRoute} />
              {/* <AuthorizedRoute path={`${match.path}/settlementAlOrWx`} component={SettlementAlOrWxRoute} /> */}
              <AuthorizedRoute path={`${match.path}/shopownerDeviceManagement`} component={ShopownerDeviceManagementRoute} />
              <AuthorizedRoute path={`${match.path}/enterpriseDeviceManagement`} component={EnterpriseDeviceManagementRoute} />
              <Route path={`${match.path}/more`} component={QfpayMore} />
              <Route path={`${match.path}/updatePassword`} component={UpdatePassword} />
              <AuthorizedRoute path={`${match.path}/advertising`} component={AdvertisingRoute} />
              <AuthorizedRoute path={`${match.path}/enterpriseUser`} component={EnterpriseUserRoute} />
              <AuthorizedRoute path={`${match.path}/exceptionOrder`} component={ExceptionOrderRoute} />
              <AuthorizedRoute access={[access.STORE_OWNER, access.ENTERPRISE_OWNER]} path={`${match.path}/updateSettings`} component={UpdateSettings} />
              <AuthorizedRoute access={[access.SUPER_ADMIN]} path={`${match.path}/adminUpdateSettings`} component={AdminUpdateSettings} />
              <AuthorizedRoute access={[access.STORE_OWNER, access.FIXED_AMOUNT_QR_CODE]} path={`${match.path}/disposeBarCode`} component={disposeBarCodeRoute} />
              <AuthorizedRoute access={[access.SUPER_ADMIN, access.ACD_RATE]} path={`${match.path}/acdExchangeRate`} component={acdExchangeRate} />
              <AuthorizedRoute access={[access.SUPER_ADMIN, access.ACD_RATE]} path={`${match.path}/internationalExchangeRate`} component={internationalExchangeRate} />
              <AuthorizedRoute access={[access.STORE_OWNER]} path={`${match.path}/storeSavvy`} component={StoreSavvyRoute} />
              <AuthorizedRoute access={[access.ENTERPRISE_OWNER]} path={`${match.path}/enterpriseSavvy`} component={EnterpriseSavvyRoute} />
              <AuthorizedRoute access={[access.SUPER_ADMIN]} path={`${match.path}/adminSavvy`} component={AdminSavvyRoute} />
            </Switch>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

const Auth = observer(({ match }) => {
  return (
    <div className="auth-wrapper">
      <LoginHeader />
      <Switch>
        <Route exact path={`${match.path}/login`} component={Login} />
        <Route exact path={`${match.path}/forget`} component={Forget} />
        {/* <Route exact path={`${match.path}/register`} component={Register} /> */}
        <Route exact path={`${match.path}/registerType`} component={RegisterType} />
        {/* <Route exact path={`${match.path}/registerMore`} component={RegisterMore} /> */}
        <Route exact path={`${match.path}/registerSuccess`} component={RegisterSuccess} />
        <Route component={Login} />
      </Switch>

    </div>
  );
});

@observer
class App extends React.Component {
  constructor() {
    super();
    const langInStorage = sessionStore.getLocale();
    let lang = DEFAULT_LOCALE;
    if (langInStorage) {
      lang = langInStorage;
    }
    this.state = {
      locale: locales[lang],
      setLocale: this.setLocale,
    };
    window.qf_locale = locales[lang];
  }

  setLocale = (value) => {
    if (value !== this.state.locale.l) {
      this.setState({
        locale: locales[value],
      });
      window.qf_locale = locales[value];
      sessionStore.saveLocale(value);
      window.location.reload();
    }
  }

  render() {
    return (
      <LocaleContext.Provider value={this.state}>
        <BrowserRouter>
          <Switch>
            <Route path="/auth" component={Auth} />
            <AuthorizedRoute path="/app" component={Home} />
            <Redirect exact from="/" to="/app/dashboard" />
          </Switch>
        </BrowserRouter>
      </LocaleContext.Provider>
    );
  }
}
export default App;
