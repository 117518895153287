import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import { observer } from 'mobx-react';
import sessionStore from 'sessionStore';

const AuthorizedRoute = observer(({ component: Component, access, ...rest }) => {
  const userAccess = sessionStore.getUser().accesses;
  const redirectUrl = sessionStore.getRedirectUrl();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (redirectUrl !== '/app/dashboard') {
          if (redirectUrl === '/app/acdExchangeRate') {
            return <Component {...props} />;
          }
          return (
            <Redirect
              to={{
                pathname: '/auth/login',
                state: { from: props.location },
              }}
            />
          );
        }
        if (!access) {
          return <Component {...props} />;
        }
        return access.some(o => userAccess.includes(o)) 
          ? <Component {...props} />
          : (
            <Redirect
              to={{
                pathname: '/app',
                state: { from: props.location },
              }}
            />
          );
      }} 
    />
  );
});
export default AuthorizedRoute;

