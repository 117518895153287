const access = {
  APP_LOGIN: 'APP_LOGIN',
  WEB_LOGIN: 'WEB_LOGIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  ENTERPRISE_OWNER: 'ENTERPRISE_OWNER',
  STORE_OWNER: 'STORE_OWNER',
  MANAGE_EMPLOYEE: 'MANAGE_EMPLOYEE',
  MANAGE_PROMOTION_CAMPAIGN: 'MANAGE_PROMOTION_CAMPAIGN',
  TRADE_QUERY: 'TRADE_QUERY',
  TRADE_STORE_QUERY: 'TRADE_STORE_QUERY',
  SETTLEMENT_MANAGEMENT: 'SETTLEMENT_MANAGEMENT',
  WITHDRAWAL_MANAGEMENT: 'WITHDRAWAL_MANAGEMENT',
  PAY_BARCODE: 'PAY_BARCODE',
  CONTRACT_INFO: 'CONTRACT_INFO',
  STORE_DEVICE_MANAGEMENT: 'STORE_DEVICE_MANAGEMENT',
  ENTERPRISE_DEVICE_MANAGEMENT: 'ENTERPRISE_DEVICE_MANAGEMENT',
  ENTERPRISE_USER: 'ENTERPRISE_USER',
  MANAGE_BRANCH: 'MANAGE_BRANCH',
  ORDER_REFUND: 'ORDER_REFUND',
  FIXED_AMOUNT_QR_CODE: 'FIXED_AMOUNT_QR_CODE',
  ACD_RATE: 'ACD_RATE'
};
const unControlAccess = [access.SUPER_ADMIN, access.ENTERPRISE_OWNER, access.STORE_OWNER];
export { access, unControlAccess };
