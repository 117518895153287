import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { AuthorizedRouter } from 'acl';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

const Campaign = Loadable({
  loader: () => import('./Campaign'),
  loading: LCLoading,
});
const CampaignCreate = Loadable({
  loader: () => import('./CampaignCreate'),
  loading: LCLoading,
});
const CampaignEdit = Loadable({
  loader: () => import('./CampaignEdit'),
  loading: LCLoading,
});

const CampaignRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/:id/edit`} component={CampaignEdit} />
      <Route exact path={`${match.path}/new`} component={CampaignCreate} />
      <Route exact path={`${match.path}`} component={Campaign} />
    </Switch>
  );
}
export { CampaignRoute, Campaign, CampaignCreate, CampaignEdit };
