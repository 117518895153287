import React from 'react';
import accounting from 'accounting';

// 价格格式化 round->保留位数 symbol->价格符号 format->显示格式, accurate->是否精确显示
export const PriceFormat = ({ value, symbol, format, round, precision = 0, accurate = false, nilRender = '--', ...props }) => {
  let result;
  if (_.isNil(value) && !_.isNil(nilRender)) {
    return <span>{nilRender}</span>;
  }
  if (_.isNil(value)) {
    return <span>--</span>;
  }
  if (typeof value === 'object' && value.isDivided) {
    if (!value.flag) {
      result = `${value.dividend}/${value.divisor}`;
      let money = accounting.formatMoney(1, {
        symbol: symbol || '￥',
        format: format || '%v',
        precision: precision
      });
      money = money.replace('1', result);
      return (
        <span {...props}>
          {money}
        </span>
      );
    }
    result = value.result;
  } else {
    result = value;
  }
  
 

  const money = accounting.formatMoney(result, {
    symbol: symbol || '￥',
    format: format || '%v',
    precision: precision,
  });
  
 
  return (
    <span {...props}>
      {money}
    </span>
  );
};
