import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

export const Settlement = Loadable({
  loader: () => import('./Settlement'),
  loading: LCLoading,
});

export const SettlementRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={Settlement} />
    </Switch>
  );
};
