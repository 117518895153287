import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

export const advertising = Loadable({
  loader: () => import('./Advertising'),
  loading: LCLoading,
});
export const advertisingAllot = Loadable({
  loader: () => import('./AdvertisingAllot'),
  loading: LCLoading,
});
export const advertisingView = Loadable({
  loader: () => import('./advertisingView'),
  loading: LCLoading,
});
export const AdvertisingRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/allot`} component={advertisingAllot} />
      <Route exact path={`${match.path}/:id/view`} component={advertisingView} />
      <Route exact path={`${match.path}`} component={advertising} />
    </Switch>
  );
}