import { Model } from 'qn/data';

@Model({
  modelName: 'Branch',
  fields: {
    uid: 'string',
    key: 'string',
    name: 'string',
    status: 'string',
    address: 'string',
    mail: 'string',
    phone: 'string',
    closedDate: 'string'
  },
  idProperty: 'key',
})
class Branch {

}

export default Branch;
