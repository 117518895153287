import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';

export const Register = Loadable({
  loader: () => import('./RegisterMore'),
  loading: LCLoading,
});

// export const RegisterMore = Loadable({
//   loader: () => import('./RegisterMore'),
//   loading: LCLoading,
// });

export const RegisterSuccess = Loadable({
  loader: () => import('./RegisterSucess'),
  loading: LCLoading,
});

export const RegisterType = Loadable({
  loader: () => import('./RegisterType'),
  loading: LCLoading,
});

export default { Register, /*RegisterMore,*/ RegisterSuccess, RegisterType };
