export default {
  l: 'jp',
  param: 'ja_JP',
  lang: '日本語',
  langSelection: '言語選択',
  brand: '加盟店管理システム',
  searchMore: '更に検索',
  search: '検索',
  modify: '編集',
  delete: '削除',
  export: 'ダウンロード',
  exportCSV: 'CSVダウンロード',
  exportExcel: 'Excelダウンロード',
  allexport: '全部ダンロード',
  exportOriginal: 'ローデータをダウンロード',
  withdrawalDetail: '引き出し詳細',
  complete: '完了',
  cancel: 'キャンセル',
  all: '全て',
  rests: 'その他',
  restsChannel: '他のウォレット',
  add: '追加',
  back: '戻る',
  notify: 'お知らせ',
  logout: 'ログアウト',
  moreSearchCriteria: '詳細条件を指定する',
  expand: '開く',
  takeUp: '閉じる',
  storeExpand: '詳細店舗開く',
  storeTakeUp: '詳細店舗閉じる',
  logoutConfirm: '終了してもよろしいですか？',
  submit: '提出',
  yes: 'はい',
  no: 'いいえ',
  psdError: '入力されたパスワードが正しくありません',
  noWebLoginAccess: '申し訳ありませんが、ログインする権限がありません。',
  operator: '操作',
  detail: '詳細',
  updatePassword: 'パスワードの変更',
  accountManage: 'アカウント管理',
  tradeFrom: '取引明細表',
  store: '店舗',
  storeUser: '従業員',
  select: '検索内容',
  noAuthority: '権限がありません',
  certificateOfIncorporation: '営業許可書',
  settings: '基本設定',
  tip: 'ご注意',
  login: {
    id: 'ログインID',
    idPlaceholder: 'メールアドレスを入力してください',
    storeownerEmail: '店長メール',
    psd: 'パスワード',
    psdPlaceholder: 'パスワードを入力してください',
    workNumber: '従業員番号',
    workNumberPlaceholder: '従業員番号を入力してください',
    autoLogin: 'ログインしたままにする',
    shopkeeperLogin: '管理者のログイン',
    employeeLogin: '従業員のログイン',
    forgetPsd: 'パスワードを忘れた場合',
    forgetPsdTitle: 'パスワードを忘れた場合',
    submit: 'ログイン',
    noLoginIdTip: '初めてご利用ですか ？',
    registerNow: 'QFPayサービスを申込',
    kickOut: '他のユーザーが同じアカウントでシステムにログインしたため、利用者様を強制的にログアウトさせて頂きました。安全性を確保するため、貴社の他のユーザーに連絡し、同じアカウントを使用しているかどうか確認してください。',
    rejectInfo: '申し訳ございませんが、ご提出頂いた情報の一部に間違いがありました。営業担当者に連絡して、提出した情報の修正を差し上げます。',
    pleaseEnterLoginID: 'ログインID',
    rescindInfo: 'お客様の取引先はすでに解約済みで、取引はできません。'
  },
  register: {
    mail: 'メールアドレス',
    mailPlaceholder: 'メールアドレスを入力してください',
    mailPlaceholderStore: '店舗ユーザーログインID',
    mailPlaceholderEnterprise: '企業ユーザーログインID',
    defaultPassword: '初期パスワードは123456',
    theDefaultPassword: '初期パスワード',
    psd: 'パスワード',
    psdPlaceholder: 'パスワードを入力してください',
    psdConfirm: 'パスワードの確認',
    psdConfirmPlaceholder: 'パスワードをもう一度入力してください',
    captcha: '検証コード',
    captchaPlaceholder: 'コードを入力してください',
    termsOfService: '「利用規約」',
    privacyRelatedPolicies: '「プライバシーポリシー」',
    agree: '同意する',
    agreeAndSendMail: '同意してサービス利用開始する',
    and: 'と',
    submit: '登録',
    hasLoginId: 'アカウントがあるので',
    toLogin: '今すぐログイン',
    oldPsd: '現在のパスワード',
    newPsd: '新しいパスワード',
    psdOldPlaceholder: '古いパスワードを入力してください',
    prompt: '次のページをクリックし、全読みしてから同意がクリックできるようになります。',
    titlePrompt: 'QFPayサービスご利用いただくには「利用規約」への同意が必要です。以下の内容をご確認の上、ご同意頂ける場合のみ「同意してサービス利用開始する」ボタンをクリックしてください。'
  },
  registerMore: {
    belongToEInfo: '会社情報',
    belongToEName: '会社名',
    hasEnterprise: '親会社は登録されていますか',
    has: 'はい',
    not: 'いいえ',
    bankInfoRequired: '独立決済する場合、銀行情報を入力してください。',
  },
  registerType: {
    title: 'アカウントの種類を選択してください',
    single: '個人店舗',
    chain: 'チェーン',
    personal: '個人',
    enterprise: '企業',
  },
  registerStep: {
    register: 'アカウント情報',
    moreInfo: '詳細情報',
    waitingReview: '審査待ち',
  },
  footer: {
    concatInfo: '連絡先',
    concatMail: 'メール',
    concatTel: '電話番号',
    businessHours: '営業時間',
    address: '住所：〒105-0001 東京都港区虎ノ門 1-10-5 KDX Toranomon 1 Chome 11F 株式会社QFPay Japan',
    except: '定休日と祝日は除く',
    useRole: '利用規約',
    privacy: 'プライバシーポリシー',
    companyInfo: '会社情報',
    aboutUs: '会社概要',
    qfpay: 'QFPay Japan',
    about: '関連情報',
    naviJapan: 'Navi Japan',
    yayaTraslation: 'Yaya Translation',
    pay: '決済サービス',
    supportPay: 'QFPayで利用できる決済サービス',
  },
  nav: {
    homePage: 'トップページ',
    tranManagemant: '取引管理',
    propManagement: 'PR管理',
    promActivities: 'キャンペーン',
    mercManagement: '店舗管理',
    employeeManagement: '従業員管理',
    toReview: '審査待ち',
    branchStoreManagement: '支店管理',
    subscription: '契約詳細',
    approvedReview: 'チャンネル審査',
    approvedReviews: '為替レートセット',
    withdrawalManagement: '引き出し管理',
    settlementManagement: '精算管理',
    deviceManagement: '端末管理',
    barcodeImage: '決済用QRコード',
    settlement: '精算管理',
    tranQuery: '取引検索',
    updatePassword: 'パスワードの変更',
    advertiseManage: '広告管理',
    userManagement: 'ユーザー管理',
    exceptionOrder: '異常取引',
    settingsManage: 'メールの管理',
    disposeBarCode: 'QRコードの設定',
    acdExchangeRate: 'ACD為替レート',
    internationalExchangeRate: '国際為替レート',
    savvyPoint: 'ポイント',
    pointBillManagement: 'ポイント請求書管理',
    exchangeRateManagement: '為替レート管理',
  },
  permissions: {
    loginAccess: 'ログイン権限',
    tranManagemant: '取引管理',
    propManagement: 'PR管理',
    mercManagement: '店舗管理',
    deviceManagement: '端末管理',
    QFPayPlus: 'QFPay+'
  },
  dashboard: {
    scanIncome: '本日売り上げ',
    incomeNumber: '収入取引数',
    notice: '新しいお知らせ',
    noticeCount: '通',
    incomeOfMonth: '30日間の収入金額',
    currentPromot: 'キャンペーン',
    notAvailable: '利用不可',
    incomeStat: '収入統計',
    storeIncomeStat: '支店収入統計',
    lastMonth: '最近一ヶ月',
    lastWeek: '最近一週間',
    totalIncome: '総収入',
    refundsAmount: '返金数',
    paymentChannel: '支払いチャネル',
    count: '回',
    amount: '金額(円)'
  },
  user: {
    code: '従業員番号',
    employeeCode: '従業員番号',
    code_p: '従業員番号を入力してください',
    name: '従業員名前',
    name_p: '従業員の名前を入力してください',
    permission: '権限',
    permissionFilters: '権限',
    concatInfo: '連絡先',
    concatInfo_p: '従業員の連絡先情報を入力してください',
    operate: '操作',
    password: 'ログインパスワード',
    password_p: 'ログインパスワードを入力してください',
    deleteInfo: '従業員({code})を削除しますか？',
    addEmployee: '従業員追加',
    editEmployee: '従業員更新',
    tip: 'ご注意',
    printerId: 'プリント端末番号',
    payDeviceId: '端末番号',
    bindDevice: '設備を関連する',
    deviceIdDuplicate: '解除完了。',
    unbind: '設定を解除',
    export: 'ダウンロード',
    userInfo: '従業員情報表',
    downloadQRCode: 'QRコードをダウンロード',
    storeBrCode: '店舗のQRコードをダウンロード',
    addBrCode: '決済用QRコード追加',
    clickToDownloadQRCode: '決済用QRコードをダウンロード',
    formerDeleteUser: '端末の紐付けを解除ください',
    branchStoreName: '支店名',
    userName: 'ユーザ名',
    addUser: 'ユーザ追加',
    editUser: 'ユーザ編集',
    id: 'ログインID',
    id_p: 'ログインIDを入力してください',
    deleteInfo1: '当該ユーザ({code})を削除しますか。',
  },
  guide: {
    title: 'ヒント',
    hidden: 'このメッセージを表示しない',
    know: 'わかった',
    BrCode1: '決済用QRコード機能を改善しました',
    BrCode2: '各従業員の決済用QRコードを検索とダウンロードすることができます。各従業員の決済用QRコードをクリックして、決済用QRコード画面が展示し、ダウンロードできます。（店長のアイコンをクリックして、店長の決済用QRコードをダウンロードできます）',
    BrCode3: '決済用QRコード追加ボタンをクリックして、新しい従業員を追加できます。同時に、該当従業員に属する決済用QRコードを追加します。',
    User1: '従業員管理画面に以下の改善をしました',
    User2: '権限検索に各権限をグループに分けて、見やすいです。従業員追加と編集画面も同じ改善しました。',
    User3: '従業員の権限は一行に展示できない場合は、カーソルを三点リーダーの位置に置いて、詳細の権限リストが見えます。',
    device1: '快適にご利用いただけるため、端末管理画面を最適化にしました。',
    device2: '端末管理画面はさらにアップデートされ、QFPAYでご購入いただいた端末QM800のレジ担当者を管理するために使用することも、sunmiなどのandroid端末を追加または管理することもできます。',
    device3: '',
    device4: '',
    device5: '',
    device6: ''
  },
  device: {
    deviceId: '端末番号',
    storeName: '店舗名',
    importDeviceId: '端末番号を入力してください',
    importStoreName: '店舗名を入力してください',
    bindingState: '端末を紐付けているか',
    all: '全て',
    yes: 'はい',
    no: 'いいえ',
    add: '追加',
    softwareVersion: 'バージョン情報',
    bindingStore: '店舗と端末の設定',
    operate: '操作',
    binding: '端末の設定',
    unbind: '設定を解除',
    delete: '削除',
    tip: 'ご注意',
    deleteInfo: '端末情報({code})を削除しますか',
    unbindInfo: '端末を解除しますか？',
    edit: '編集',
    remark: 'メモ',
    addError: 'この端末番号は既に登録されています!',
    staff: '従業員名前',
    bindingStaff: 'レジ担当者の設定',
    comment: '※端末情報を記録し、簡単に区別することができます。',
    deviceType: '端末種類',
    hardware: 'QM800',
    android: 'Android端末',
    formerDeleteUser: '端末の紐付けを解除ください',
    AndroidDevicesBinding: 'アンドロイドデバイスを紐付ける前に、店舗を設定してください。',
  },
  access: {
    APP_LOGIN: 'appにログイン',
    WEB_LOGIN: '管理システムにログイン',
    SUPER_ADMIN: 'SUPER_ADMIN',
    ENTERPRISE_OWNER: 'ENTERPRISE_OWNER',
    STORE_OWNER: 'STORE_OWNER',
    MANAGE_EMPLOYEE: '従業員管理',
    MANAGE_PROMOTION_CAMPAIGN: 'キャンペーン管理',
    TRADE_QUERY: '取引検索',
    TRADE_STORE_QUERY: '本店の取引検索',
    SETTLEMENT_MANAGEMENT: '清算管理',
    WITHDRAWAL_MANAGEMENT: '引き出し管理',
    PAY_BARCODE: '決済用QRコード',
    CONTRACT_INFO: '契約詳細',
    ENTERPRISE_USER: 'ユーザー管理',
    STORE_DEVICE_MANAGEMENT: '端末管理',
    ENTERPRISE_DEVICE_MANAGEMENT: '端末管理',
    MANAGE_BRANCH: '支店管理',
    ORDER_REFUND: '取引の返金権限',
    FIXED_AMOUNT_QR_CODE: 'QRコードの設定'
  },
  receivables: {
    code: '決済番号(QFPay発行)',
    originTradeNo: '元取引番号',
    storeId: '店舗ID',
    storeName: '店舗名',
    storeAddress: '店舗住所',
    promotionName: '割引キャンペーン名',
    originAmount: '割引前の金額',
    serviceCashing: '取引手数料',
    netAmount: '正味金額',
    cashier: 'レジ担当者番号',
    tragePeriod: '取引時間',
    trageTime: '取引時間',
    businessWaterNumberTitle: '決済番号(QFPay発行)',
    null: 'なし',
    TradingTime: '取引時間',
    initTradingTime: '取引時間',
    currency: '通貨タイプ',
    finalTotalAmount: '注文金額',
    payMethod: '具体的な支払い方法',
    payMethodExplain: '<p style="text-align:left;font-size:14px;">具体的な支払い方法説明：</p><p style="text-align:left;font-size:14px;">1.お客が動的なQRコードをスキャン： 店舗がQRコードを展示して、お客様が相応的なウォレットで</br>支払います。</p><p style="text-align:left;font-size:14px;">2.店舗がお客のQRコードをスキャン： お客様がQRコードを展示して、店舗がQRコードをスキャンし</br>て、レジします。</p><p style="text-align:left;font-size:14px;">3.お客が静的なQRコードをスキャン、金額を入力：お客様が店舗に貼ってる静的なQRコードをスキャ</br>ン、金額を入力して、支払います。</p><p style="text-align:left;font-size:14px;">4.お客が静的なQRコードをスキャン、店舗が金額を入力：店舗が金額を入力して、お客様が店に貼っ</br>てる静的なQRコードをスキャンして、支払います。</p><p style="text-align:left;font-size:14px;">5.ミニプログラム決済：ミニプログラム中で起動された決済</p>',
    amount: '金額',
    amountDetails: '金額',
    operateAccount: 'スタッフ',
    storeDeviceId: '端末番号',
    trageChannel: '支払い方法',
    promotionType: 'キャンペーン',
    orderStatus: '取引状態',
    operate: '操作',
    refund: '返金',
    branchStoreName: '支店名',
    refundChannel: '返金チャネル',
    canRefundMoney: '返金可能金額',
    refundMoney: '返金金額',
    paySuccess: '支払い成功',
    payFailure: '支払い失敗',
    refunded: '返金済み',
    refundFailure: '返金失敗',
    refundFailureState: '返金失敗しました。何かございましたらご連絡ください。電話番号：0120-501-080 ',
    refundSuccess: '返金申請が成功しました。これから、ユーザ側へ${wallet}からの返金通知を発送します。',
    orderClose: '取引キャンセル',
    tradeAmount: '取引金額',
    offerType: 'キャンペーンタイプ',
    noCampaign: 'キャンペーンなし',
    businessWaterNumber: '決済番号',
    businessWaterNumberExplain1: '<p style="text-align:left;font-size:14px;">決済番号:</p><p style="text-align:left;font-size:14px;">${channel}より生成されたオーダー番号</p>',
    businessWaterNumberExplain2: '<p style="text-align:left;font-size:14px;">決済番号(QFPay発行):</p><p style="text-align:left;font-size:14px;">QFPayより生成されたオーダー番号</p>',
    issued: '(QFPay発行)',
    enterpriseLoginId: '店舗Id',
    storeLoginId: '支店Id',
    businessWaterNumberBranchStoreNameAdmin: '決済番号(QFPay発行)/スタッフ/端末番号/ログインId',
    businessWaterNumberBranchStoreName: '決済番号(QFPay発行)/支店名',
    businessWaterNumberOperateAccount: '決済番号(QFPay発行)/スタッフ/端末番号',
    transNumber: '取引件数',
    transMoney: '取引総金額',
    comment: 'メモ',
    discounts: 'クーポン',
    rate: 'レート',
    rateUnit: '(100分の1単位)',
    settlementTime: '精算時間',
    activityName: 'キャンペーン名',
    choose: 'キャンペーンを選択する',
    chooseStores: '支店選択',
    chooseHint: 'キャンペーンを選択して、検索条件になります',
    scanType: {
      QR: 'お客が動的なQRコードをスキャン',
      Scan: '店舗がお客のQRコードをスキャン',
      StaticQR: 'お客が静的なQRコードをスキャン、金額を入力',
      Applet: 'ミニプログラム決済',
    },
    clickToAddOrRemove: '支店名をクリックより追加または削除できます',
    selectedStore: '選択中の支店',
    chooseDevice: 'デバイスの選択',
    chooseDeviceHint: 'デバイス番号を選んで検索条件に入れます',
    chooseColumn: 'ダウンロード内容を選らんでください',
    columns: {
      TradeNumber: "決済番号(QFPay発行)",
      OriginalTradeNumber: "元取引番号",
      ChannelTransactionId: "チャネル取引番号",
      TradeTime: "取引時間",
      Currency: "通貨タイプ",
      Amount: "金額",
      TransactionFee: "取引手数料",
      Netamount: "正味金額",
      BankingOrg: "支払い方法",
      Status: "取引状態",
      StoreId: "支店ID",
      StoreName: "支店名",
      Operator: "スタッフ",
      CashierNumber: "レジ担当者番号",
      StoreDeviceId: "端末番号",
      payMethod: "具体的な支払い方法",
      CampaignType: "キャンペーン",
      CampaignName: "キャンペーン名",
      AmountbeforeCampaign: "キャンペーン無しでの金額",
      Remark: "メモ",
      Poundage: "レート",
      SettlementDate: "精算時間",
      PointAmount: '使ったポイント数',
      Deduction: '差し引き金額',
      OriginalAmount: 'ポイント利用前の金額'
    },
    savvyPoint: 'ポイント',
    pointAmount: '使ったポイント数',
    deduction: '差し引き金額',
    originalAmount: 'ポイント利用前の金額',
    pointRefundSuccess: '返金申請が成功しました。',
    exchangeAmount: '実際支払額',
    exchangeCurrency: '実際支払通貨',
  },
  exceptionOrder: {
    exceptionStatus: '異常状態',
    newCreate: '新増',
    solveSuccess: '解決成功',
    solveFailure: '解決失敗',
    manualSolution: '人工解決待ち',
    retryTimes: '再試行回数',
    MarkAsSolve: '标记为已解决',
    askToSolve: '确定要将该异常订单标记为已解决吗?',
  },
  transaction: {
    tabName: '取引詳細',
  },
  campaign: {
    activityTime: '期間',
    activityType: 'タイプ',
    activityStatus: '状態',
    activityName: 'キャンペーン名',
    inProcess: '進行中',
    terminated: '終了',
    unStart: '未開始',
    activityRange: '範囲',
    operate: '操作',
    inputName: 'キャンペーン名を入力してください',
    startAt: '開始時間',
    endAt: '終了時間',
    downloadQRCode: 'QRコードをダウンロード',
    QRCodeCount: 'QRコードの数',
    QRCodeSize: 'QRコードのサイズ',
    codeCanUseTime: 'クーポン利用可能回数',
    codeCanUseTimePlaceholder: 'クーポンコードの利用可能総回数',
    unlimited: '制限なし',
    customQuantity: 'カスタマイズ数',
    nextStep: '次へ',
    lastStep: '前へ',
    overlayUse: '他と共に使用',
    modifyActivityInfo: 'キャンペーン情報を修正',
    activityValidity: 'キャンペーン有効期限',
    activityAmount: '割引金額',
    remark: 'メモ',
    pleaseChooseType: '新規作成したいキャンペーンの種類を選択してください',
    pleaseInputActivityContent: 'キャンペーンの詳細を入力してください',
    release: 'リリース',
    selectType: '種類選択',
    inputContent: '入力内容',
    activityRelease: 'リリース',
    giftName: 'ギフト名',
    giftPhoto: 'ギフト写真',
    off: '割引金額',
    new: '新しいキャンペーンを追加する',
    allBranch: 'すべての店舗',
    usedTimess: '使用回数',
  },
  barcode: {
    comment: '決済用QRコードは静的なQRコードですので、ダウンロードした後、印刷して店舗に設置することができます。お客様は、このQRコードをスキャンして支払うことができます。'
  },
  branch: {
    SettlementExplain1: '<p style="text-align:left;font-size:14px;">独立決済店舗：</p><p style="text-align:left;font-size:14px;">取引残高が法人残高にカウントされず、</p><p style="text-align:left;font-size:14px;">直接QFPayで決済・入出金・入金を行います。</p><p style="text-align:left;font-size:14px;">送金は、別途、管理システムに登録されている振込先に行います。</p>',
    SettlementExplain2: '<p style="text-align:left;font-size:14px;">非独立決済店舗：</p><p style="text-align:left;font-size:14px;">QFPayシステムの通常の決済店舗の場合、</p><p style="text-align:left;font-size:14px;">企業ID残高の下に非独立決済店舗分が統一的に表示され、</p><p style="text-align:left;font-size:14px;">決済と現金引き出しの操作は企業IDにて行います。</p><p style="text-align:left;font-size:14px;">QFPayは、QFPayシステムに登録した企業の銀行口座に直接、</p><p style="text-align:left;font-size:14px;">申請された金額を送金します。</p>',
    SettlementType1: '独立した決済',
    SettlementType2: '非独立決済',
    uid: '店舗ID',
    createAt: '作成時間',
    status: '状態',
    auditSuccess: '審査通過',
    auditing: '審査中',
    auditFailure: '審査失敗',
    rescind: '解約済み',
    name: '支店名',
    type: '決済タイプ',
    code: '支店番号',
    address: '支店住所',
    storeStatus: '支店状態',
    mail: 'メールアドレス',
    tel: '電話番号',
    operate: '操作',
    view: '詳細',
    addStore: '支店追加',
    resubmit: '再提出',
    storeInfo: '支店情報',
    accountInfo: 'アカウント情報',
    bankInfo: '銀行情報',
    didShowBankInfo: '銀行情報を表示しますか',
    didFillingBankInfo: '銀行情報を記入する',
    fillExplain: '当該支店に銀行情報を入力しますか。',
    showExplain: 'ログインされる時には、当該支店の銀行情報を契約情報に表示されますでしょうか。表示されないをお選びいただいた場合、企業情報が表示されます。',
    bankCode: '銀行番号',
    remark: 'メモ',
    waitingTip: 'ご提出いただいた申請は現在審査中になっており、こちらは1-2稼働日以内に処理いたしますので、暫くお待ちください',
    failureTip: '申し訳ありませんが、ご提出頂いた情報の一部に間違いがありました。修正した上、もう一度提出してください。',
    loginEmail: 'メールアドレスが当該支店のログインIDです',
    attachedStore: '付属店舗'
  },
  branchAuditStatus: {
    NORMAL: '正常',
    TO_REVISE_MATERIAL: '拒否',
    AWAITING_REVIEW: '審査待ち',
    RESCIND: "解約済み"
  },
  pendingReviewList: {
    registerTime: '登録時間',
    userID: 'ログインID',
    userType: 'ユーザータイプ',
    registerType: '登録タイプ',
    lastUpdateTime: '最終更新時間',
    operate: '操作',
    audit: 'レビュー',
    not: '無し',
    reviewDate: '前回審査日',
    searchOther: 'ログインID',
    enterpriseName: '企業名',
    storeName: '支店名',
    auditStatus: '情報審査状態',
    auditing: '情報審査待ち',
  },
  registerTypeConst: {
    SELF_REGISTERED: '個人登録',
    ENTERPRISE_REGISTERED: 'ビジネス登録',
  },
  userTypeConst: {
    SINGLE: '店',
    STORE_USER: '支店',
    ENTERPRISE_USER: '本社',
    INCOMPLETE_REGISTRATION: '未記入情報があります',
  },
  AttachedUserTypeConst: {
    SINGLE: '店',
    STORE_USER: '付属店舗',
    ENTERPRISE_USER: '本社',
    INCOMPLETE_REGISTRATION: '未記入情報があります',
  },
  reviewDetail: {
    revoke: 'キャンセル',
    reject: '拒否',
    storeInfo: '店舗情報',
    storeName: '店舗名',
    storeAddress: '店舗住所',
    storeEmail: 'メールアドレス',
    isDevicePurchase: '端末を購入するか',
    storeTel: '連絡先',
    bankInfo: '銀行情報',
    bankInfoOld: '元の銀行情報',
    didFillingBankInfo: '銀行情報を記入する？',
    fillExplain: '当該支店に銀行情報を入力しますか。',
    showExplain: 'ログインされる時には、当該支店の銀行情報を契約情報に表示されますでしょうか。表示されないをお選びいただいた場合、企業情報が表示されます。',
    bankCode: '銀行番号',
    bankName: '銀行名',
    branchBankCode: '支店番号',
    branchBankName: '支店名',
    accountType: 'アカウント種類',
    accountName: '口座名義人',
    accountCode: '口座番号',
    settlementCurrency: '決済通貨',
    didIndependentSettlement: '独立決済しますか？',
    ok: '通過',
    refuse: '拒否',
    audit: '審査待ち',
    auditBankInfo: '銀行情報審査待ち'
  },
  withdrawalManagement: {
    withdrawalTime: '引き出し申請時間',
    searchOther: '支店名',
    status: '状態',
    toPay: '未支払い',
    ToBeProcessed: '処理待ち',
    paidAlready: '支払済み',
    withdrawalOrderNum: '引き出し申請番号',
    enterpriseName: '所属する企業名',
    storeName: '支店名',
    paymentChannel: '支払いルート',
    withdrawalAmount: '金額',
    pay: '金を払う',
    notSelectRecord: 'ダウンロードする記録をお選びください！',
    notSelectPayRecord: '未支払い記録をお選びください',
    confirmToPay: 'お金を払いますか？',
    startTodealwith: '処理開始',
    intheprocessing: '処理中',
    notSelectTodealwithRecord: '処理したい記録を選択してください。',
    confirmTodealwith: '処理しますか？',
    withdrawalNum: '引き出し件数',
    confirmIsPay: '支払済みに変更',
    withdrawalApply: '引き出し申請',
    withdrawalData: '引き出しローデータ',
    todayTip: 'データは現時点までになっており、ダウンロードしますか。',
    todayTip2: '※利用者の引き出し操作により、増加する可能性があります、ご注意ください。',
    warningTitle: '<h4>引出金額異常のご注意</h4><table><tr><td>企業名</td><td>店舗名</td><td>引き出し申請時間</td><td>引出金額</td><td>前回引出金額</td></tr>',
    logErrorTitle: '<h4>残高歴史の異常情報</h4><table><tr><td>企業名</td><td>店舗名</td><td>現在の残高</td><td>元の残高</td><td>異常が見つかった時間</td></tr>',
    logErrorTip: '残高歴史の異常情報',
    logErrorText: '＊処理されていない異常情報があれば、カスタマーサポートセンターにご連絡ください。',
    tableHeader: {
      enterpriseName: '企業名',
      storeName: '店舗名',
      currentCashing: '現在の残高',
      originalCashing: '元の残高',
      createDate: '異常が見つかった時間'
    },
    withdrawalTotal: '引き出し総額',
    withdrawalBalance: '引き出し後残高'
  },
  withdrawal: {
    withdrawalTime: '引き出し申請時間',
    withdrawalAmount: '残高',
    submitWithdrawal: '引き出しを申請',
    setAutomaticWithdrawTime: '自動引き出し時間を設定',
    withdrawalChannel: '引き出し方法',
    balance: '残高',
    balanceAfterWidthdrawal: '引き出し後の残高',
    withdrawalTitle: '引き出す金額を入力してください',
    withdrawalAutoTitle: '自動引き出しサイクルの設定',
    inputWithdrawalAmount: '引き出し金額',
    autoWithdrawalDaysExceedMaxDays: '自動引き出し期間は1000日以内にしてください！',
    autoWithdrawalDays: '引き出しサイクル設定',
    balanceNotEnough: '残高不足!',
    logError: '残高歴史に異常がありますので、カスタマーセンターにご連絡ください。',
    minBalanceEnough: '引き出し金額は1000円以上に設定してください。',
    comment: '残高とは出金可能な金額となっております、「引き出しを申請」ボタンをクリックし、出金金額を入力することで、出金することができます。もし、全ての残高を定期的に自動で出金したい場合には、「自動引き出しサイクルの設定」をクリックし、期間を設定しますと自動で出金させることができます。',
    warn: '残高は1000円以下の場合、引き出し申請は受付できませんのでご了承ください。自動引き出しサイクルをお設定された場合、残高が1000円以下としても自動引き出し申請を行いません。',
    warn1: '引き出し金額は5000万円以上の場合、他行間の振込手数料は不要となります。5000万未満の場合、毎回の引き出し申請に対して、256円の銀行振込手数料が発生します。ご了承お願い致します。',
    tip: 'ご注意',
    remark: '引き出し機能はまだご利用いただけません。リリース開始までご期待ください!',
    mincashCycle: '自動引き出し期間は7日間以上にしてください。',
    week: '一週間一回',
    twoWeek: '二週間一回',
    month: '一ヶ月間一回',
    close: '自動引き出し機能を停止する',
    explain: '説明',
    weekExplain: '毎週の月曜日に先週の残高に対して引き出し申請を行う',
    twoWeekExplain: '隔週の月曜日に過去二週間の残高に対して引き出し申請を行う',
    monthExplain: '毎月の一日に前月の残高に対して引き出し申請を行う',
    closeExplain: '自動引き出し機能を停止する',
    remind: '引き出し金額は500万円以下の場合、振込手数料をご負担いただきます。ご注意ください。',
    noPermissions: '引き出し権限がないですから、企業アカウントへログインして引き出し操作を実行されます。'
  },
  advertising: {
    pleaseEnter: '広告名',
    name: '広告名',
    nameAdd: '広告名',
    nameOf: '広告名:',
    imageUrl: '広告写真',
    printTimes: '印刷された回数',
    operate: '操作',
    distribution: '分配',
    addAdvertise: '広告を追加',
    editAdvertise: '広告を編集',
    startDate: '開始時間',
    expirationDate: '終わり時間',
    maxPrintTimes: '印刷回数上限',
    cannotDelete: 'この広告は既に店舗に分配されました。削除は分配を解除後にしてください。',
    confirmToDelete: 'この広告を削除しますか？',
    uploadPicture: '広告写真をアップロードしてください！',
    status: '状態',
    normal: '正常',
    stop: '使用停止',
    enterpriseOrStoreName: '企業名或いは店舗名',
    enterpriseOrStoreNameEnter: '',
    storeName: '支店名',
    haveNum: '広告数',
    printNum: 'この広告を印刷された回数',
    addAdvertiseToStore: '支店に追加',
    AdvertiseDisabled: '無効である広告',
    threeError: 'この支店は既に三つの広告が使用中ですので、上限になりました！',
    details: '詳細',
    enable: '使用始め',
    disable: '使用停止',
    advertiseManage: '広告管理',
    advertiseAllotDetails: '広告分配詳細',
    storeAdvertiseManage: '支店広告管理',
    printTimeError: '印刷回数は上限になりましたので、編集した後、もう一度試してください。',
    pastDueError: 'この広告が既に期限切りましたので、編集した後、もう一度試してください。',
    allPrintTimes: '印刷された総回数',
    storePrintTimes: '本店が印刷した回数',
    return: '前のページに戻る',
  },
  internationalExchangeRate: {
    meiyuan: 'ドル',
    riyuan: '円',
    taizhu: 'タイバーツ',
    paymentCurrency: '支払通貨',
    converter: '通貨交換',
    nationalFlag: '国旗',
    abbreviation: '英語略語',
    currency: '清算通貨',
    qfpayExchangeRate: 'QFPAY為替レート',
    createTime: '作成時間',
    operation: '操作',
    chooseAll: 'すべて選択',
    update: '変更',
    history: '履歴',
    search: '検索',
    name_p: '入力してください',
    editAdvertise: '編集',
    addAdvertise: '追加',
    goBack: '戻る',
    updateBy: '変更者',
    qfpayRateCheckText: '小数点以下4桁まで、上方四捨五入',
    qfpayRateCheckTextLength: '最大8桁',
    qfpayRateExist: '為替レートは空であってはならず、0より大きい値であること',
  },
  modal: {
    title: 'ヒント',
    primaryButtonText: '完了',
    secondaryButtonText: 'キャンセル',
    errorTitle: 'エラーがあります',
    confirmPrimaryButtonText: '確認',
    confirmSecondaryButtonText: 'キャンセル',
    systemErrorMessage: 'システムはエラーがあります！',
    systemErrorDashboard: '現在メンテナンス中のため、しばらく経ってからログインしてください。ご迷惑をかけし申し訳ございません。なおQFPayサービスでの通常の取引には影響ありません。',
    downPage: '次のページ'
  },
  validator: {
    required: '必須',
    number: '正しいパスワードを入力してください',
    percentageNumber: '正確な数字を入力してください（小数以下2桁まで表示する）',
    email: '正しいメールアドレスを入力してください',
    len: '入力した文字が長すぎます',
    len1: 'パスワードは、6桁～8桁の長さで、英字と数字の両方を含む必要があります',
    integer: '整数を入力してください',
    samePwd: '2つのパスワードが同じであることを確認してください。',
    existEmail: 'メールアドレスが登録されています。変更してください',
    unExistUser: 'ユーザが存在しません。或いは、パスワードが間違っています。',
    existWorkNumber: 'この従業員番号は既に使われています。他の番号を入力してください。',
    halfChar: '半角カナ・半角スペース・半角かっこをご入力ください。',
    receivable: {
      refoundAmountTooHight: '払い戻し額は払い戻し可能額を超えることはできません',
      refoundAmountGtZero: '払い戻し額は0より大きくする必要があります',
      refoundAmountInvalidDecimal: '払い戻し額は小数点以下2桁で保持されます',
      max_l_8: '最大8桁',
      max_l_100: '最大100桁',
    },
    positiveNumber: '正数を入力してください',
    noBindingChannels: '契約されたチャネル無し',
    existImageName: '広告名が使用されていますので、新たな名を入力してください。',
    maxLength: '最大限は10桁を入力してください。',
    existLoginId: '当該ログインIDは既に存在しています、もう一度入力してください。',
    maxUploadSize: '写真を1MB以下にしてください',
    invalidUploadExtensions: 'アップロードできる画像形式はjpeg,jpg,pngのみです',
    invalidUploadExtensionsAndPdf: '画像とPDFファイルのみアップロードできます',
    exchangeRate: '小数点以下8桁までの正しい為替レートを入力してください。'
  },
  enterprise: {
    null: 'なし',
    tip: '会社情報を変更する必要がある場合は、カスタマーサービスにお問い合わせてください。メール：info@qfpay.info',
    applyForChannel: '決済チャネル申請',
    registeredChannelInfo: '承認チャネル',
    applyChannelInfo: '審査中のチャネル',
    toApplyNewChannel: '新しいチャネルを申請する',
    checking: '当座',
    savinngs: '普通',
    waitingReview: '審査待ち...',
    parentheses: ')',
    refused: '拒否済み',
    editTo: '(修正後：',
    newUpload: '最新のアップロード',
    not: '無し',
    lease: 'レンタル',
    buy: '購入',
    notBuy: '購入しない',
    applyInAttachedStore: 'ヒント: 付属店舗内で新しいチャンネルをご申請ください。',
    bankTypeEnum: {
      BANK: '銀行',
      FINANCIAL_COOPERATIVE: '信金',
      CREDIT_COOPERATIVE: '信組',
      AGRICULTURAL_COOPERATIVE: '農協'
    },
    branchTypeEnum: {
      BRANCH_STORE: '支店',
      HEAD_OFFICE: '本店',
      FIELD_OFFICE: '出張所',
      BRANCH_OFFICE: '支所'
    },
  },
  channel: {
    storeId: '支店ID',
    id: '決済チャネルID',
    qf: 'QFPay',
    channel: 'チャネル',
    type: '支払いチャネル',
    currency: '通貨',
    rate: 'レート',
    rateUnit: '100分の1単位',
    withdrawCycle: '引き出しサイクル',
    withdrawCycleUnit: '日中',
    withdrawAmount: '一回引き出し最大額',
    withdrawAmountUnit: '単位は円',
    applySuccess: 'あなたの申請書が提出されました。審査待ちです。',
    comment: 'メモ',
    applicationDate: '申請時間',
    rejectReason: '拒否理由',
    typeEnum: {
      ALIPAY: 'Alipay',
      WECHAT: 'WeChat',
    },
    poundage: '手数料',
    language: 'jp',
    merchcomment: 'QFチャネルは自動的に口座を開設できます、その他のチャネルは決済IDを入力する必要があります。',
    whetherToUse: '使用禁止するか',
    hasBeendisabled: '使用禁止',
    whetherToPass: '通過しますか',
    ok: '通過',
    refuse: '拒否',
    applyFailure: '申請は失敗しましたので、のちほどお試しください',
    whetherSettlement: '精算しますか',
    settlement: 'はい',
    NoSettlement: 'いいえ',
    deviceNum: 'パスコード',
    iSORate: '一次代理店料率',
    iSVOneRate: '二次代理店料率',
    iSVTwoRate: '三次代理店料率',
    agentRate: '営業担当者料率',
    channelRate: 'チャネル料率',
    qfpayRate: 'QFPay料率'
  },
  approvedReview: {
    pendingReviewChannel: '審査中のチャネル',
    registeredChannel: '承認チャネル',
    reviewDate: '審査通過日',
    searchOther: 'ログインID',
    feeRate: '手数料',
  },
  tips: {
    confirmToDelete: '削除してもよろしいですか？',
    needToReWrite: '拒否されました。ユーザーに連絡して情報を再入力してください',
    noPremission: 'このアカウントには権限がありません',
    reviewInEmail: 'メールを確認してからパスワードを変更してください。',
    modifySuccess: 'パスワードを更新しました',
    channelExist: 'このユーザには、このチャネルがあるため、再度追加することはできません。',
    confirmPassed: '承認が合格したことはありますか？',
    rejectStore: '商業アプリケーションの拒否',
    actionSuccess: '操作成功',
    storeAccount: '管理者IDで操作してください',
    cannotDelete: 'この端末は既に店舗と設定しています、削除は設定を解除後にしてください。',
    actionFail: '操作失敗',
    ErrTime: '終了時間が開始時間より前の時間になっています。終了時間には開始時間以降の時間を指定して下さい。',
    selectChannel: 'ウォレットを選択してください',
    maxUpload: '画像は最大9枚までアップロードできます！',
    maxRate: '割引率は100％以下に設定してください。',
    selectDevice: '端末番号を選択してください。',
    selectCampaigns: 'キャンペーンを選択してください',
    lackJurisdiction: '引き出し機能を利用する権限はありません。',
    noMchId: '当該企業また店舗はチャネルIDがありません。入力してから申請してください。',
    openAccountFail: 'データが保存され、銭方APIヘのコールは失敗しました。エラーコードとエラーメッセージ：${errmsg}',
    noEnterMchid: 'データが保存され、銭方APIヘのコールは失敗しました。',
    selectOrderState: '取引状態を選択してください。',
    maxAmount: '最大限で四つのカーボンコピーのメールアドレスが入力されます。',
    auditFail: '店铺审核失败，请稍后重试！',
    appliFeerateRule: '料率は以下の条件を満たす必要があります：店舗料率 >= 直属上位代理店料率,下位組織料率>= 上位組織料率,チャネル料金は代理店料金よりも低い',
    curFeerate: '現在のレートは以下の通りです：店舗料率：${feeRate},チャネル料率：${channelRate}',
    iSORateTip: ',一次代理店料率：${iSORate}',
    iSVOneRateTip: ',二次代理店料率：${iSVOneRate}',
    iSVTwoRateTip: ',三次代理店料率：${iSVTwoRate}',
    agentRateTip: ',営業担当者料率：${agentRate}'
  },
  message: {
    readed: '既読',
    unread: '未読',
    readAll: 'すべて見る',
    noMessage: '通知がありません',
    noNewMessage: 'お知らせがありません'
  },
  pagination: {
    prev: '前のページ',
    next: '次のページ',
    each: '1ページあたりの数',
    total: '総ページ数',
  },
  currency: {
    c: '中国元',
    e: 'ドル',
    j: '円',
  },
  bank: {
    ALIPAY: 'Alipay',
    WECHAT: 'WeChat',
    UNKNOWN: '未登録チャネル',
    ZERO: 'ゼロ',
  },
  settlement: {
    amountInThisMonth: '今月の決済金額',
    pay: '支払い',
    status: '精算状態',
    status_unsettlement: '未精算',
    status_settled: '精算済み',
    settlementTime: '精算時間',
    payeeName: '口座名義人',
    payeeAccount: '口座番号',
    payeeBankName: '銀行名',
    bankBranch: '支店名',
    channel: 'チャネル',
    tradeAmount: '総売上',
    feeRate: 'レート',
    channelFee: '取引手数料',
    systemFee: 'システム使用費',
    serviceFee: 'サービス費',
    otherFee: 'その他の費用',
    finalFee: '決済金額',
    enterpriseName: '企業名',
    storeName: '支店名',
    doSettle: '精算',
    userType: 'タイプ',
    settlementRange: '精算期間',
    settlementFee: '決済予定額',
    settlementInfo: '精算情報',
    settlementSuccess: '精算成功',
    doSettleTime: '決済時間',
    operator: 'オペレータ',
    exportUnsettlement: '自動送金伝票のエクスポート',
    transferDate: '希望の振込日',
    settlementchannel: '清算チャンネル',
    transNumber: '取引件数',
    amount: '取引金額',
    actualarrivalamount: '実際入金金額',
    export: '明細をダウンロード',
    settlementDetail: '精算明細表',
  },
  QFPayMore: {
    develop: '新機能の登場を',
    wish: 'どうぞご期待ください。',
  },
  updateSettings: {
    setting: '送信のタイミングの設定',
    enterpriseName: '企業名',
    storeName: '支店名',
    cycle: '送信のタイミング',
    mail: 'メールアドレス',
    copyMail: 'カーボンコピー',
    all: '全て',
    time: '毎回',
    day: '毎日',
    week: '毎週',
    month: '每月',
    close: '閉じる',
    save: '保存',
    condirm: 'メールに関する設定を修正しますか?',
    tips1: '【毎回】を選んでいる場合、毎回の取引と返金が成功してからメールに添付してお送りいたします；',
    tips2: '【毎日】を選んでいる場合、設定し次第、毎日に一日前の取引詳細をお送りいたします；',
    tips3: '【毎週】を選んでいる場合、来週から、毎週の月曜日に先週の取引詳細をお送りいたします；',
    tips4: '【毎月】を選んでいる場合、来月から、毎月の一日に先月のすべての取引詳細をお送りいたします；',
    tips5: '【閉じる】を選んでいる場合、設定してから、取引詳細のメールをお送り致しません。',
    tips6: '',
    enterprise: '企業',
    store: '店舗',
    merchantName: '店舗名',
    mercahntMail: '店舗メールアドレス',
    operate: '操作',
    exchangeRate: '為替レート',
    baseInfo: '店舗基本情報',
    signStatus: '契約ステータス'
  },
  disposeBarCode: {
    title: 'タイトル',
    createDate: '作成時間',
    dateRange: '作成時間',
    amount: '金額',
    comment: 'メモ',
    operate: '操作',
    edit: '編集',
    detail: '詳細',
    delete: '削除',
    create: '決済用QRコード新規',
    title_P: 'タイトルを入力してください',
    amount_P: '金額を入力してください',
    comment_P: '備考を入力してください',
    barcodeTip: '決済用ＱＲコードは固定金額の静的ＱＲコードですので、ダウンロードして印刷してからお店において使えます。それから、お客様はそれをスキャンして支払います。',
    deleteTip: '該QRコードを削除しますか。'
  },
  acdExchangeRate: {
    updateTime: '更新時間',
    virtualCurrency: '仮想通貨',
    before: '更新前為替レート',
    after: '更新後為替レート',
    updateBy: '更新者',
    edit: '更新為替レート',
    nowRate: 'ACD 現在為替レート',
    newRate: 'ACD 新為替レート',
  },
  savvyPoint: {
    enterpriseName: '企業名',
    storeName: '支店名',
    address: '住所',
    phoneNumber: '電話番号',
    mail: 'メールアドレス',
    openPoint: 'ポイントサービス開通',
    openTip: 'ポイントサービスのご開通を行っていただきますよう、ご確認をお願いいたします。',
    intoSavvy: 'システムSavvyへログイン',
    billMonth: '請求書日付',
    billAmount: '金額',
    billNum: '請求書番号',
    paymentDeadline: '支払期限日',
    statementDate: '請求書の発行日付',
    payStatus: '支払い状態',
    status: '状态',
    hasOpen: '開通済み',
    notPay: '支払待ち',
    hasPay: '支払済み',
    overdue: '期限切れ',
    urging: '支払い催促済み',
    toOpen: '開通',
    waitPayBills: 'Savvy請求書',
    storeList: '店舗リスト',
    payDate: '支払い日付',
    markAsPaid: '支払済みにマック',
    payTip: '支払完了にしますか。',
    inputStoreName: '店舗名を入力してください',
    inputEnterName: '企業名を入力してください',
    ensureOpen: 'システムSavvyへアクセスすることを許可しますか。',
    openFailure: '操作失敗、何かございましたらご連絡ください。電話番号：0120-501-080。',
    reason: '原因：'
  },
  errorCode: {
    186688: 'redisにアクセスできません',
    186678: 'プライマリ キーが重複しました',
    186679: '認証コードの保存セッション が無効になりました',
    186680: '企業アカウントの登録に失敗しました',
    186681: 'Aliyunにアクセスできません',
    186682: '店舗アカウントの登録に失敗しました',
    186683: 'ユーザー情報を更新できません',
    186684: '該当ユーザーは既に存在します',
    186685: 'このユーザーを連携できませんでした',
    186686: 'ユーザー更新に失敗しました',
    186687: 'ユーザー情報の更新が失敗しました',
    186689: '企業とユーザーの連携削除に失敗しました',
    186690: '企業とユーザーの連携に失敗しました',
    186691: 'ユーザー権限の更新が失敗しました',
    186692: 'ユーザーと初期メッセージの連携に失敗しました',
    186693: 'ユーザーと店舗の連携削除に失敗しました',
    186694: 'ユーザーの連携に失敗しました',
    186695: 'チャネルの更新が失敗しました',
    186696: 'メールサーバーへのアクセスが失敗しました',
    186697: 'しばらくしてから、もう一度お試しください',
    186698: '企業情報の更新が失敗しました',
    186699: 'キャンペーンの更新が失敗しました',
    186676: '払い戻しに失敗しました',
    186674: 'QianFang サーバーにアクセスできません',
    186675: ' 完了した支払い記録は返金できません。',
    186601: '不明なエラーが発生しました',
    186602: 'データの挿入に失敗しました',
    186503: '店舗のオーナーが存在しません',
    186504: '店舗のオーナー番号が間違っています',
    186505: 'キャンペーンの作成に失敗しました',
    186506: 'キャンペーンコードの数が上限を超えています',
    186507: 'このユーザーは既に企業と連携済です',
    186508: '企業の作成に失敗しました',
    186509: '企業情報が必須になります',
    186510: 'この企業には店舗の作成ができませんでした',
    186511: 'ユーザーと店舗を連携できませんでした',
    186512: '権限の作成を失敗しました',
    186513: 'データの更新が失敗しました',
    186514: 'ルールと一致しませんでした',
    186515: '結果と一致しませんでした',
    186516: '更新が失敗しました',
    186599: '情報を見つけることができません',
    186570: 'ファイルが壊れています',
    186571: 'コードが無効です',
    186572: '0-20の間に入っていません',
    186573: '検索条件は必須です。',
    186574: '社員番号は８桁までにしてください',
    186575: '店舗情報が間違っています',
    186576: 'ユーザーは存在しません',
    186577: '審査中のユーザー情報が間違っています',
    186578: 'メールアドレスが間違っています',
    186579: 'トークンが無効もしくは存在しません',
    186580: 'キャンペーンコードの利用回数は0より大きくする必要があります。',
    186581: 'コードサイズが間違っています',
    186582: 'ユーザーが間違っています',
    186583: 'オーダー状態は必須です。',
    186584: 'パラメーターが間違っています',
    186470: 'キャンペーンは存在しません',
    186471: 'チャネルは存在しません',
    186472: 'ユーザーは存在しません',
    186473: 'パスワードは無効です。',
    186474: '有効な支払い口座が連携されていません',
    186560: 'トランザクション番号の作成に失敗しました',
    183675: '残高が足りないため、返金できません。',
    181674: '店舗情報が正しくありません。管理者に連絡してください。',
    181121: '決済記録の挿入に失敗しました',
    186000: 'アップロードするファイルは10MB以下にしてください。',
    187000: '引き出し日付状態テーブルヘの挿入が失敗しました',
    187001: '引き出し日付状態テーブルヘの更新が失敗しました',
    187002: '引き出し履歴テーブルヘの更新が失敗しました',
    187003: 'ステータスコードの受信エラー',
    187100: '取引日付は返金期限を超えます',
    187101: '操作失敗、該当取引はもう返金しました。'
  },
};
