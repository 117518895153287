import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
  modelName: 'SettlementAlOrWx',
  fields: {
    startTime: 'date',
    endTime: 'date',
    enterpriseId: 'number',
    storeId: 'number',
    tradeCount: 'number',
    tradeAmount: 'number',
    serviceFee: 'number',
    currency: 'string',
    settlementAmount: 'number',
    channel: 'string',
    settlementAmount: 'number',
    settlementDate: 'string',
    systemFee: 'number',
    uid : 'number',
  },
  idgen: 'uuid',
})
class SettlementAlOrWx {

}

export default SettlementAlOrWx;
