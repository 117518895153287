import React from 'react';
import LocaleContext from 'component/LocaleContext';

export default function withLocale(Component) {
  return function LocaledComponent(props) {
    return (
      <LocaleContext.Consumer>
        {locale => <Component {...props} {...locale} />}
      </LocaleContext.Consumer>
    );
  };
}
