import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
  modelName: 'Unsettlement',
  fields: {
    startTime: 'date',
    endTime: 'date',
    enterpriseName: 'string',
    storeName: 'string',
    enterpriseKey: 'string',
    storeKey: 'string',
    settlements: 'any',
    // channel: 'any',
    // tradeAmount: 'any',
    // feeRate: 'any',
    // channelFee: 'any',
    // systemFee: 'any',
    // serviceFee: 'any',
    // otherFee: 'any',
    // finalFee: 'any',
  },
  idgen: 'uuid',
})
class SettlementAdmin {

}

export default SettlementAdmin;
