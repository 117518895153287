import invariant from 'invariant';

let id = 0;
class StoreManager {
  stores = {}

  getStore(name) {
    invariant(Reflect.has(this.stores, name), 'not found this Store');
    return this.stores[name];
  }

  register(store) {
    let storeId = store.storeId ? store.storeId : `store-${++id}`;
    this.stores[storeId] = store;
  }

  dispose() {
    for (let k in this.stores) {
      let store = this.stores[k];
      store.dispose && store.dispose();
    };
  }
}
export default new StoreManager();
