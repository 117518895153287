export default {
  l: 'en',
  param: 'en_US',
  lang: 'English',
  langSelection: 'Language Selection',
  brand: 'Merchant Management System',
  searchMore: 'Search For',
  search: 'Search',
  modify: 'Edit',
  delete: 'Delete',
  export: 'Export',
  exportCSV: 'Export CSV',
  exportExcel: 'Export Excel',
  allexport: 'Export All',
  exportOriginal: 'Download Raw Data',
  withdrawalDetail: 'Withdrawal detail',
  all: 'All',
  rests: 'Rests',
  restsChannel: 'Rests Channel',
  complete: 'Complete',
  cancel: 'Cancel',
  notify: 'Notifications',
  add: 'Add',
  back: 'Back',
  logout: 'Logout',
  logoutConfirm: 'Are you sure to exit?',
  submit: 'Submit',
  yes: 'Yes',
  no: 'No',
  moreSearchCriteria: 'More search criteria',
  expand: 'Expand',
  takeUp: 'Collapse',
  storeExpand: 'Detailed store expand',
  storeTakeUp: 'Detailed store collapse',
  psdError: 'The password you entered is incorrect',
  noWebLoginAccess: "Sorry, you don't have permission to log in",
  operator: 'Operation',
  detail: 'Details',
  updatePassword: 'Update Password',
  accountManage: 'Account Management',
  tradeFrom: 'trade',
  store: 'store',
  storeUser: 'shop assistant',
  select: 'The search criteria',
  noAuthority: 'You do not have permission',
  certificateOfIncorporation: 'Certificate Of Incorporation',
  settings: 'Email Notifications',
  tip: 'Tips',
  login: {
    id: 'Login ID',
    idPlaceholder: 'Please enter your email',
    storeownerEmail: 'Manager email',
    psd: 'Password',
    psdPlaceholder: 'Please enter your password',
    workNumber: 'Staff ID',
    workNumberPlaceholder: 'Please enter your staff ID',
    autoLogin: 'Remember me',
    shopkeeperLogin: 'Manager Login',
    employeeLogin: 'Employee Login',
    forgetPsd: 'Forgotten Password?',
    forgetPsdTitle: 'Recover Password',
    submit: 'Log In',
    noLoginIdTip: 'New customer? ',
    registerNow: 'Start here',
    kickOut: 'You have been forced to logout as other user just login the system with the same account! Please contact with other users who may use the same account in your company.',
    rejectInfo: 'Your account request has been rejected, please contact your sales agent to modify information for you!',
    pleaseEnterLoginID: 'Please enter login ID',
    rescindInfo: 'Your merchant has terminated the contract and cannot trade.'
  },
  register: {
    mail: 'Email',
    mailPlaceholder: 'Please enter your email',
    mailPlaceholderStore: 'Store user login ID',
    mailPlaceholderEnterprise: 'Enterprise user login ID',
    defaultPassword: 'The default password is 123456',
    theDefaultPassword: 'The default password',
    psd: 'Password',
    psdPlaceholder: 'Please enter new password',
    psdConfirm: 'Re-enter new password',
    psdConfirmPlaceholder: 'Please enter new password again',
    captcha: 'Captcha code',
    captchaPlaceholder: 'please enter the code',
    termsOfService: 'terms of service',
    privacyRelatedPolicies: 'privacy policy',
    agree: 'Agree to',
    agreeAndSendMail: 'Agree to terms of service and send email to mailbox',
    and: 'and',
    submit: 'Register',
    hasLoginId: 'Have an account',
    toLogin: 'Login',
    oldPsd: 'Old password',
    newPsd: 'New password',
    psdOldPlaceholder: 'Please enter current password',
    prompt: 'Click on the next page to turn the page,After reading, you can click "agree"',
    titlePrompt: 'If you use QFPay for your service, please agree to QFPay terms of service.Please confirm the following contents and click the button of "Agree to terms of service and send email to mailbox" that you agree to use.'
  },
  registerMore: {
    belongToEInfo: 'Company information',
    belongToEName: 'Company name',
    hasEnterprise: 'Is your parent enterprise registered',
    has: 'Yes',
    not: 'No',
    bankInfoRequired: 'Please enter bank information for independent settlement account!',
  },
  registerType: {
    title: 'Please choose your account type',
    single: 'Single Store',
    chain: 'Chain Store',
    personal: 'Personal',
    enterprise: 'Enterprise',
  },
  registerStep: {
    register: 'Basic info',
    moreInfo: 'Account Details',
    waitingReview: 'Approving',
  },
  footer: {
    concatInfo: 'Contact Us',
    concatMail: 'Email',
    concatTel: 'Telephone number',
    businessHours: 'Business hours',
    address: 'Address ：〒105-0001 東京都港区虎ノ門 1-10-5 KDX Toranomon 1 Chome 11F 株式会社QFPay Japan',
    except: 'Weekends and holidays excluded',
    useRole: 'Terms of use',
    privacy: 'Privacy policy',
    companyInfo: 'Company information',
    aboutUs: 'About',
    qfpay: 'QFPay Japan',
    about: 'About',
    naviJapan: 'Navi Japan',
    yayaTraslation: 'Yaya Translation',
    pay: 'Payments',
    supportPay: 'Payment Options',
  },
  nav: {
    homePage: 'Home',
    tranManagemant: 'Transaction Management',
    propManagement: 'Promotion Management',
    promActivities: 'Promotions',
    mercManagement: 'Store Management',
    employeeManagement: 'Employee Management',
    toReview: 'Waiting for review',
    branchStoreManagement: 'Branches',
    subscription: 'Registration information',
    approvedReview: 'Channel Management',
    approvedReviews: 'Exchange Rate Setting',
    withdrawalManagement: 'Withdrawal Management',
    settlementManagement: 'Settlement management',
    deviceManagement: 'Device Management',
    barcodeImage: 'Static QRC',
    settlement: 'Settlement management',
    tranQuery: 'Transaction History',
    updatePassword: 'Update password',
    advertiseManage: 'Advertisement Management',
    userManagement: 'User management',
    exceptionOrder: 'Exceptional Transactions',
    settingsManage: 'Email Management',
    disposeBarCode: 'Configurable QR Code',
    acdExchangeRate: 'ACD exchange rate',
    internationalExchangeRate: 'INT Exchange Rate',
    savvyPoint: 'Point',
    pointBillManagement: 'Integral Bill Management',
    exchangeRateManagement: 'Exchange Rate Management',
  },
  permissions: {
    loginAccess: 'Login Access',
    tranManagemant: 'Transaction Mgt',
    propManagement: 'Promotion Mgt',
    mercManagement: 'Store Mgt',
    deviceManagement: 'Device Mgt',
    QFPayPlus: 'QFPay+'
  },
  dashboard: {
    scanIncome: "Today's GMV",
    incomeNumber: 'Total Transactions',
    notice: 'New Notices',
    noticeCount: 'messages',
    incomeOfMonth: 'Past 30 Days’ GMV',
    currentPromot: 'Current Promotions',
    notAvailable: 'Not available',
    incomeStat: 'Income statistics',
    storeIncomeStat: 'Income statistics of stores',
    lastMonth: 'Last 30 Days',
    lastWeek: 'Last Week',
    totalIncome: 'Total GMV',
    refundsAmount: 'Total No. of Refunds',
    paymentChannel: 'Payment Channels:',
    count: 'Transactions',
    amount: 'GMV(JPY)'
  },
  user: {
    code: 'Internal Code',
    employeeCode: 'Employee Internal Code',
    code_p: 'Please enter his/her internal code',
    name: 'Name',
    name_p: 'Please enter his/her name',
    permission: 'Access Rights',
    permissionFilters: 'Access Filters',
    concatInfo: 'Contact Number',
    concatInfo_p: 'Please enter concat information',
    operate: 'Operation',
    password: 'Login Password',
    password_p: 'Please enter login password',
    deleteInfo: 'Are you sure to delete the employee ({code})?',
    addEmployee: 'Add Employee',
    editEmployee: 'modify an employee',
    tip: 'Tips',
    printerId: 'Printer Number',
    payDeviceId: 'Device ID',
    bindDevice: 'Device Binding',
    deviceIdDuplicate: 'The employee has successfully unbundled equipment.',
    unbind: 'unbind',
    export: 'Export',
    userInfo: 'Employee Information Sheet',
    downloadQRCode: 'Download QR code',
    storeBrCode: 'Download the store QR code',
    addBrCode: 'Add QR code',
    clickToDownloadQRCode: 'Click to download the static payment qr code',
    formerDeleteUser: 'Please unbind the equipment',
    branchStoreName: 'Branch Name',
    userName: 'User name',
    addUser: 'Add a user',
    editUser: 'Edit a user',
    id: 'Login ID',
    id_p: 'Please enter your login id',
    deleteInfo1: 'You are sure to delete the user({code})？',
  },
  guide: {
    title: 'Gentle Reminder',
    hidden: 'Don’t show this notification again',
    know: 'Got it',
    BrCode1: 'Static QR Code Function has been redesigned',
    BrCode2: 'You can now create a designated static QR Code which is assigned to your employees.',
    BrCode3: 'Click ‘Add QR Code’ to add a new employee. To download the designated QR Code, simply click on the QR Code logo under the ‘Operations’ column.',
    User1: 'You can now control access given your to employee using Employee Management. To manage access control, click on “…” under Operation column.',
    User2: 'You can also search for employees using access control filters which are grouped by functions.',
    User3: '',
    device1: 'The device management page has been optimized for user experience.',
    device2: 'This function has been upgraded.',
    device3: 'You can now manage the following devices:',
    device4: '1.QM800 (QFPay)',
    device5: '2.Sunmi',
    device6: '3.Other Android Devices'
  },
  device: {
    deviceId: 'Device ID',
    storeName: 'Store Name',
    importDeviceId: 'Please enter Device ID',
    importStoreName: 'Please enter Store Name',
    bindingState: 'Is device bound already?',
    all: 'All',
    yes: 'Yes',
    no: 'No',
    add: 'Add Device',
    softwareVersion: 'Software Version',
    bindingStore: 'binding Store',
    operate: 'Operation',
    binding: 'Binding',
    unbind: 'Unbind',
    delete: 'Delete',
    tip: 'Hint',
    deleteInfo: 'Are you sure you want to delete the device(deviceId{code})？',
    unbindInfo: 'Are you sure you want to unbind this device？',
    edit: 'Edit',
    remark: 'Remark',
    addError: 'Operation failed, this device number already exists!',
    staff: 'Employee Name',
    bindingStaff: 'Device Binding',
    comment: '※Record the detailed information of the equipment in the notes, and label the equipment easy to distinguish.',
    deviceType: 'Device Type',
    hardware: 'Conventional POS',
    android: 'Android Devices',
    formerDeleteUser: 'Please unbind the equipment',
    AndroidDevicesBinding: 'Android devices must be tied to the store',
  },
  access: {
    APP_LOGIN: 'Login to App',
    WEB_LOGIN: 'Login to Management System',
    SUPER_ADMIN: 'SUPER_ADMIN',
    ENTERPRISE_OWNER: 'ENTERPRISE_OWNER',
    STORE_OWNER: 'STORE_OWNER',
    MANAGE_EMPLOYEE: 'Employee Management',
    MANAGE_PROMOTION_CAMPAIGN: 'Manage Promotions',
    TRADE_QUERY: 'All Transaction History',
    TRADE_STORE_QUERY: 'Store Transaction History',
    SETTLEMENT_MANAGEMENT: 'Settlement management',
    WITHDRAWAL_MANAGEMENT: 'Withdrawal Management',
    PAY_BARCODE: 'Generate Static QRC',
    CONTRACT_INFO: 'Registration Information',
    STORE_DEVICE_MANAGEMENT: 'Device Management',
    ENTERPRISE_DEVICE_MANAGEMENT: 'Device management',
    ENTERPRISE_USER: 'User management',
    MANAGE_BRANCH: 'Branch Management',
    ORDER_REFUND: 'Process Refund',
    FIXED_AMOUNT_QR_CODE: 'Configurable QR code'
  },
  receivables: {
    code: 'Number',
    originTradeNo: 'Original Transaction ID',
    storeId: 'Store ID',
    storeName: 'Store Name',
    storeAddress: 'Store Address',
    promotionName: 'Promotion Name',
    originAmount: 'Original Transaction Amouint',
    serviceCashing: 'Transaction Fees',
    netAmount: 'Settlement Amount',
    cashier: 'Cashier ID',
    tragePeriod: 'Transaction Period',
    trageTime: 'Transaction Time',
    businessWaterNumberTitle: 'Transaction ID',
    null: 'none',
    TradingTime: 'Transaction Time',
    initTradingTime: 'Initial Transaction Time',
    currency: 'Currency',
    finalTotalAmount: 'Order amount',
    payMethod: 'Transaction Method',
    payMethodExplain: '<p style="text-align:left;font-size:14px;">Transaction Method:</p><p style="text-align:left;font-size:14px;">1.Customer scans merchant’s dynamic QR code</p><p style="text-align:left;font-size:14px;">2.Merchant scans customer’s dynamic QR code</p><p style="text-align:left;font-size:14px;">3.Customer scans merchant’s static QR code and input payment amount</p><p style="text-align:left;font-size:14px;">4.Customer scans merchant’s fixed-amount static QR code</p><p style="text-align:left;font-size:14px;">5.Payment in WeChat Applet</p>',
    amount: 'Transaction Amount',
    amountDetails: 'Gross Transaction Amount',
    operateAccount: 'Cashier',
    storeDeviceId: 'Device ID',
    trageChannel: 'Payment Channel',
    promotionType: 'Promotion Type',
    orderStatus: 'Status',
    operate: 'Operation',
    refund: 'Refund',
    branchStoreName: 'Branch Name',
    refundChannel: 'Refund channel',
    canRefundMoney: 'Refundable amount',
    refundMoney: 'Refund amount',
    paySuccess: 'Payment Success',
    payFailure: 'Payment Failed',
    refunded: 'Refund Success',
    refundFailure: 'Refund Failed',
    refundFailureState: 'Refund failure,Please contact the customer service,TEL:0120-501-080',
    refundSuccess: 'The refund application has been successful. The user will receive a refund notice sent by ${wallet}',
    orderClose: 'Incomplete Order',
    tradeAmount: 'Transaction Amount',
    offerType: 'Promotion Type',
    noCampaign: 'No Campaign',
    businessWaterNumber: 'Transaction ID',
    businessWaterNumberExplain1: '<p style="text-align:left;font-size:14px;">Transaction ID:</p><p style="text-align:left;font-size:14px;">${channel} Transaction ID</p>',
    businessWaterNumberExplain2: '<p style="text-align:left;font-size:14px;">Transaction ID(QFPay):</p><p style="text-align:left;font-size:14px;">QFPay Transaction ID</p>',
    issued: '(QFPay)',
    enterpriseLoginId: 'Enterprise ID',
    storeLoginId: 'Store ID',
    businessWaterNumberBranchStoreNameAdmin: 'Please enter Transaction ID / Login ID / Cashier ID / Device ID',
    businessWaterNumberBranchStoreName: 'Please enter Transaction Id/Branch name',
    businessWaterNumberOperateAccount: 'Please enter Transaction Id/Cashier/DeviceId',
    transNumber: 'Transaction Count',
    transMoney: 'GMV',
    comment: 'Remark',
    discounts: 'Coupon',
    rate: 'MDR',
    rateUnit: '(%)',
    settlementTime: 'Transaction Completion Time',
    activityName: 'Promotion Name',
    choose: 'Choose Promotion',
    chooseStores: 'Select stores',
    chooseHint: 'Select the activity to add to the search criteria',
    scanType: {
      QR: 'Customer scan dynamic QR code of merchant ',
      Scan: 'Merchant scan dynamic QR code of customer',
      StaticQR: 'Static QR code with customer input amount',
      Applet: 'Applet payment',
    },
    clickToAddOrRemove: 'Click the store name to add or remove',
    selectedStore: 'Selected stores',
    chooseDevice: 'Select device',
    chooseDeviceHint: 'Please select device number to add to search criteria',
    chooseColumn: 'Select export',
    columns: {
      TradeNumber: "Trade Number",
      OriginalTradeNumber: "Original Trade Number",
      ChannelTransactionId: "Channel Transaction Id",
      TradeTime: "Trade Time",
      Currency: "Currency",
      Amount: "Amount",
      TransactionFee: "Transaction Fee",
      Netamount: "Net amount",
      BankingOrg: "Channel",
      Status: "Status",
      StoreId: "Store ID",
      StoreName: "Store Name",
      Operator: "Cashier",
      CashierNumber: "Cashier Number",
      StoreDeviceId: "Store Device Id",
      payMethod: "Specific payment method",
      CampaignType: "Promotion Type",
      CampaignName: "Promotion Name",
      AmountbeforeCampaign: "Amount before Promotion",
      Remark: "Remark",
      Poundage: "Rate",
      SettlementDate: "Period Of Settlement",
      PointAmount: 'Consumption points',
      Deduction: 'Deduction amount',
      OriginalAmount: 'Amount before deduction'
    },
    savvyPoint: 'Savvy point',
    pointAmount: 'Consumption points',
    deduction: 'Deduction amount',
    originalAmount: 'Amount before deduction',
    pointRefundSuccess: 'The refund application has been successful.',
    exchangeAmount: 'Actual payment amount',
    exchangeCurrency: 'Actual payment currency',
  },
  exceptionOrder: {
    exceptionStatus: 'Exceptional Status',
    newCreate: 'New',
    solveSuccess: 'Resolve Success',
    solveFailure: 'Resolve Failure',
    manualSolution: 'Awaiting Manual Fix',
    retryTimes: 'Retry Count',
    MarkAsSolve: 'Mark as solve',
    askToSolve: 'Are you sure you want to mark this exception order as resolved?',
  },
  transaction: {
    tabName: 'Trade history',
  },
  campaign: {
    activityTime: 'Promotion Period',
    activityType: 'Promotion Type',
    activityStatus: 'Promotion Status',
    activityName: 'Promotion Name',
    inProcess: 'Ongoing',
    terminated: 'Ended',
    unStart: 'Not Started Yet',
    activityRange: 'Owner',
    operate: 'Operation',
    inputName: 'Please enter promotion name',
    startAt: 'Start Time',
    endAt: 'End Time',
    downloadQRCode: 'Download QR code',
    QRCodeCount: 'Generate Quantity',
    QRCodeSize: 'QR Code Size',
    codeCanUseTime: 'Redeem Limit Per Code',
    codeCanUseTimePlaceholder: 'total times',
    unlimited: 'Unlimited',
    customQuantity: 'Quantity',
    nextStep: 'Next',
    lastStep: 'Previous',
    overlayUse: 'Valid With Other Promotions',
    modifyActivityInfo: 'Modify activity information',
    activityValidity: 'Effective period',
    activityAmount: 'JYP-Amount Off',
    remark: 'Remarks',
    pleaseChooseType: 'Select Promotion Type',
    pleaseInputActivityContent: 'Customize Promotion',
    release: 'Confirm',
    selectType: 'Type',
    inputContent: 'Customize',
    activityRelease: 'Complete',
    giftName: 'Gift name',
    giftPhoto: 'Gift photo',
    off: 'Fixed Amount Discount',
    new: 'Create Promotion',
    allBranch: 'All parts of the store',
    usedTimess: 'Total Redeemed',
  },
  barcode: {
    comment: 'The received qr code is a static qr code, which can be downloaded and printed and placed in your store. The customer will scan the code to make payment. '
  },
  branch: {
    SettlementExplain1: '<p style="text-align:left;font-size:14px;">Independent settlement stores:</p><p style="text-align:left;font-size:14px;"> Transaction balances are not counted as corporate balances and are settled,</p><p style="text-align:left;font-size:14px;">withdrawn and credited directly via QFPay.</p><p style="text-align:left;font-size:14px;">Remittance will be made to a separate bank account registered in the management system.</p>',
    SettlementExplain2: '<p style="text-align:left;font-size:14px;">Non-independent payment stores:</p><p style="text-align:left;font-size:14px;">In case of normal payment stores of QFpay system,</p><p style="text-align:left;font-size:14px;">the non-independent payment stores will be displayed uniformly under the company ID balance,</p><p style="text-align:left;font-size:14px;"> and the operation of payment and cash withdrawal will be done with the company ID.</p><p style="text-align:left;font-size:14px;">QFPay will transfer the requested amount directly to the bank account of a company registered in the QFPay system.</p>',
    SettlementType1: 'Independent payment',
    SettlementType2: 'Non-independent payment',
    uid: 'Store ID',
    createAt: 'Creation Time',
    status: 'Review status',
    auditSuccess: 'Passed',
    auditing: 'Under review',
    auditFailure: 'Rejected',
    rescind: 'Rescind',
    name: 'Name',
    type: 'Non-independent or Independent ',
    code: 'Code',
    address: 'Address',
    storeStatus: 'Status',
    mail: 'E-mail',
    tel: 'Telephone number',
    operate: 'Operation',
    view: 'View',
    addStore: 'New branch',
    resubmit: 'Resubmit',
    storeInfo: 'Branch detail',
    accountInfo: 'Account detail',
    bankInfo: 'Bank Information',
    didShowBankInfo: 'Whether to display the bank information',
    didFillingBankInfo: 'Whether to fill in the bank information',
    fillExplain: 'Whether to fill in the bank information for this branch.',
    showExplain: "Whether to display the branch's bank information in the signing information when the branch user logs in, or display the enterprise's bank information if you choose not to display.",
    bankCode: 'Bank Code',
    remark: 'Remark',
    waitingTip: 'Your application is under review. We will finish it within 1-2 weekdays.',
    failureTip: 'Sorry, some of submitted information didn\'t pass review. Please correct and resubmit',
    loginEmail: 'This mail is the login ID of the current branch',
    attachedStore: 'Attached Store'
  },
  branchAuditStatus: {
    NORMAL: 'normal',
    TO_REVISE_MATERIAL: 'rejected',
    AWAITING_REVIEW: 'pending review',
    RESCIND: "rescind"
  },
  pendingReviewList: {
    registerTime: 'Registration Time',
    userID: 'User ID',
    userType: 'User Type',
    registerType: 'Registration Type',
    lastUpdateTime: 'Last update time',
    operate: 'Operation',
    audit: 'Review',
    not: 'No',
    reviewDate: 'Last review date',
    searchOther: 'User ID',
    enterpriseName: 'Enterprise Name',
    storeName: 'Store Name',
    auditStatus: 'Audit Status Info.',
    auditing: 'Information Waiting for audit',
  },
  registerTypeConst: {
    SELF_REGISTERED: 'Self-Registered',
    ENTERPRISE_REGISTERED: 'Registered by parent enterprise',
  },
  userTypeConst: {
    SINGLE: 'Store',
    STORE_USER: 'Corporate branch',
    ENTERPRISE_USER: 'Business',
    INCOMPLETE_REGISTRATION: 'Not filled in information',
  },
  AttachedUserTypeConst: {
    SINGLE: 'Store',
    STORE_USER: 'Corporate attached branch',
    ENTERPRISE_USER: 'Business',
    INCOMPLETE_REGISTRATION: 'Not filled in information',
  },
  reviewDetail: {
    revoke: 'cancel',
    reject: 'refuse',
    storeInfo: 'Store Information',
    storeName: 'Store Name',
    storeAddress: 'Store Address',
    storeEmail: 'Email',
    isDevicePurchase: 'Whether you need to buy equipment',
    storeTel: 'Telephone Number',
    bankInfo: 'Bank Information',
    bankInfoOld: 'Original bank information',
    didFillingBankInfo: 'Whether to fill in the bank information',
    fillExplain: 'Whether to fill in the bank information for this branch.',
    showExplain: "Whether to display the branch's bank information in the signing information when the branch user logs in, or display the enterprise's bank information if you choose not to display.",
    bankCode: 'Bank Code',
    bankName: 'Bank Name',
    branchBankCode: ' Branch Code',
    branchBankName: ' Branch Name',
    accountType: 'Account Type',
    accountName: 'Account Name',
    accountCode: 'Account Code',
    settlementCurrency: 'Currency',
    didIndependentSettlement: 'Settle indepently from the headquarter?',
    ok: 'Pass',
    refuse: 'refuse',
    audit: 'Waiting for audit',
    auditBankInfo: 'Bank information to be reviewed'
  },
  withdrawalManagement: {
    withdrawalTime: 'Withdrawal Application Date',
    searchOther: 'Store',
    status: 'Withdrawal Status',
    toPay: 'To Pay',
    ToBeProcessed: 'Waiting',
    paidAlready: 'Paid',
    withdrawalOrderNum: 'Withdrawal Order Number',
    enterpriseName: 'Enterprise Name',
    storeName: 'Store Name',
    paymentChannel: 'Payment Channel',
    withdrawalAmount: 'Withdrawal Amount',
    pay: 'Pay',
    notSelectRecord: 'Please selected at least one record to export!',
    notSelectPayRecord: 'Please selected at least one record to pay!',
    confirmToPay: 'Are you sure to pay these records?',
    startTodealwith: 'Mark as Processing',
    intheprocessing: 'Processing',
    notSelectTodealwithRecord: ' Please select to be processed records!',
    confirmTodealwith: 'Are you sure to start processing?',
    withdrawalNum: 'No. of Withdrawals',
    confirmIsPay: 'Mark as Paid',
    withdrawalApply: 'Withdrawal Apply',
    withdrawalData: 'Withdrawal of raw data',
    todayTip: 'Now the exported data is up to the current time, do you want to continue?',
    todayTip2: 'Merchants may apply for withdrawal after this, please note',
    warningTitle: '<h4>Withdrawal Amount Alert</h4><table><tr><td>Enterprise Name</td><td>Store Name</td><td>Withdrawal Date</td><td>Withdrawal Amount</td><td>Last Withdrawal Amount</td></tr>',
    logErrorTitle: '<h4>Channel Log Error Info</h4><table><tr><td>EnterpriseName</td><td>StoreName</td><td>Now the balance</td><td>The original balance</td><td>Find the time</td></tr>',
    logErrorTip: 'Channel Log Error Info',
    logErrorText: '※If there is any unhandled pipeline exception, please contact the technical team to solve it',
    tableHeader: {
      enterpriseName: 'EnterpriseName',
      storeName: 'StoreName',
      currentCashing: 'Now the balance',
      originalCashing: 'The original balance',
      createDate: 'Find the time'
    },
    withdrawalTotal: 'Total Withdrawal Amount',
    withdrawalBalance: 'Amount remaining after withdrawal'
  },
  withdrawal: {
    withdrawalTime: 'Withdrawal Application Period',
    withdrawalAmount: 'Balance',
    submitWithdrawal: 'Request for Withdrawal',
    setAutomaticWithdrawTime: 'Set Auto Withdrawal Time',
    withdrawalChannel: 'Pay Channel',
    balance: 'Balance',
    balanceAfterWidthdrawal: 'Balance After Withdrawal',
    withdrawalTitle: 'Request For Withdrawal',
    withdrawalAutoTitle: 'Set Frequency of Automatic Withdrawal',
    inputWithdrawalAmount: 'Withdrawal Amount',
    autoWithdrawalDays: 'Days',
    autoWithdrawalDaysExceedMaxDays: 'The automatic withdrawal period should be less than 1000 days！',
    balanceNotEnough: 'Balance is not enough!',
    logError: 'The balance flow is abnormal, please contact customer service',
    minBalanceEnough: 'Please set the withdrawal amount at 1,000 yen or above!',
    comment: 'Balance is the amount available for withdrawal.',
    warn: 'Do note that there is a minimum withdrawal amount of 1,000 yen (applies for automated withdrawals). No interbank transfer fee for withdrawals above 200,000 yen.',
    warn1: 'Withdrawal of more than 5000,000 yen is free of inter-bank remittance service charges.',
    tip: 'Withdrawal Alert',
    remark: 'The withdrawal balance and automatic withdrawal function have not been officially used, please look forward to it!',
    mincashCycle: 'The automatic withdrawal period shall not be less than seven days!',
    week: 'Weekly',
    twoWeek: 'Bi-weekly',
    month: 'Monthly',
    close: 'Turn off Automatic Withdrawal',
    explain: 'Instructions',
    weekExplain: 'Automated withdrawal request submitted every Monday',
    twoWeekExplain: 'Automated withdrawal request submitted every other Monday',
    monthExplain: 'Automated withdrawal request submitted on the first day of every month',
    closeExplain: 'Deactivate all automatic withdrawal',
    remind: 'Interbank transfer fees apply for withdrawals under 5000,000 yen.',
    noPermissions: 'you don’t have authority to withdraw, please sign in with a business account'
  },
  advertising: {
    pleaseEnter: 'Please enter Campaign Name',
    name: 'Advertisement Name',
    nameAdd: 'Campaign Name',
    nameOf: 'Name of Campaign:',
    imageUrl: 'Images',
    promotionalImages: 'Promotional Images',
    printTimes: 'Current Print Count',
    operate: 'Operate',
    distribution: 'Distribution',
    addAdvertise: 'Add New Advertisement',
    editAdvertise: 'Edit advertisement',
    CampaignStartDate: 'Campaign Start Date',
    CampaignExpirationDate: 'Campaign End Date',
    startDate: 'Start Date',
    expirationDate: 'End Date',
    maxPrintTimes: 'Max Print Limit',
    cannotDelete: 'This advertisement has been assigned to the store for use. Please remove it after the distribution!',
    confirmToDelete: 'Are you sure to delete this advertisement?',
    uploadPicture: 'Please upload the advertisement picture!',
    status: 'Status',
    normal: 'Active',
    stop: 'Inactive',
    enterpriseOrStoreName: 'Enterprise Name or Store Name',
    enterpriseOrStoreNameEnter: 'Please enter Enterprise Name or Store Name',
    storeName: 'Store Name',
    haveNum: 'Existing advertising quantity',
    printNum: 'Current Print Count',
    addAdvertiseToStore: 'Add to Store',
    AdvertiseDisabled: 'This advertisement is invalid',
    threeError: 'this store has 3 enabled ads, operation failed',
    details: 'View',
    enable: 'Enable',
    disable: 'Disable',
    advertiseManage: 'Advertisement Management',
    advertiseAllotDetails: 'Campaign Distribution Details',
    storeAdvertiseManage: 'Store advertising management',
    printTimeError: 'The total number of times of printing has reached the maximum. Please modify the advertising information and try again',
    pastDueError: 'This advertisement has expired, please modify the advertisement information and try again',
    allPrintTimes: 'Total number of print',
    storePrintTimes: 'The number of times this store prints',
    return: 'Go back to the previous page',
  },
  internationalExchangeRate: {
    meiyuan: 'Dollar',
    riyuan: 'Japanese Currency',
    taizhu: 'Thai Baht',
    paymentCurrency: 'Payment Currency',
    converter: 'Currency Exchange',
    nationalFlag: 'National Flag',
    abbreviation: 'English Abbreviations',
    currency: 'Clearing Currency',
    qfpayExchangeRate: 'QFPAY Exchange Rate',
    createTime: 'Create Time',
    operation: 'Operation',
    chooseAll: 'Select All',
    update: 'Modify',
    history: 'Historic Records',
    search: 'Search',
    name_p: 'Please Enter',
    editAdvertise: 'Edit',
    addAdvertise: 'Add',
    goBack: 'Go Back',
    updateBy: 'Modified By',
    qfpayRateCheckText: 'Up to 4 digits after the decimal point, rounded up',
    qfpayRateCheckTextLength: 'Maximum 8 digits',
    qfpayRateExist: 'The exchange rate cannot be empty and greater than 0',
  },
  modal: {
    title: 'Tips',
    primaryButtonText: 'OK',
    secondaryButtonText: 'Cancel',
    errorTitle: 'There is error',
    confirmPrimaryButtonText: 'Confirm',
    confirmSecondaryButtonText: 'Cancel',
    systemErrorMessage: 'There is a system error!',
    systemErrorDashboard: 'Home page statistics are abnormal, will be repaired later, thank you!',
    downPage: 'The next page'
  },
  validator: {
    required: 'Required',
    number: 'Please enter the correct password',
    percentageNumber: 'Please enter the correct percentage (two decimal at most)',
    email: 'please enter your vaild email',
    len: 'The string you entered is too long',
    len1: 'The password must contain letters and numbers with a length of 6-8 digits',
    integer: 'Please enter an integer',
    halfChar: 'Please enter half - horn kana, half - horn space, half - horn parenthesis.',
    samePwd: 'Please make sure two passwords are same.',
    existEmail: 'The email has been registered, please change another one',
    unExistUser: 'User does not exist or password error',
    existWorkNumber: 'The internal code has been used. Please input again!',
    receivable: {
      refoundAmountTooHight: 'The refund amount cannot be higher than the refundable amount',
      refoundAmountGtZero: 'The refund amount must be greater than 0',
      refoundAmountInvalidDecimal: 'The refund amount is kept in two decimal places',
      max_l_8: 'No more than 8 digits',
      max_l_100: 'No more than 100 digits',
    },
    positiveNumber: 'Please enter a positive number',
    noBindingChannels: 'No binding channles',
    existImageName: 'The name of the advertisement has already been used. Please enter it again',
    maxLength: 'Enter a maximum of ten digits',
    existLoginId: 'The login ID is already in use. Please enter it again!',
    maxUploadSize: 'The size of each image cannot exceed 1M',
    invalidUploadExtensions: 'Upload files should be in a legal image format',
    invalidUploadExtensionsAndPdf: 'Upload files should be in legal image format or PDF format',
    exchangeRate: 'Please enter the correct exchange rate, up to 8 decimal places.'
  },
  enterprise: {
    null: 'None',
    tip: 'If you need to modify any information below, please contact customer service.Email:info@qfpay.info',
    applyForChannel: 'Channel Application',
    registeredChannelInfo: 'Approved Payment Channels',
    applyChannelInfo: 'Pending Payment Channels',
    toApplyNewChannel: 'Apply for a new channel',
    checking: 'Current account',
    savinngs: 'Normal account',
    waitingReview: 'Waiting for audit...',
    parentheses: ')',
    refused: 'Refused',
    editTo: '(Modified to：',
    newUpload: 'The latest upload',
    not: 'null',
    lease: 'lease',
    buy: 'buy',
    notBuy: 'not buy',
    applyInAttachedStore: 'Tip: Apply for new channels in affiliated stores',
    bankTypeEnum: {
      BANK: 'BANK',
      FINANCIAL_COOPERATIVE: 'FINANCIAL_COOPERATIVE',
      CREDIT_COOPERATIVE: 'CREDIT_COOPERATIVE',
      AGRICULTURAL_COOPERATIVE: 'AGRICULTURAL_COOPERATIVE'
    },
    branchTypeEnum: {
      BRANCH_STORE: 'BRANCH_STORE',
      HEAD_OFFICE: 'HEAD_OFFICE',
      FIELD_OFFICE: 'FIELD_OFFICE',
      BRANCH_OFFICE: 'BRANCH_OFFICE'
    },
  },
  channel: {
    storeId: 'Store ID',
    id: 'Channel ID',
    qf: 'QFPay',
    channel: 'Source',
    type: 'Payment Channel',
    currency: 'Currency',
    rate: 'MDR',
    rateUnit: '%',
    withdrawCycle: 'Withdrawal cycle',
    withdrawCycleUnit: 'In days',
    withdrawAmount: 'The largest withdrawal amount per time',
    withdrawAmountUnit: 'In units of cent',
    applySuccess: 'Your application has been submitted, please wait for approvement',
    comment: 'Remarks',
    applicationDate: 'Application Date',
    rejectReason: 'Rejection Reason',
    typeEnum: {
      ALIPAY: 'Alipay',
      WECHAT: 'WeChat',
    },
    poundage: 'MDR(%)',
    language: 'en',
    merchcomment: 'Only QFPay payment channels support to open account automatically, while you have to input payment channel id for other channels.',
    whetherToUse: 'Whether to disable',
    hasBeendisabled: 'Has been disabled',
    whetherToPass: 'Registration Status',
    ok: 'Pass',
    refuse: 'Refuse',
    applyFailure: 'Your application failed. Please try again later',
    whetherSettlement: 'whether to settlement',
    settlement: 'yes',
    NoSettlement: 'no',
    deviceNum: 'pass Code',
    iSORate: 'First-tier agency rates',
    iSVOneRate: 'Second-tier agency rates',
    iSVTwoRate: 'Third-tier agency rates',
    agentRate: 'Salesperson rates',
    channelRate: 'Channel-side rates',
    qfpayRate: 'QFPay rates'
  },
  approvedReview: {
    pendingReviewChannel: 'Pending Channel',
    registeredChannel: 'Approved Channel',
    reviewDate: 'Review date',
    searchOther: 'User ID',
    feeRate: 'Rate',
  },
  tips: {
    confirmToDelete: 'Are you sure you want to delete it?',
    needToReWrite: 'The application is rejected, please contact the user to re-fill the information',
    noPremission: 'This account does not have permission',
    reviewInEmail: 'Please confirm in your email and change the password.',
    modifySuccess: 'Password has been updated successfully',
    channelExist: 'This channel has been approved for the user and can not be added again.',
    confirmPassed: 'Are you sure you want to pass it?',
    rejectStore: 'Refuse the application',
    actionSuccess: 'Your request has been executed successfully!',
    storeAccount: 'Please use the storeOwner\'s account to log in',
    cannotDelete: 'The device is bound to the user. Please remove it after unbinding',
    actionFail: 'Your request is fail!',
    ErrTime: 'The end time should not be earlier than the start time!',
    selectChannel: 'Please select trading channels',
    maxUpload: 'Up to nine images can be added!',
    maxRate: 'The discount cannot exceed 100%!',
    selectDevice: 'Please select a payment device number!',
    selectCampaigns: 'Please select activity',
    lackJurisdiction: 'There\'s no permission to withdraw money!',
    noMchid: 'The merchant has no channel payment Id, please fill in and apply again',
    openAccountFail: 'Successful data saving, failed call interface, error code and information are as follows:${errmsg}',
    noEnterMchid: 'Successful data saving, failed call interface',
    selectOrderState: 'Please select Order status',
    maxAmount: 'Up to four cc mailboxes can be added!',
    auditFail: 'The store audit failed, please try again later!',
    maxAmount: 'Up to four cc mailboxes can be added!',
    auditFail: '店铺审核失败，请稍后重试！',
    appliFeerateRule: 'The rate must meet the following conditions: Store rate >= direct top agency rate,Lower organization rate>= Upper organization rate,Channel rates are lower than agency rates.',
    curFeerate: 'The current rates are as follows:Store rates:${feeRate},Channel rate:${channelRate}',
    iSORateTip: ',First-tier agency rates：${iSORate}',
    iSVOneRateTip: ',Second-tier agency rates：${iSVOneRate}',
    iSVTwoRateTip: ',Third-tier agency rates：${iSVTwoRate}',
    agentRateTip: ',Salesperson rates：${agentRate}'
  },
  message: {
    readed: 'Read',
    unread: 'Unread',
    readAll: 'View all',
    noMessage: 'No Messages',
    noNewMessage: 'No news for the time being'
  },
  pagination: {
    prev: 'previous',
    next: 'next',
    each: 'per page',
    total: 'total',
  },
  currency: {
    c: 'CNY',
    e: 'USD',
    j: 'JPY',
  },
  bank: {
    ALIPAY: 'Alipay',
    WECHAT: 'WeChat',
    UNKNOWN: 'Unknown',
    ZERO: 'Zero',
  },
  settlement: {
    amountInThisMonth: 'The settlement amount for this month',
    pay: 'Pay',
    status: 'Settlement status',
    status_unsettlement: 'Unsettled',
    status_settled: 'Settled',
    settlementTime: 'Period Of Settlement',
    payeeName: 'Account Name',
    payeeAccount: 'Account Code',
    payeeBankName: 'Bank Name',
    bankBranch: 'Branch Name',
    channel: 'Channel',
    tradeAmount: 'Total Income',
    feeRate: 'Rate',
    channelFee: 'Transaction Fee',
    systemFee: 'System Fee',
    serviceFee: 'Service Fee',
    otherFee: 'Other Fee',
    finalFee: 'Settlement Amount',
    enterpriseName: 'Enterprise Name',
    storeName: 'Branch Name',
    doSettle: 'Settlement',
    userType: 'Target Type',
    settlementRange: 'Period of settlement',
    settlementFee: 'Expected Settlement Amount',
    settlementInfo: 'Settlement info',
    settlementSuccess: 'Settled',
    doSettleTime: 'Time of Settlement',
    operator: 'Operator',
    exportUnsettlement: 'Export auto-transfer file',
    transferDate: 'Transfer date',
    settlementchannel: 'The liquidation Channels',
    transNumber: 'Count',
    amount: 'Amount',
    actualarrivalamount: 'Settlement Amount',
    export: 'Details Export',
    settlementDetail: 'Liquidation Detail Table',
  },
  QFPayMore: {
    develop: 'QFPay+ is under construction and not currently available.',
    wish: "You surely don't want to miss it.",
  },
  updateSettings: {
    setting: 'Set Frequency',
    storeName: 'Store Name',
    cycle: 'Frequency',
    mail: 'Email',
    copyMail: 'CC',
    all: 'All',
    time: 'Each',
    day: 'Daily',
    week: 'Weekly',
    month: 'Monthly',
    close: 'Not Required',
    save: 'Save',
    condirm: 'Confirm to change mail Settings?',
    tips1: 'Select frequency to receive payment and refund transaction details via email for your convenience:',
    tips2: '•[Each] – Every transaction',
    tips3: '•[Daily} – Previous day transactions',
    tips4: '•[Weekly] – Past 1 week transactions',
    tips5: '•[Monthly] – Past 1 month transactions',
    tips6: '•[Not Required] – Not required',
    enterprise: 'Enterprise',
    store: 'Store',
    merchantName: 'Merchant Name',
    mercahntMail: 'Mercahnt Mail',
    enterpriseName: 'Enterprise Name',
    operate: 'Operation',
    exchangeRate: 'Exchange Rate',
    baseInfo: 'Shop basic information',
    signStatus: 'Signing status',
  },
  disposeBarCode: {
    title: 'Title',
    createDate: 'Date',
    dateRange: 'Date Range',
    amount: 'Amount',
    comment: 'Comment',
    operate: 'Operate',
    edit: 'Edit',
    detail: 'Detail',
    delete: 'Delete',
    create: 'New Payment Code',
    title_P: 'Please enter title',
    amount_P: 'Please enter the amount',
    comment_P: 'Please enter comment',
    barcodeTip: 'The collection QR code is a static QR code with fixed amount, which can be downloaded and printed and placed in your store. It is used when the customer actively scans the code for payment.',
    deleteTip: 'Are you sure to delete the QR code?'
  },
  acdExchangeRate: {
    updateTime: 'Update Time',
    virtualCurrency: 'Virtual currency',
    before: 'Exchange rate before update',
    after: 'Exchange rate after update',
    updateBy: 'Updated by',
    edit: 'Updated exchange rate',
    nowRate: 'ACD current exchange rate',
    newRate: 'ACD new exchange rate',
  },
  savvyPoint: {
    enterpriseName: 'Enterprise Name',
    storeName: 'Store Name',
    address: 'Address',
    phoneNumber: 'Phone Number',
    mail: 'Mail',
    openPoint: 'Open Integral',
    openTip: 'You are opening points servise. Please confirm this operation',
    intoSavvy: 'Enter Savvy management points',
    billMonth: 'Bill year',
    billAmount: 'Bill amount',
    billNum: 'Order number',
    paymentDeadline: 'Latest payment date',
    statementDate: 'Statement date',
    payStatus: 'Payment status',
    status: 'Status',
    hasOpen: 'Opened',
    notPay: 'Unpaid',
    hasPay: 'Paid',
    overdue: 'Overdue',
    urging: 'Collected',
    toOpen: 'To open',
    waitPayBills: 'Savvy bills',
    storeList: 'Shop List',
    payDate: 'Payment Date',
    markAsPaid: 'Mark Paid',
    payTip: 'Are you sure to mark that the bill has been paid?',
    inputStoreName: 'Please enter Store Name',
    inputEnterName: 'Please enter Enterprise Name',
    ensureOpen: 'Confirm to jump to savvy system for operation?',
    openFailure: 'Failed to open, please contact customer service.Phone：0120-501-080',
    reason: 'Reason:'
  },
  errorCode: {
    186688: 'Failed to access redis',
    186678: 'Primary key is not unique',
    186679: 'Seesion to store authentication code for the image is not valid',
    186680: 'Failed to create a enterprise',
    186681: 'Failed to connect to Aliyun',
    186682: 'Failed to create a store',
    186683: 'Unable to update user status',
    186684: 'Exsited user',
    186685: 'Failed to bind this user',
    186686: 'Failed to update user',
    186687: 'Failed to update user status',
    186689: ' Failed to delete temporary binding relationship record between enterprise and user',
    186690: 'Failed to bind enterprise and user',
    186691: 'Failed to update user permission',
    186692: 'Failed to bind user and welcome message',
    186693: 'Failed to delete temporary binding relationshio record between store and user',
    186694: 'Failed to bind store and user',
    186695: 'Failed to update channel',
    186696: 'Failed to connect to mail server',
    186697: 'Please try it again',
    186698: 'Failed to update enterprise information',
    186699: 'Failed to update compaign',
    186676: 'Failed to refund',
    186674: 'Unable to connect to QF(QianFang) server',
    186675: 'Failed to refund on a completed order',
    186601: 'Unknown error',
    186602: 'Failed to insert data',
    186503: 'Didn’t attach owner to this store',
    186504: 'Wrong owner number for the store',
    186505: 'Failed to create a campaign',
    186506: 'number of campagin codes exceeds the limit',
    186507: 'This user has bind an enterprise already',
    186508: 'Failed to create an enterprise',
    186509: 'nable to create a empty enterprise',
    186510: 'Unable to create a store for the enterprise',
    186511: 'Unable to bind the store to the user',
    186512: 'Failed to insert permission',
    186513: 'Failed to update data',
    186514: 'Didn’t match the rule',
    186515: 'Didn’t match the result',
    186516: 'Failed to update',
    186599: 'Unable to find the information',
    186570: 'Broken file',
    186571: 'Invalid code',
    186572: 'Not between 0-20',
    186573: 'Inquiry can’t be empty',
    186574: 'Staff code cant exceed 8 bits',
    186575: 'Wrong store information',
    186576: 'Not exsited user',
    186577: 'Information for this pending user is wrong',
    186578: 'Wrong email address',
    186579: 'Invalid token or not exsited token',
    186580: 'Usage number for campaign code should be bigger than 0.',
    186581: 'Wrong code size',
    186582: 'Wrong user information',
    186583: 'Order status can’t be empty',
    186584: 'Wrong parameter',
    186470: 'Campaign is not exsited',
    186471: 'Channel is not existed',
    186472: 'User is not existed',
    186473: 'Invalid password',
    186474: 'You haven’t bind a valid payment account',
    186560: 'Failed to create a transaction number',
    183675: 'Failed to refund due to not enough balance',
    181674: 'Store config is not correct, please contact administrator',
    181121: 'Failed to insert settlement record',
    186000: 'Uploaded file exceed max size 10MB!',
    187000: 'Insert withdrawal date status table failed',
    187001: 'Failed to update the withdrawal date status table',
    187002: 'Failed to update withdrawal record form',
    187003: 'Error passing status code',
    187100: 'The order date exceeds the refund period',
    187101: 'Operation failed. The transaction has been refunded.',
  },
};
