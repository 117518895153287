import { Model } from 'qn/data';
import { observable, computed } from 'mobx';
import { REVIEW_USER_TYPE, NULL_ID, USER_REGISTER_TYPE } from 'const';

const getUserType = (enterpriseKey, storeKey) => {
  if (enterpriseKey === NULL_ID && storeKey === NULL_ID) {
    return REVIEW_USER_TYPE.INCOMPLETE_REGISTRATION;
  }
  if (enterpriseKey !== NULL_ID && storeKey !== NULL_ID) {
    return REVIEW_USER_TYPE.SINGLE;
  }
  if (enterpriseKey !== NULL_ID) {
    return REVIEW_USER_TYPE.ENTERPRISE_USER;
  }
  return REVIEW_USER_TYPE.STORE_USER;
};

@Model({
  modelName: 'ReviewUser',
  fields: {
    enterpriseKey: 'string',
    loginId: 'string',
    registrationDate: 'date',
    storeKey: 'string',
    userKey: 'string',
    registeredChannels: 'any',
    appliedChannels: 'any',
    updatedDate: 'date',
    createdDate: 'date',
    reviewDate: 'date',
    storeName: 'string',
    enterpriseName: 'string',
    auditStatus: 'string',
    status : "string"
  },
  // idProperty: 'loginId',
  idgen: 'uuid',
})
class ReviewUser {
  @observable userType

  @observable registrationType

  @observable registrationDate

  @observable lastUpdatedDate

  constructor(data) {
    this.userType = getUserType(data.enterpriseKey, data.storeKey); 
    if (this.userType === REVIEW_USER_TYPE.SINGLE) {
      this.userType = REVIEW_USER_TYPE.STORE_USER;
    }
    this.registrationType = data.createdBy === data.loginId ? USER_REGISTER_TYPE.SELF_REGISTERED : USER_REGISTER_TYPE.ENTERPRISE_REGISTERED;
    this.registrationDate = data.registrationDate || data.createdDate;
    this.lastUpdatedDate = data.updatedDate;
    if (this.userType !== REVIEW_USER_TYPE.ENTERPRISE_USER && data.registeredChannels) {
      this.set({
        registeredChannels: this.registeredChannels.filter(o => o.isOwnedByEnterprise === false),
      });
    }
  }

  @computed get userTypeInApproved() {
    const type = getUserType(this.enterpriseKey, this.storeKey);
    if (type === REVIEW_USER_TYPE.SINGLE) {
      return REVIEW_USER_TYPE.STORE_USER;
    }
    return type;
  }

  
}

export default ReviewUser;
