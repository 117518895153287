import { Modal as CarbonModal, Loading } from 'carbon-components-react';
import React from 'react';
import './modal.scss';
import cx from 'classnames';
import withLocale from 'decorator/withLocale';
import PropTypes from 'prop-types';

const FormModalButtonText = ({ loading }) => {
  const style = {
    display: 'inline-block',
    verticalAlign: 'middle',
  };
  return (
    <React.Fragment>
      {loading && <Loading withOverlay={false} small style={style} className="form-modal__btn--loading" />}
      {window.qf_locale.modal.primaryButtonText}
    </React.Fragment>
  );
};

const Modal = withLocale(({ 
  loading, 
  className, 
  setLocale, 
  locale, 
  afterClose, 
  children,
  primaryButtonDisabled,
  primaryButtonLoading,
  ...props 
}) => {
  const modalClassName = cx(className, 'qf-modal');
  const formModalProps = {};
  formModalProps.primaryButtonText = <FormModalButtonText loading={primaryButtonLoading} />;
  // formModalProps.primaryButtonText = '完成';
  formModalProps.secondaryButtonText = window.qf_locale.modal.secondaryButtonText;

  return (
    <CarbonModal
      {...formModalProps}
      {...props}
      className={modalClassName}
      primaryButtonDisabled={primaryButtonLoading}
    >
      {loading ? <Loading withOverlay={false} /> : children}
    </CarbonModal>
  );
});
Modal.propTypes = {
  formModal: PropTypes.bool,
  primaryButtonLoading: PropTypes.bool,
};

export default Modal;
