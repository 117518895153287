import { Model } from 'qn/data';

@Model({
  modelName: 'Advertising',
  fields: {
    imageName: 'string',
    imageUrl: 'string',
    printTimes: 'number',
    startDate: 'date',
    expirationDate: 'date',
    maxPrintTimes: 'number',
    advertiseId: 'number',
    status: 'string',
    pastDue: 'string',
    allPrintTimes: 'number'
  },
  idgen: 'uuid',
})
class Advertising {

}

export default Advertising;
