import { Model } from 'qn/data';

@Model({
  modelName: 'AdvertisingStore',
  fields: {
    storeId: 'number',
    storeName: 'string',
    haveNum: 'number',
    printNum: 'number',
    status: 'string',
  },
  idgen: 'uuid',
})
class AdvertisingStore {
}

export default AdvertisingStore;
