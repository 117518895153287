import { Model } from 'qn/data';

@Model({
  modelName: 'Settings',
  fields: {
    enterpriseId: 'number',
    storeId: 'number',
    mailByDays : 'string',
    // mailByDays : 'number',
    copyMail: 'string',
    merchantName: 'string',
    merchantMail: 'string'
  },
  idgen: 'uuid',
})
class Settings {
}

export default Settings;
