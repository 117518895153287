import { observable, action } from 'mobx';
import { StoreManager } from 'qn/data';

class AppStore {
  @observable viewport = {
    height: 1,
    width: 1,
  }

  // @observable locale = 'zh-CN'

  constructor() {
    this.asyncResize = _.debounce(this.updateViewport, 0, { leading: false });
    window.addEventListener('resize', this.asyncResize);
    this.updateViewport();
  }
  
  @action
  updateViewport = () => {
    this.viewport.height = window.innerHeight;
    this.viewport.width = window.innerWidth;
  }

  getMainMinHeight() {
    return this.viewport.height - 355;
  }

  // @action
  // setLocale(val) {
  //   this.locale = val;
  // }

  dispose() {
    window.removeEventListener('resize', this.asyncResize);
  }
}

const appStore = new AppStore();
StoreManager.register(appStore);
export default appStore;
