import {observable, action} from 'mobx';
import Errors from '../Errors';
class Field {
  type = 'any'
  isField = true
  count = 0
  @observable value
  originalValue
  @observable previousValue
  @observable hasChange = false
  @observable validator = null
  @observable errors = null
  @observable isValid = true
  constructor(options) {
    this.name = options.name;
    if(Reflect.has(options, 'defaultValue')) {
      this.defaultValue = options.defaultValue;
    }
  }

  convert(v) {
    if(v !== undefined) {
      return v;
    }
    return null;
  }

  getValue() {
    return this.value;
  }

  @action
  setDefaultValue() {
    this.value = this.convert(this.defaultValue);
    this.previousValue = this.value;
    this.originalValue = this.value;
    this.count ++;
  }

  isEqual(oldV, newV) {
    return oldV === newV;
  }

  @action
  setValue(v, option = {}) {
    let dirty;
    if(option.dirty === undefined) {
      dirty = true;
    }else {
      dirty = option.dirty;
    }
    let newVal = this.convert(v);
    if(this.isEqual(this.value, newVal)) {
      return this;
    }
    this.previousValue = this.value;
    this.value = newVal;
    if(this.count === 0) {
      this.originalValue = this.value;
      this.previousValue = this.value;
      this.count++;
    }
    if(this.originalValue === this.value) {
      this.hasChange = false;
    }else if(dirty === false) {
      this.hasChange = false;
    }else{
      this.hasChange = true;
    }
    return this;
  }

  @action
  validate(option) {
    this.errors = this.errors || new Errors();
    this.errors.clear();
    if(this.validator === null) {
      return true;
    }
    let record = option.record;
    let v;
    if(Reflect.has(option, 'value')) {
      v = option.value;
    }else{
      v = this.getValue();
    }
    let result = this.validator.validate(v, this.errors, record);
    this.isValid = result;
  }

  @action
  rollback() {
    this.value = this.previousValue;
  }
}
export default Field;
