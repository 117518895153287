import React from 'react';
import { observer } from 'mobx-react';
import { Menu, MenuItem, MenuList } from 'control/leftMenu';
import { Link } from 'react-router-dom';
import Card from 'control/card';
import appStore from 'appStore';
import './nav.scss';
import api from 'remote/api';
import { canDoSome, cannotDoSome, cannotDoEvery, access } from 'acl';
import withLocale from 'decorator/withLocale';
import sessionStore from 'sessionStore';
import { observable } from 'mobx';

@withLocale
@observer
class Nav extends React.Component {
  // componentWillMount() {
  //   const lang = this.props.locale.nav;
  //   this.menu = [
  //     { link: '/app/dashboard', title: lang.homePage, access: [] },
  //     { link: '/app/transaction', title: lang.tranManagemant, noAccess: [access.SUPER_ADMIN] },
  //     { title: lang.propManagement, access: [access.MANAGE_PROMOTION_CAMPAIGN, access.STORE_OWNER, access.ENTERPRISE_OWNER], 
  //       children: [
  //         { title: lang.promActivities, link: '/app/campaign', access: [access.MANAGE_PROMOTION_CAMPAIGN, access.STORE_OWNER, access.ENTERPRISE_OWNER] },
  //       ],
  //     },
  //     { title: lang.mercManagement, access: [access.ENTERPRISE_OWNER, access.MANAGE_EMPLOYEE, access.STORE_OWNER], children: [
  //       { title: '签约信息', link: '/app/enterprise', access: [] },
  //       { title: lang.branchStoreManagement, link: '/app/branch', access: [access.ENTERPRISE_OWNER] },
  //       { title: lang.employeeManagement, link: '/app/user', access: [access.MANAGE_EMPLOYEE, access.STORE_OWNER] },
  //     ] },
  //     { link: '/app/pendingReview', title: lang.toReview, access: [access.SUPER_ADMIN] },
  //   ];
  // }
  @observable useOwnBankAccountForSettlement = false

  async componentWillMount () {
    const user = sessionStore.getUser();
    if (user.storeKey != null) {
      const res = await api.get(`/store/${user.storeKey}`);
      this.useOwnBankAccountForSettlement = res.data.useOwnBankAccountForSettlement;
    }
  }

  buildMenu = () => {
    const lang = this.props.locale.nav;
    const menu = [
      { link: '/app/dashboard', title: lang.homePage, noAccess: [access.ACD_RATE] },
      {
        title: lang.tranManagemant,
        // noAccess: [access.SUPER_ADMIN],
        access: [access.ENTERPRISE_OWNER,
        access.STORE_OWNER,
        access.TRADE_QUERY,
        access.TRADE_STORE_QUERY,
        access.SETTLEMENT_MANAGEMENT,
        access.WITHDRAWAL_MANAGEMENT,
        access.PAY_BARCODE,
        access.SUPER_ADMIN],
        children: [
          // { link: '/app/transaction', title: lang.tranQuery, noAccess: [access.SUPER_ADMIN] },
          {
            link: '/app/transaction', title: lang.tranQuery, access:
              [access.ENTERPRISE_OWNER, access.STORE_OWNER, access.TRADE_QUERY, access.TRADE_STORE_QUERY, access.SUPER_ADMIN]
          },
          // { link: '/app/settlementAlOrWx', title: lang.settlementManagement, access: [access.STORE_OWNER, access.ENTERPRISE_OWNER, access.SETTLEMENT_MANAGEMENT] },
          { link: '/app/withdrawal', title: lang.withdrawalManagement, access: [access.STORE_OWNER, access.ENTERPRISE_OWNER, access.WITHDRAWAL_MANAGEMENT] },
          // { link: '/app/settlement', title: lang.settlement, noAccess: [access.SUPER_ADMIN] },
          { link: '/app/barcode', title: lang.barcodeImage, access: [access.STORE_OWNER, access.PAY_BARCODE] },
        ]
      },
      {
        title: lang.propManagement, access: [access.MANAGE_PROMOTION_CAMPAIGN, access.STORE_OWNER, access.ENTERPRISE_OWNER],
        children: [
          { title: lang.promActivities, link: '/app/campaign', access: [access.MANAGE_PROMOTION_CAMPAIGN, access.STORE_OWNER, access.ENTERPRISE_OWNER] },
        ],
      },

      {
        title: lang.mercManagement, access: [access.ENTERPRISE_OWNER, access.MANAGE_EMPLOYEE, access.CONTRACT_INFO, access.MANAGE_BRANCH, access.STORE_OWNER], children: [
          { title: lang.subscription, link: '/app/enterprise', access: [access.CONTRACT_INFO, access.ENTERPRISE_OWNER, access.STORE_OWNER] },
          { title: lang.branchStoreManagement, link: '/app/branch', access: [access.ENTERPRISE_OWNER, access.MANAGE_BRANCH] },
          { title: lang.employeeManagement, link: '/app/user', access: [access.MANAGE_EMPLOYEE, access.STORE_OWNER] },
          { title: lang.userManagement, link: '/app/enterpriseUser', access: [access.ENTERPRISE_OWNER] },
        ]
      },
      // { link: '/app/withdrawal', title: lang.withdrawalManagement, access: [access.STORE_OWNER, access.ENTERPRISE_OWNER] },

      // { link: '/app/pendingReview', title: lang.toReview, access: [access.SUPER_ADMIN] },
      { link: '/app/approvedReview', title: lang.approvedReview, access: [access.SUPER_ADMIN] },
      { link: '/app/withdrawalManagement', title: lang.withdrawalManagement, access: [access.SUPER_ADMIN] },
      { link: '/app/exceptionOrder', title: lang.exceptionOrder, access: [access.SUPER_ADMIN] },
      { link: '/app/deviceManagement', title: lang.deviceManagement, access: [access.SUPER_ADMIN] },
      { link: '/app/shopownerDeviceManagement', title: lang.deviceManagement, access: [access.STORE_OWNER, access.STORE_DEVICE_MANAGEMENT] },
      { link: '/app/enterpriseDeviceManagement', title: lang.deviceManagement, access: [access.ENTERPRISE_OWNER, access.ENTERPRISE_DEVICE_MANAGEMENT] },
      //{ link: '/app/settlementadmin', title: lang.settlement, access: [access.SUPER_ADMIN] },
      { link: '/app/advertising', title: lang.advertiseManage, access: [access.SUPER_ADMIN], className: 'heige' },
      { link: '/app/adminUpdateSettings', title: lang.settingsManage, access: [access.SUPER_ADMIN] },
      // { link: '/app/acdExchangeRate', title: lang.acdExchangeRate, access: [access.SUPER_ADMIN, access.ACD_RATE] },
      // { link: '/app/internationalExchangeRate', title: lang.internationalExchangeRate, access: [access.SUPER_ADMIN, access.ACD_RATE] },
      {
        title: lang.exchangeRateManagement, access: [access.SUPER_ADMIN, access.ACD_RATE],
        children: [
          { title: lang.acdExchangeRate, link: '/app/acdExchangeRate', access: [access.SUPER_ADMIN, access.ACD_RATE] },
          { title: lang.internationalExchangeRate, link: '/app/internationalExchangeRate', access: [access.SUPER_ADMIN] },
        ],
      },
      { title: 'QFPay+', link: '/app/more', noAccess: [access.FIXED_AMOUNT_QR_CODE, access.ACD_RATE, access.STORE_OWNER, access.SUPER_ADMIN, access.ENTERPRISE_OWNER] },
      {
        title: 'QFPay+', access: [access.STORE_OWNER, access.FIXED_AMOUNT_QR_CODE, access.SUPER_ADMIN, access.ENTERPRISE_OWNER], children: [
          { title: lang.disposeBarCode, link: '/app/disposeBarCode', access: [access.STORE_OWNER, access.FIXED_AMOUNT_QR_CODE] },
          { title: lang.savvyPoint, link: '/app/storeSavvy', access: [access.STORE_OWNER] },
          { title: lang.savvyPoint, link: '/app/enterpriseSavvy', access: [access.ENTERPRISE_OWNER] },
          { title: lang.pointBillManagement, link: '/app/adminSavvy', access: [access.SUPER_ADMIN] },
        ]
      },
      
    ];

    const level1Menus = [];
    menu.forEach((m, i) => {
      if (m.children) {
        const level2Menus = [];
        m.children.forEach((child, k) => {
          if (child.access && canDoSome(child.access)) {
            level2Menus.push(<MenuItem key={k}><Link to={child.link}>{child.title}</Link></MenuItem>);
          } else if (child.noAccess && cannotDoSome(child.noAccess)) {
            level2Menus.push(<MenuItem key={k}><Link to={child.link}>{child.title}</Link></MenuItem>);
          }
        });
        if (m.access && canDoSome(m.access)) {
          level1Menus.push(<MenuList title={m.title} key={i}>{level2Menus}</MenuList>);
        } else if (m.noAccess && cannotDoSome(m.noAccess)) {
          level1Menus.push(<MenuList title={m.title} key={i}>{level2Menus}</MenuList>);
        }
      } else if (m.access && canDoSome(m.access)) {
        level1Menus.push(<MenuItem key={i}><Link to={m.link}>{m.title}</Link></MenuItem>);
      } else if (m.noAccess && cannotDoEvery(m.noAccess)) {
        level1Menus.push(<MenuItem key={i}><Link to={m.link}>{m.title}</Link></MenuItem>);
      }
    });
    return level1Menus;
  }

  render () {
    const { style } = this.props;
    return (
      <div className="left-nav" style={style}>
        <Menu
          activeHref={(window.location && window.location.pathname)}
        >
          {this.buildMenu()}
        </Menu>
      </div>
    );
  }
}
export default Nav;
