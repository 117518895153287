import Field from './Field';

class StringField extends Field {
  type = 'string'
  convert(v) {
    if(v === null || v === undefined) {
      return v;
    }
    return String(v);
  }
}
export default StringField;
