import { Model } from 'qn/data';

@Model({
  modelName: 'Device',
  fields: {
    deviceId: 'string',
    userId: 'number',
    comment: 'string',
    createdBy: 'string',
    createdDate: 'string',
    updatedBy: 'string',
    updatedDate: 'string',
    storeId: 'number',
    softwareVersion: 'string',
    storeName: 'string',
    userName: 'string',
    cashierCode: 'string',
    deviceType: 'number',
  },
  idgen: 'uuid',
})
class Device {
}

export default Device;
