import { observable, action, computed } from 'mobx';
import Cookies from 'js-cookie';
import { StoreManager, Store } from 'qn/data';
import { access } from 'acl';
const sha1 = require('sha1');
import Modal from 'control/modal';

const unCreatedEnterpriseId = 'LTE=';
const expires = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);// 七天过期

class SessionStore {
  @observable user = {
    enterpriseKey: null,
    isEnterpriseOwner: null,
    isStoreOwner: null,
    isSuperAdmin: null,
    name: null,
    status: null,
    token: null,
    userKey: null,
    storeKey: null,
    accesses: [],
    enterpriseName: null,
    storeName: null
  }

  @observable psd = null

  @observable autoLogin = null

  @observable locale = null

  @computed get hasLoginAccess () {
    return this.accesses.includes(access.WEB_LOGIN);
  }

  @action
  saveUser (user) {
    Object.keys(this.user).forEach((key) => {
      this.user[key] = user[key] === undefined ? null : user[key];
    });
    this.user.accesses = user.access;
    delete this.user.access;
    this.saveToken(user.token);
    const exp = this.autoLogin ? expires : null;
    Cookies.set('qf-pay-psd', this.psd, { expires: exp });
    Cookies.set('qfpay-user', JSON.stringify(this.user), { expires: exp });
    // localStorage.setItem('qfpay-user', JSON.stringify(this.user));
  }

  getUser () {
    if (!this.user.token) {
      const jsonData = Cookies.get('qfpay-user');
      if (jsonData === undefined) {
        return this.user;
      }
      const data = JSON.parse(jsonData);
      for (let key in data) {
        this.user[key] = data[key];
      }
    }
    return this.user;
  }

  @action
  setAutoLogin (v) {
    this.autoLogin = v;
  }

  @action
  setPsd (v) {
    const exp = this.autoLogin ? expires : null;
    this.psd = sha1(v);
    Cookies.set('qf-pay-psd', this.psd, { expires: exp });
  }

  getPsd () {
    if (this.psd === null) {
      this.psd = Cookies.get('qf-pay-psd');
    }
    return this.psd;
  }

  @action
  saveLocale (v) {
    this.locale = v;
    const exp = this.autoLogin ? expires : null;
    Cookies.set('qf-pay-locale', this.locale, { expires: exp });
  }

  getLocale () {
    if (this.locale === null) {
      this.locale = Cookies.get('qf-pay-locale');
    }
    return this.locale;
  }

  getAutoLogin () {
    return this.autoLogin;
  }

  saveToken (token) {
    const option = {};
    if (this.autoLogin) {
      option.expires = expires;
    }
    Cookies.set('qf-pay-token', token, option);
  }

  getToken () {
    return Cookies.get('qf-pay-token');
  }

  removeToken () {
    Cookies.remove('qf-pay-token');
    Cookies.remove('qf-pay-psd');
    Cookies.remove('qfpay-user');
    Cookies.remove('user_rescind');
    // localStorage.removeItem('qfpay-user');
    Object.keys(this.user).forEach((key) => {
      this.user[key] = null;
    });
  }

  hasToken () {
    return Cookies.get('qf-pay-token');
  }

  isEnterpriseCreated () {
    return this.user.enterpriseKey !== unCreatedEnterpriseId
      && this.user.enterpriseKey !== -1
      && this.user.enterpriseKey !== null;
  }

  isStoreCreated () {
    return this.user.storeKey !== unCreatedEnterpriseId
      && this.user.storeKey !== -1
      && this.user.storeKey !== null;
  }

  /**
   * 审核成功后，此方法不再有效
   */
  isSingleOwner () {
    return this.isEnterpriseCreated() && this.isStoreCreated();
  }

  getRedirectUrl () {
    if (!this.getToken() || !this.user.token) {
      return '/auth/login';
    }

    if (this.user.accesses.includes(access.ACD_RATE)) {
      return '/app/acdExchangeRate';
    }
    switch (this.user.status) {
      case 'NORMAL':
        return '/app/dashboard';
      case 'AWAITING_REVIEW':
        return '/auth/registerSuccess';
      case 'RESCIND': {
        console.log(Cookies.get('user_rescind'))
        if (Cookies.get('user_rescind')== null || Cookies.get('user_rescind') == true) {
          // window.alert(window.qf_locale.login.rescindInfo);
          Modal.confirm({
            content: window.qf_locale.login.rescindInfo,
            onRequestSubmit: () => {
              Cookies.set('user_rescind', false, { expires: expires });
              return '/app/dashboard';
            },
          });
        }
        Cookies.set('user_rescind', false, { expires: expires });
        return '/app/dashboard';
      }
      default:
        {
          window.alert(window.qf_locale.login.rejectInfo);
          return '/auth/login';
        }
    }

    // if (!this.user.accesses.includes(access.WEB_LOGIN)) {
    //   window.alert(window.qf_locale.noWebLoginAccess);
    //   return '/auth/login';
    // }


  }
}
const sessionStore = new SessionStore();
StoreManager.register(sessionStore);
export default sessionStore;