import React from 'react';

// 价格格式化 round->保留位数 symbol->价格符号 format->显示格式, accurate->是否精确显示
export const CurrencyFormat = ({value, ...props }) => {
    let temp = 'JPY¥';
    return (
      <span {...props}>
        {(String(value) != '' && String(value) === 'JPY') ? temp : String(value)}
      </span>
    );
};
