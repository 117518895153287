import Modal from './Modal';
import confirm from './confirm';

Modal.error = function (props) {
  return confirm({
    modalHeading: window.qf_locale.modal.errorTitle,
    ...props,
    danger: true,
  });
};
Modal.info = function (props) {
  return confirm(props);
};
Modal.success = function (props) {
  return confirm(props);
};
Modal.confirm = function (props) {
  return confirm({
    primaryButtonText: window.qf_locale.modal.confirmPrimaryButtonText,
    secondaryButtonText: window.qf_locale.modal.confirmSecondaryButtonText,
    ...props,
    isConfirm: true,
  });
};
export default Modal;
