import { Model } from 'qn/data';
import { observable } from 'mobx';

@Model({
  modelName: 'QRcode',
  fields: {
    codeKey: 'string',
    uid : 'number',
    title: 'string',
    amount: 'string',
    comment: 'string',
    link : 'string',
    createdBy: 'string',
    isDeleted: 'string',
    createdDate: 'string',
  },
  idProperty: 'codeKey',
})
class QRcode {
}

export default QRcode;
