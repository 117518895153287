export const BRANCH_STATUS = {
  NORMAL: 'NORMAL',
  CLOSED: 'CLOSED',
  BLOCKED: 'BLOCKED',
};
export const TIME = {
  F_NORMAL: 'YYYY-MM-DD HH:mm:ss',
  F_SHOW: 'YYYY/MM/DD HH:mm',
  F_SHOW_YMD: 'YYYY/MM/DD',
  F_SHOW_SPOT: 'YYYY-MM-DD',
};
export const USER_STATUS = {
  NORMAL: 'NORMAL',
  BLOCKED: 'BLOCKED',
  TO_COMPLETE_MATERIAL: 'TO_COMPLETE_MATERIAL',
  TO_REVISE_MATERIAL: 'TO_REVISE_MATERIAL',
  AWAITING_REVIEW: 'AWAITING_REVIEW',
  RESCIND: 'RESCIND'
};
export const USER_PERMISSIONS = {
  APP_LOGIN: 'APP_LOGIN',
  WEB_LOGIN: 'WEB_LOGIN',
  MANAGE_EMPLOYEE: 'MANAGE_EMPLOYEE',
  MANAGE_PROMOTION_CAMPAIGN: 'MANAGE_PROMOTION_CAMPAIGN',
};
export const CHANNEL = {
  ALIPAY: 'ALIPAY',
  WECHAT: 'WECHAT',
};
export const CURRENCY = {
  JPY: 'JPY',
  CNY: 'CNY',
  USD: 'USD',
};
export function currencyItems(locale) {
  return [
    { label: locale.currency.j, value: 'JPY' }, 
    { label: locale.currency.c, value: 'CNY' },
    { label: locale.currency.e, value: 'USD' },
  ];
}
export function bankingOrgItems(locale) {
  return [
    { label: locale.bank.ALIPAY, value: 'ALIPAY' }, 
    { label: locale.bank.WECHAT, value: 'WECHAT' },
  ];
}
export const REVIEW_USER_TYPE = {
  INCOMPLETE_REGISTRATION: 'INCOMPLETE_REGISTRATION',
  ENTERPRISE_USER: 'ENTERPRISE_USER',
  STORE_USER: 'STORE_USER',
  SINGLE: 'SINGLE',
};
export const NULL_ID = 'LTE=';
export const USER_REGISTER_TYPE = {
  SELF_REGISTERED: 'SELF_REGISTERED',
  ENTERPRISE_REGISTERED: 'ENTERPRISE_REGISTERED',
};
export const DATE_PICKER_LANG = {
  'zh-CN': 'zh',
  'en': 'en',
  'jp': 'ja',
};