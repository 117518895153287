import Loadable from 'react-loadable';
import { LCLoading } from 'control/loading';
import { Route, Switch } from 'react-router-dom';
import React from 'react';

export const storeSavvy = Loadable({
  loader: () => import('./StoreSavvy'),
  loading: LCLoading,
});
export const StoreSavvyRoute = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={storeSavvy} />
    </Switch>
  );
}