import { Model } from 'qn/data';
import { observable } from 'mobx';

@Model({
  modelName: 'User',
  fields: {
    key: 'string',
    name: 'string',
    loginId: 'string',
    status: 'string',
    printId: 'string',
    deviceId: 'string',
    contactInfo: 'string',
    internalCode: { type: 'string', defaultValue: '' },
    createdBy: 'string',
    isDeleted: 'string',
    createdDate: 'date',
    isStoreOwner: false,
    accesses: { type: 'any', defaultValue: [] },
  },
  idProperty: 'key',
})
class User {
}

export default User;
