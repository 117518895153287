import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
  modelName: 'WithdrawalRecord',
  fields: {
    withdrawDay: 'string',
    withdrawNum: 'number',
    status: 'number',
    storeWarningList: 'any',
    channelLogErrorList: 'any',
    sumAmount: 'number'
  },
  idgen: 'uuid',
})
class WithdrawalRecord {

}

export default WithdrawalRecord;
