import React from 'react';
import zhCn from 'i18n/zh-CN';
import jp from 'i18n/jp';
import en from 'i18n/en';
import { DEFAULT_LOCALE } from 'config';

export const locales = {
  'zh-CN': zhCn,
  jp,
  en
};

export const LocaleContext = React.createContext({
  locale: locales[DEFAULT_LOCALE],
  setLocale: () => {},
});
export default LocaleContext;
