import { Model } from 'qn/data';
import { computed } from 'mobx';

@Model({
  modelName: 'Settlement',
  fields: {
    startTime: 'date',
    endTime: 'date',
    settlementTime: 'date',
    accountName: 'string',
    accountCode: 'string',
    bankName: 'string',
    branchName: 'string',
    channel: 'string',
    bankingOrg: 'string',
    currency: 'string',
    settlementAmount: 'number',
    transactionFee: 'number',
    systemFee: 'number',
    serviceFee: 'number',
    otherFee: 'number',
    actualSettlementAmount: 'number',
    operator: 'string',
    enterpriseName: 'string',
    storeName: 'string',
  },
  idgen: 'uuid',
})
class Settlement {

}

export default Settlement;
