import { Model } from 'qn/data';

@Model({
  modelName: 'VirtualCurrencyRateLog',
  fields: {
    virtualCurrency: 'string',
    beforeRate: 'double',
    afterRate: 'double',
    comment: 'string',
    createdBy: 'string',
    createdDate: 'string',
    updatedBy: 'string',
    updatedDate: 'string'
  },
  idgen: 'uuid',
})
class VirtualCurrencyRateLog {
}

export default VirtualCurrencyRateLog;
