import Event from './Event';

class Observable {

  getEvents() {
    return this.events || (this.events = {});
  }

  addListener(event) {
    let events = this.getEvents();
    for(let eventName in event) {
      let e = event[eventName];
      let fn;
      let scope;
      if(typeof e === 'function') {
        fn = e;
        scope = this;
      }else{
        fn = e.fn;
        scope = e.scope;
      }
      if(!events[eventName]) {
        events[eventName] = new Event(this, eventName);
      }
      events[eventName].addListener(fn, scope);
    }
  }

  removeListener(event) {
    let events = this.getEvents();
    for(let eventName in event) {
      let e = event[eventName];
      let fn;
      let scope;
      if(typeof e === 'function') {
        fn = e;
        scope = this;
      }else{
        fn = e.fn;
        scope = e.scope;
      }
      if(events[eventName]) {
        events[eventName].removeListener(fn, scope);
      }
    }
  }

  fireEvent(eventName) {
    let events = this.getEvents();
    let args = Array.prototype.slice.call(arguments, 1);
    let event = events && events[eventName];
    if(event) {
      event.fire.apply(event, args);
    }
  }

  getSuspend() {
    return this.suspended || (this.suspended = false);
  }
  
  suspendEvent() {
    this.suspended = true;
  }

  resumeEvent() {
    this.suspended = false;
  }

}
Observable.prototype.on = Observable.prototype.addListener;
Observable.prototype.un = Observable.prototype.removeListener;
export default Observable;