import React from 'react';
import PropTypes from 'prop-types';

// 数字格式化 round->保留位数
export const NumberFormat = ({ value, round, nilRender = '--', ...props }) => {
  let node;
  if (!_.isNaN(nilRender) && _.isNil(value)) {
    node = nilRender;
  } else {
    node = _.round(value, round);
  }
  return (
    <span {...props}>
      {node}
    </span>
  );
};
NumberFormat.propTypes = {
  round: PropTypes.number,
};
NumberFormat.defaultProps = {
  round: 2,
};
